import {Component, Injector, ViewChild} from '@angular/core';
import {AppComponentBase} from '@shared/common/app-component-base';
import {SettingsServiceProxy, VersionInfoDto} from '@shared/service-proxies/service-proxies';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
    selector: 'VersionInfo',
    templateUrl: './versionInfo.component.html',
    styleUrls: ['./versionInfo.component.css']
})
export class VersionInfoComponent extends AppComponentBase {
    @ViewChild('createOrEditModal') modal: ModalDirective;

    versionInfo = new VersionInfoDto();

    constructor(
        injector: Injector,
        private _settingsService: SettingsServiceProxy
    ) {
        super(injector);
    }

    show() {
        this._settingsService.getVersionInformation().subscribe((result) => {
            this.versionInfo = result;
            if (result !== undefined) {
                this.modal.show();
            }
        });
    }

    close(): void {

        this.modal.hide();
    }
}
