import {ErrorHandler, Injectable} from '@angular/core';
import {environment} from 'environments/environment';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    /**
     *
     */
    constructor() {
    }

    handleError(error: Error) {
        if (!environment.production) {
            console.error(error);
            return;
        }
        window['appInsights'].trackException({error: error});
    }
}
