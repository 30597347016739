<!-- BEGIN: Aside Menu -->
<div (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)" [attr.aria-label]="l('LeftMenu')" [attr.m-menu-dropdown-timeout]="500"
     [attr.m-menu-dropdown]="ui.getIsMenuScrollable() ? 0 : 1" [attr.m-menu-scrollable]="ui.getIsMenuScrollable() ? 1 : 0"
     class="{{ui.getSideBarMenuClass()}}"
     data-menu-vertical="true"
     id="m_ver_menu"
     mMenuAside>
    <ul class="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
        <ng-container [ngTemplateOutlet]="mMenuListing"></ng-container>
    </ul>
</div>
<!-- END: Aside Menu -->
<!-- </div> -->
<ng-template #mMenuListing>
    <ng-container *ngFor="let child of menu.items">
        <ng-container *ngIf="showMenuItem(child)" [ngTemplateOutletContext]="{ item: child, parentItem: item }"
                      [ngTemplateOutlet]="mMenuItem"></ng-container>
    </ng-container>
</ng-template>

<ng-template #mMenuItem let-item="item" let-parentItem="parentItem">
    <li *ngIf="showMenuItem(item)"
        aria-haspopup="true"
        class="{{ui.getSideBarMenuItemClass(item, isMenuItemIsActive(item))}}"
        m-menu-submenu-toggle="hover">

        <!-- if menu item hasn't submenu -->
        <a *ngIf="!item.items.length && !item.external" [queryParams]="item.parameters" [routerLink]="item.route"
           class="m-menu__link m-menu__toggle">
            <ng-container [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
                          [ngTemplateOutlet]="mMenuItemText"></ng-container>
        </a>
        <a *ngIf="!item.items.length && item.external" [attr.href]="item.route" class="m-menu__link m-menu__toggle"
           target="_blank">
            <ng-container [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
                          [ngTemplateOutlet]="mMenuItemText"></ng-container>
        </a>

        <!-- if menu item has submenu child  -->
        <a *ngIf="item.items.length" class="m-menu__link m-menu__toggle" href="javascript:">
            <ng-container [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
                          [ngTemplateOutlet]="mMenuItemText"></ng-container>
        </a>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <div *ngIf="item.items.length" class="m-menu__submenu">
            <span class="m-menu__arrow"></span>
            <ul class="m-menu__subnav">
                <ng-container *ngFor="let child of item.items">
                    <ng-container [ngTemplateOutletContext]="{ item: child, parentItem: item }"
                                  [ngTemplateOutlet]="mMenuItem"></ng-container>
                </ng-container>
            </ul>
        </div>
    </li>
</ng-template>

<ng-template #mMenuItemText let-item="item" let-parentItem="parentItem">
    <span class="m-menu__item-here"></span>
    <!-- if menu item has icon -->
    <i *ngIf="item.icon" [ngClass]="item.icon" class="m-menu__link-icon"></i>

    <!-- if menu item using bullet -->
    <i *ngIf="parentItem && parentItem.bullet === 'dot'" class="m-menu__link-bullet m-menu__link-bullet--dot">
        <span></span>
    </i>
    <i *ngIf="parentItem && parentItem.bullet === 'line'" class="m-menu__link-bullet m-menu__link-bullet--line">
        <span></span>
    </i>

    <ng-container *ngIf="!item.badge; else mMenuLinkBadge">
        <!-- menu item title text -->
        <span class="m-menu__link-text">
            {{item.name | localize}}
        </span>
    </ng-container>

    <ng-template #mMenuLinkBadge>
        <!-- menu item with badge -->
        <span class="m-menu__link-title">
            <span class="m-menu__link-wrap">
                <span class="m-menu__link-text">
                    {{item.name | localize}}
                </span>
                <span class="m-menu__link-badge">
                    <span [ngClass]="item.badge.type" class="m-badge">{{item.badge.value}}</span>
                </span>
            </span>
        </span>
    </ng-template>

    <!-- if menu item has submenu child then put arrow icon -->
    <i *ngIf="item.items.length" class="m-menu__ver-arrow la la-angle-right"></i>
</ng-template>
<!-- END: Left Aside -->
