<div #modal="bs-modal" [config]="{backdrop: 'static'}" aria-hidden="true" aria-labelledby="modal" bsModal class="modal fade"
     role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #editForm="ngForm" (ngSubmit)="save()" novalidate>
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"NotificationSettings" | localize}}</span>
                    </h5>
                    <button (click)="close()" [attr.aria-label]="l('Close')" class="close" type="button">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div *ngIf="settings" class="modal-body">
                    <h4>{{"ReceiveNotifications" | localize}}</h4>
                    <div>
                        <span class="m-switch m-switch--icon-check">
                            <label>
                                <input [(ngModel)]="settings.receiveNotifications" name="ReceiveNotifications" type="checkbox"
                                       value="true">
                                <span></span>
                            </label>
                        </span>

                        <div class="help-block">{{"ReceiveNotifications_Definition" | localize}}</div>
                    </div>
                    <h4 *ngIf="settings.notifications.length"
                        class="m--margin-top-20">{{"NotificationTypes" | localize}}</h4>
                    <p *ngIf="settings.notifications.length && !settings.receiveNotifications" class="text-danger">
                        <small>{{"ReceiveNotifications_DisableInfo" | localize}}</small>
                    </p>
                    <div class="m-checkbox-list">
                        <div *ngFor="let notification of settings.notifications">
                            <label attr.for="Notification_{{notification.name}}" class="m-checkbox">
                                <input [(ngModel)]="notification.isSubscribed" [disabled]="!settings.receiveNotifications"
                                       id="Notification_{{notification.name}}" name="Notification_{{notification.name}}"
                                       type="checkbox">
                                {{notification.displayName}}
                                <span></span>
                            </label>
                            <span *ngIf="notification.description"
                                  class="help-block">{{notification.description}}</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="close()" [disabled]="saving" class="btn btn-default"
                            type="button">{{"Cancel" | localize}}</button>
                    <button [busyText]="l('SavingWithThreeDot')" [buttonBusy]="saving" class="btn btn-primary"
                            type="submit"><i class="fa fa-save"></i>
                        <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
