<div #loginAttemptsModal="bs-modal" aria-hidden="true" aria-labelledby="myLargeModalLabel" bsModal class="modal fade"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <span>{{"LoginAttempts" | localize}}</span>
                </h5>
                <button (click)="close()" [attr.aria-label]="l('Close')" class="close" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="form-horizontal" id="loginAttemptsModalForm">
                    <div class="form-body">
                        <div class="row">
                            <div *ngFor="let userLoginAttempt of userLoginAttempts" class="col-sm-12">
                                <div [ngClass]="userLoginAttempt.result == 'Success' ? 'alert-success' : 'alert-danger'"
                                     class="m-alert m-alert--icon m-alert--icon-solid m-alert--outline alert"
                                     role="alert">
                                    <div class="m-alert__icon">
                                        <!-- get image url -->
                                        <img *ngIf="profilePicture && userLoginAttempt.result == 'Success'"
                                             [src]="profilePicture" class="m--img-rounded m--marginless m--img-centered"
                                             height="50" width="50"><br/>
                                        <img *ngIf="!profilePicture || userLoginAttempt.result != 'Success'"
                                             [src]="defaultProfilePicture"
                                             class="m--img-rounded m--marginless m--img-centered" height="50"
                                             width="50"><br/>
                                        <span></span>
                                        <small
                                            class="label"> {{userLoginAttempt.result == "Success" ? l("Success") : l("Failed")}} </small>
                                    </div>
                                    <div class="m-alert__text">
                                        <div *ngIf="userLoginAttempt.clientIpAddress" class="row">
                                            <label
                                                class="control-label col-sm-3 text-right">{{"IpAddress" | localize}}</label>
                                            <div class="col-sm-9">
                                                <p class="form-control-static">
                                                    {{userLoginAttempt.clientIpAddress}}
                                                </p>
                                            </div>
                                        </div>

                                        <div *ngIf="userLoginAttempt.clientName" class="row">
                                            <label
                                                class="control-label col-sm-3 text-right">{{"Client" | localize}}</label>
                                            <div class="col-sm-9">
                                                <p class="form-control-static">
                                                    {{userLoginAttempt.clientName}}
                                                </p>
                                            </div>
                                        </div>

                                        <div *ngIf="userLoginAttempt.browserInfo" class="row">
                                            <label
                                                class="control-label col-sm-3 text-right">{{"Browser" | localize}}</label>
                                            <div class="col-sm-9">
                                                <p class="form-control-static">
                                                    {{userLoginAttempt.browserInfo}}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <label
                                                class="control-label col-sm-3 text-right">{{"Time" | localize}}</label>
                                            <div class="col-sm-9">
                                                <p class="form-control-static login-attempt-date">
                                                    {{getLoginAttemptTime(userLoginAttempt)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
