<div [@routerTransition] class="kpmg-theme">
    <!-- BEGIN: Header -->
    <header
        #mHeader
        *ngIf="!installationMode"
        [attr.m-minimize-mobile]="
            currentTheme.baseSettings.header.mobileFixedHeader ? 'minimize' : ''
        "
        [attr.m-minimize]="
            currentTheme.baseSettings.header.desktopFixedHeader
                ? 'minimize'
                : ''
        "
        class="m-grid__item m-header"
        id="m_header"
        m-minimize-mobile-offset="10"
        m-minimize-offset="10"
        mHeader
    >
        <div class="m-header__top">
            <div
                class="m-container {{
                    ui.getContainerClass()
                }} m-container--full-height m-page__container"
            >
                <div class="m-stack m-stack--ver m-stack--desktop kpmg-stack">
                    <!-- BEGIN: Brand -->
                    <theme5-brand
                        class="m-stack__item m-brand m-brand--skin-{{
                            currentTheme.baseSettings.menu.asideSkin
                        }} d-print-non"
                    >
                    </theme5-brand>
                    <!-- END: Brand -->

                    <!-- BEGIN: Topbar Menu -->
                    <top-bar-menu
                        *ngIf="!menuTogglerValue"
                        class="m-stack m-stack--ver m-stack--desktop"
                    >
                    </top-bar-menu>
                    <!-- END: Topbar Menu -->

                    <div
                        class="m-stack__item m-stack__item--fluid m-header-head"
                        id="m_header_nav"
                    >
                        <!-- BEGIN: Topbar -->
                        <topbar></topbar>
                        <!-- END: Topbar -->
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- END: Header -->

    <!-- begin::Body -->
    <div
        class="m-grid__item m-grid__item--fluid {{
            ui.getContainerClass()
        }} m-grid m-grid--ver-desktop m-grid--desktop m-container m-page__container m-body"
    >
        <!-- BEGIN: Left Aside -->
        <button
            (click)="clickLeftAsideHideToggle()"
            *ngIf="menuTogglerValue"
            class="m-aside-left-close m-aside-left-close--skin-{{
                s(
                    currentTheme.baseSettings.theme +
                        '.' +
                        'App.UiManagement.Left.AsideSkin'
                )
            }}"
            id="m_aside_left_close_btn"
        >
            <i [attr.aria-label]="l('Close')" class="la la-close"></i>
        </button>

        <div
            *ngIf="menuTogglerValue"
            class="m-grid__item m-aside-left m-aside-left--skin-light"
            id="m_aside_left"
        >
            <!-- BEGIN: Aside Menu -->
            <side-bar-menu></side-bar-menu>
            <!-- END: Aside Menu -->
        </div>

        <div class="m-grid__item m-grid__item--fluid m-wrapper">
            <message-bar *ngIf="showMessages"></message-bar>
            <div
                *ngIf="subscriptionStatusBarVisible()"
                [ngClass]="{ hidden: !subscriptionStatusBarVisible() }"
                class="d-inline-block d-sm-none mt-3 ml-3"
            >
                <span
                    *ngIf="
                        tenant.isInTrialPeriod && !subscriptionIsExpiringSoon()
                    "
                    [innerHTML]="getTrialSubscriptionNotification()"
                ></span>
                <a
                    *ngIf="subscriptionIsExpiringSoon()"
                    routerLink="/app/admin/subscription-management"
                >
                    <span
                        *ngIf="
                            subscriptionIsExpiringSoon() &&
                            tenant.isInTrialPeriod
                        "
                    ><i class="fa fa-exclamation-circle"></i>
                        {{
                        getExpireNotification("TrialExpireNotification")
                        }}</span
                    >
                    <span
                        *ngIf="
                            subscriptionIsExpiringSoon() &&
                            !tenant.isInTrialPeriod
                        "
                    ><i class="fa fa-exclamation-circle"></i>
                        {{
                        getExpireNotification(
                            "SubscriptionExpireNotification"
                        )
                        }}</span
                    >
                </a>
            </div>
            <router-outlet></router-outlet>
        </div>
    </div>
    <!-- end:: Body -->
    <!-- begin::Footer -->
    <footer-bar style="z-index: 0 !important;"></footer-bar>
    <!-- end::Footer -->
</div>
