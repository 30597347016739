import {Component, Injector} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AppComponentBase} from '@shared/common/app-component-base';

@Component({
    selector: 'app-root',
    templateUrl: "./root.component.html"
})
export class RootComponent extends AppComponentBase {

    // Dialog options
    showDialog = false;
    dialogTitle = "";
    dialogMessage = "";
    dialogOpts: any;
    dialogIcon = "";
    dialogShowIcon = true;
    yesLabel: string;
    noLabel: string;
    opts : any;

    public constructor(
        injector: Injector,
        private _messageService: MessageService,
        private _confirmationService: ConfirmationService,
    ) {
        super(injector);
    }

    ngOnInit(): void {

        let msgSvc = this._messageService;

        // for more options, see: https://www.primefaces.org/primeng/showcase/#/toast
        let defaultOptions = {
            sticky: false,
            closable: true,
            life: 5000,
        };

        abp.notify.success = function (message, title, options) {
            let dataS = Object.assign({
                severity: 'success',
                summary: title,
                detail: message,
                styleClass: 'p-toast-message-success',
                icon: 'pi-check-circle',
                position: 'bottom-right'
            }, defaultOptions, options);
            msgSvc.add(dataS);

        };

        abp.notify.info = function (message, title, options) {
            let dataI = Object.assign({
                severity: 'info',
                summary: title,
                detail: message,
                styleClass: 'p-toast-message-info',
                icon: 'pi-exclamation-circle'
            }, defaultOptions, options);
            msgSvc.add(dataI);
        };

        abp.notify.warn = function (message, title, options) {
            let dataW = Object.assign({
                severity: 'warn',
                summary: title,
                detail: message,
                styleClass: 'p-toast-message-warn',
                icon: 'pi-exclamation-triangle'
            }, defaultOptions, options);
            msgSvc.add(dataW);
        };

        abp.notify.error = function (message, title, options) {
            let dataE = Object.assign({
                severity: 'error',
                summary: title,
                detail: message,
                styleClass: 'p-toast-message-error',
                icon: 'pi-exclamation-triangle'
            }, defaultOptions, options);
            msgSvc.add(dataE);
        };

        let c = this;
        abp.message.info = function (message, title, opts) {
            c.dialogIcon = "pi pi-info-circle";
            c.dialogTitle = title;
            c.dialogMessage = message;
            c.dialogOpts = opts;
            // Show!
            c.showDialog = true;
        };

        abp.message.success = function (message, title, opts) {
            c.dialogIcon = "pi pi-check-circle";
            c.dialogTitle = title;
            c.dialogMessage = message;
            c.dialogOpts = opts;
            // Show!
            c.showDialog = true;
        };

        abp.message.warn = function (message, title, opts) {
            c.dialogIcon = "pi pi-exclamation-triangle";
            c.dialogTitle = title;
            c.dialogMessage = message;
            c.dialogOpts = opts;
            // Show!
            c.showDialog = true;
        };

        abp.message.error = function (message, title, opts) {
            c.dialogIcon = "pi pi-exclamation-triangle";
            c.dialogTitle = title;
            c.dialogMessage = message;
            c.dialogOpts = opts;
            // Show!
            c.showDialog = true;
        };

        // See https://www.primefaces.org/primeng/showcase/#/confirmdialog
        let comp = this;
        let confirmSvc = this._confirmationService;
        
        abp.message.confirm = function (message, titleOrCallback, callback, opts) {

            let title = undefined;
            let cb = undefined;

            if (typeof titleOrCallback === "function") {
                cb = titleOrCallback;
            } else if (titleOrCallback) {
                title = titleOrCallback;
                cb = callback;
            } else {
                cb = callback;
            }

            opts = opts || {};
            comp.noLabel = opts.rejectLabel  ??  comp.l('No');
            comp.yesLabel = opts.acceptLabel ??  comp.l('Yes');
            opts.title = title ? title : abp.localization.abpWeb('AreYouSure');
            //opts.type = 'warning';

            confirmSvc.confirm({
                message: message,
                header: opts.title,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    cb(true);
                },
                reject: (type) => {
                    cb(false);
                }
            });
        };
        this.noLabel =  this.l('No');
        this.yesLabel = this.l('Yes');
    }

}
