import {DefaultThemeAssetContributor} from '@app/shared/layout/themes/default/DefaultThemeAssetContributor';
import {Theme5ThemeAssetContributor} from '@app/shared/layout/themes/theme5/Theme5ThemeAssetContributor';
import {ThemeHelper} from '@app/shared/layout/themes/ThemeHelper';
import {AppConsts} from '@shared/AppConsts';
import {StyleLoaderService} from '@shared/utils/style-loader.service';

export class DynamicResourcesHelper {

    static loadResources(callback: () => void): void {
        Promise.all([DynamicResourcesHelper.loadStlyes()])
            .then(() => {
                callback();
            });
    }

    static loadStlyes(): Promise<any> {
        let themeColor = ThemeHelper.getThemeColor();

        const styleLoaderService = new StyleLoaderService();

        let styleUrls = [
            AppConsts.appBaseUrl + '/assets/metronic/assets/demo/' + themeColor + '/base/style.bundle.css',
            AppConsts.appBaseUrl + '/assets/primeng/datatable/css/primeng.datatable.css',
            AppConsts.appBaseUrl + '/assets/common/styles/themes/' + themeColor + '/primeng.datatable.css',
            AppConsts.appBaseUrl + '/assets/common/styles/metronic-customize.css',
            AppConsts.appBaseUrl + '/assets/common/styles/themes/' + themeColor + '/metronic-customize.css',
            AppConsts.appBaseUrl + '/assets/common/styles/metronic-customize-angular.css',
            AppConsts.appBaseUrl + '/assets/common/styles/themes/' + themeColor + '/metronic-customize-angular.css'
        ].concat(DynamicResourcesHelper.getAdditionalThemeAssets());

        styleLoaderService.loadArray(styleUrls);

        return Promise.resolve(true);
    }

    static getAdditionalThemeAssets(): string[] {
        let theme = ThemeHelper.getTheme();

        let result = [];
        if (theme === 'default') {
            result = new DefaultThemeAssetContributor().getAssetUrls();
        }

        if (theme === 'theme5') {
            result = new Theme5ThemeAssetContributor().getAssetUrls();
        }

        if (AppConsts.appBaseHref !== '/') {
            result.forEach((item, index, array) => {
                array[index] = AppConsts.appBaseHref.slice(0, -1) + item;
            });
        }

        return result;
    }
}
