import {Inject, Injectable} from '@angular/core';
import {AbpSessionService} from 'abp-ng2-module';
import {AppConsts} from '@shared/AppConsts';
import {DOCUMENT} from '@angular/common';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HostSettingsServiceProxy, TenantSettingsServiceProxy} from '@shared/service-proxies/service-proxies';

@Injectable({
    providedIn: 'root'
})
export class CommunicatonService {

    messagesSubject: Subject<any> = new Subject<any>();
    hostMessagesSubject: Subject<any> = new Subject<any>();
    private typeMessage: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private timerMessage: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private _abpSessionService: AbpSessionService,
        @Inject(DOCUMENT) private document: Document,
        private tenantSettings: TenantSettingsServiceProxy,
        private hostSettings: HostSettingsServiceProxy) {
    }

    public loadThemeStyle(flag) {
        let styleName = AppConsts.appBaseUrl;
        if (flag) {
            localStorage.setItem("themeFlag", 'dark');
            styleName += '/assets/common/styles/themes/theme5/kpmg-dark-style.css';
        } else {
            localStorage.setItem("themeFlag", 'light');
            styleName += '/assets/common/styles/themes/theme5/kpmg-style.css';
        }

        //let styleName = "/assets/common/styles/themes/kpmg/kpmg-style.css";
        const head = this.document.getElementsByTagName('head')[0];

        let themeLink = this.document.getElementById(
            'client-theme'
        ) as HTMLLinkElement;
        if (themeLink) {
            themeLink.href = styleName;
        } else {
            const style = this.document.createElement('link');
            style.id = 'client-theme';
            style.rel = 'stylesheet';
            style.href = `${styleName}`;

            head.appendChild(style);
        }
    }

    public setMessageType(type) {
        this.typeMessage.next(type);
    }

    public getMessageType(): Observable<any> {
        return this.typeMessage;
    }


    public setMessageTimer(timer) {
        this.timerMessage.next(timer);
    }

    public getMessageTimer(): Observable<any> {
        return this.timerMessage;
    }

    public loadMessages() {
        this.tenantSettings.getGlobalMessagesValue().subscribe(res => this.messagesSubject.next(res))
    }

    public setMessages(messageVal, isTenant) {
        if (isTenant) {
            this.tenantSettings.updateGlobalMessagesValue(messageVal).subscribe(res => this.loadMessages())
        } else {
            this.hostSettings.updateHostMessageFlag(messageVal).subscribe(res => this.loadHostMessages())
        }
    }

    public loadHostMessages() {
        this.hostSettings.getHostMessageFlag().subscribe(res => this.hostMessagesSubject.next(res))
    }
}
