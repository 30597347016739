import {Injectable, Injector} from '@angular/core';
import {TableLazyLoadEvent} from 'primeng/table';
import {ColumnConfigDto, SelectItem, TableFieldType} from '@shared/service-proxies/service-proxies';
import {AppComponentBase} from '@shared/common/app-component-base';
import * as _ from 'lodash';
import {FilterMetadata, SelectItem as PrimeNgSelectItem} from "primeng/api";


@Injectable()
export class TableUtilitiesService extends AppComponentBase {

    constructor(injector: Injector) {
        super(injector);
    }


    getValue(rowData: any, fieldAccessor: string): any {
        let fields = fieldAccessor.split('.');
        let result = rowData;
        for (const field of fields) {
            if (!result) {
                return result;
            }
            result = result[field];
        }
        if (result !== undefined) {
            return result;
        } else {
            return this.getValueForReferences(rowData, fieldAccessor);
        }
    }

    //listItems - the list of enums translated
    localizeEnumList(listItems: SelectItem[] | PrimeNgSelectItem<any>[], value) {
        let result = '';
        listItems.forEach((item) => {
            if (item.value === value) {
                result = item.label;
            }
        });
        return result;
    }

    //listItems - the list of enums translated
    localizeEnumFlagList(listItems: SelectItem[], value): string {
        let result = [];

        listItems.forEach((item, index) => {
            // yes only one & for the flags condition
            if (value & item.value) {
                result.push(item.label);
            }
        });
        return result.join(', ');
    }

    getValueForReferences(rowData: any, fieldAccessor: string): any {
        let field = fieldAccessor.split('.').pop();
        return rowData[field];
    }

    createFilterObject(event: TableLazyLoadEvent, cols: ColumnConfigDto[], ignoreSorting: boolean = false) {

        let input: any = {};
        input['isEnabled'] = null;
        input['filter'] = event.globalFilter != null ? event.globalFilter : '';

        _.forEach(_.keys(event.filters), function (x) {

            let col: ColumnConfigDto;
            col = cols.find(f => f.field === x);

            if (!col || col.isHidden) {
                return;
            }
            let filter = col.filterName + 'Filter';

            if (col.fieldType === TableFieldType.Date || col.fieldType === TableFieldType.Datetime ||
                col.fieldType === TableFieldType.Year || col.fieldType === TableFieldType.DateUTCRange ||
                col.fieldType === TableFieldType.NumberRange || col.fieldType === TableFieldType.MoneyRange) {
                input['min' + filter[0].toUpperCase() + filter.slice(1)] = (<FilterMetadata>event.filters[x]).value[0];
                input['max' + filter[0].toUpperCase() + filter.slice(1)] = (<FilterMetadata>event.filters[x]).value[1];
            } else {
                input[filter[0].toLowerCase() + filter.slice(1)] = (<FilterMetadata>event.filters[x]).value;
            }

        });
        input.skipCount = this.getSkipCountForEvent(event);
        if (!ignoreSorting) {
            input.sorting = this.getSorting(event);
        }
        input.maxResultCount = this.getMaxResultCount(event);
        return input;
    }

    public getSkipCountForEvent(event: TableLazyLoadEvent): number {
        return !event ? 0 : event.first;
    }

    public getMaxResultCount(event: TableLazyLoadEvent): number {
        return !event ? 10 : (event.rows > 0 ? event.rows : 10);
    }

    private getSorting(event: TableLazyLoadEvent): string | string[] {
        let sorting: string | string[] = null;

        if (!event.sortField || event.sortField === '_defaultSortOrder') {
            return sorting;
        } else {
            sorting = event.sortField;
            if (event.sortOrder === 1) {
                sorting += ' asc';
            } else if (event.sortOrder === -1) {
                sorting += ' desc';
            }

        }
        return sorting;
    }

    getEnumArrayValue(enumValues: SelectItem[], values: any): string {
        let result = [];
        values.forEach(x => {
            let item = enumValues.find(f => f.value === x);
            if(!!item)
                result.push(item.label);
        });
        return result.join(', ');
    }
}
