<div #createOrEditModal="bs-modal" [config]="{backdrop: false }" aria-hidden="true" aria-labelledby="createOrEditModal"
    bsModal class="modal fade" role="dialog" style="background-color:rgba(0, 0, 0, 0.5);" tabindex="-1">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content" style="height: 750px">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{"OpenSourceComponentsTitle" | localize}}</span>
                </h4>
                <button (click)="close()" aria-label="Close" class="close" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body ">
                <p-tabView>
                    <p-tabPanel header="{{l('EricLicenseTab')}}">
                        <div>
                            <div>{{l('EricLicense1')}}:
                                <a (click)="download(this.ericLicenseType1)" class="cursor-pointer"
                                    style="color:blue;  text-decoration: underline">{{ this.l('Download') }}</a>
                            </div>
                            <div>{{l('EricLicense2')}}:
                                <a (click)="download(this.ericLicenseType2)" class="cursor-pointer"
                                    style="color:blue;  text-decoration: underline">
                                    {{ this.l('Download') }}</a>
                            </div>
                            <hr />
                            <span style="font-weight:bold">Datenschutzhinweis durch die Finanzverwaltung:</span>
                            <br />
                            <div [innerText]="eRiCDisclaimerText"></div>
                            <br>
                            <br>
                            <div class="kt-checkbox-list">
                                <label class="kt-checkbox" for="EricLicenseAccept">
                                    <input [(ngModel)]="hasAcceptedLicense" id="EricLicenseAccept"
                                        name="EricLicenseAccept" type="checkbox"> 
                                    <b>&nbsp; {{"ERicCheckboxTitle" | localize}}</b>
                                    <span></span>
                                </label>    
                                &nbsp;
                                <span *ngIf="acceptedOn" class="accepted-on padding-left-5">({{ acceptedOn }})</span>
                            </div>
                            <button (click)="saveEricAcceptLicense()" class="mt-3 btn btn-primary blue"
                                type="button">{{"Save" |
                                localize}}</button>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="{{l('Frontend')}}">
                        <pre class="label_text_primary" style="text-align:left">
                          {{frontend3rdParties}}
                        </pre>
                    </p-tabPanel>
                    <p-tabPanel header="{{l('Backend')}}">
                        <div *ngFor="let backendItem of backend3rdParties">
                            <div>{{l('Name')}}: {{backendItem.packageName}}</div>
                            <div>{{l('Version')}}: {{backendItem.packageVersion}}</div>
                            <div>{{l('Url')}}: {{backendItem.packageUrl}}</div>
                            <div>{{l('Copyright')}}: {{backendItem.copyright}}</div>
                            <div>{{l('licenseType')}}: {{backendItem.licenseType}}</div>
                            <div>{{l('LicenseUrl')}}: {{backendItem.licenseUrl}}</div>
                            <hr />
                        </div>
                    </p-tabPanel>

                </p-tabView>
            </div>

            <div class="modal-footer">
                <button (click)="close()" class="btn btn-primary blue" type="button">{{"Close" | localize}}</button>
            </div>
        </div>
    </div>
</div>