<footer class="m-grid__item m-footer" style="position: unset;">
    <div class="m-footer__inner">
        <div class="m-footer-left">
                <span class="m-footer__copyright">
                    <!-- KPMG<span
                        *ngIf="appSession.tenant && appSession.tenant.edition && appSession.tenant.edition.displayName">
                        {{appSession.tenant.edition.displayName}}</span> <br />
                    v{{appSession.application.version}} [{{releaseDate}}] -->
                </span>
        </div>
        <div class="m-footer-right">
            <div class="messageSwitch">
                <!-- <label class="col-form-label label_text_primary messagesGlobal" pBadge severity="danger" [value]="messagesCounter"> -->
                <label class="col-form-label label_text_primary messagesGlobal">
                    Messages
                    <span class="badge badge-primary">{{messagesCounter}}</span>
                </label>
                <!-- <i class="pi pi-envelope p-text-secondary" pBadge style="font-size: 1.5rem" severity="danger" [value]="messagesCounter"></i> -->
                <p-inputSwitch (onChange)="setMessages($event)" [(ngModel)]="messagesFlag"></p-inputSwitch>
            </div>
            <span class="pipe-divider"> | </span>
            <div class="themeSwitch">
                <label class="col-form-label label_text_primary">Light</label>
                <p-inputSwitch (onChange)="loadStyle($event)" [(ngModel)]="themeLight"></p-inputSwitch>
                <label class="col-form-label label_text_primary">Dark</label>
            </div>
            <span class="pipe-divider"> | </span>
            <app-disclaimer></app-disclaimer>
        </div>
    </div>
</footer>
