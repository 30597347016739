<!-- BEGIN: Horizontal Menu -->
<button (click)="clickLeftAsideHideToggle()"
        class="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-light " id="m_aside_header_menu_mobile_close_btn">
    <i class="la la-close"></i>
</button>
<div #m_header_menu class="{{getCssClass()}}" id="m_header_menu">
    <ul class="m-menu__nav m-menu__nav--submenu-arrow kpmg-menu__nav">
        <ng-container *ngFor="let item of menu.items">
            <ng-container *ngIf="item.name" [ngTemplateOutletContext]="{ item: item, depth: 0 }"
                          [ngTemplateOutlet]="m_menu">
            </ng-container>
        </ng-container>
    </ul>
</div>
<!-- END: Horizontal Menu -->


<ng-template #m_menu let-depth="depth" let-item="item" let-parentItem="parentItem">

    <li *ngIf="showMenuItem(item)" [attr.m-menu-submenu-toggle]="getItemAttrSubmenuToggle(item, parentItem, depth)"
        attr.aria-haspopup="true" class="{{ getItemCssClasses(item, parentItem, depth) }}"
        routerLinkActive="m-menu__item--active">


        <!-- if item has submenu -->
        <ng-container *ngIf="item.items.length || (isTabMenuUsed && !parentItem && !item.items.length)">
            <a class="{{ getAnchorItemCssClasses(item, parentItem) }}" href="javascript:">

                <ng-container [ngTemplateOutletContext]="{ item: item, parentItem: parentItem, depth: (depth + 1) }"
                              [ngTemplateOutlet]="m_menu_item_inner">
                </ng-container>

                <!-- arrow icons -->
                <i *ngIf="item.items.length && !parentItem" class="m-menu__hor-arrow la la-angle-down"></i>
                <i *ngIf="item.items.length && !parentItem" class="m-menu__ver-arrow la la-angle-right"></i>
                <!-- else arrow icons -->
                <i *ngIf="item.items.length && parentItem" class="m-menu__hor-arrow la la-angle-right"></i>
                <i *ngIf="item.items.length && parentItem" class="m-menu__ver-arrow la la-angle-right"></i>
            </a>
        </ng-container>

        <!-- if item hasn't sumbenu -->
        <ng-container *ngIf="!item.items.length && !(isTabMenuUsed && !parentItem && !item.items.length)">
            <a *ngIf="!item.external" [queryParams]="item.parameters" [routerLink]="item.route" class="m-menu__link">
                <ng-container [ngTemplateOutletContext]="{ item: item, parentItem: parentItem, depth: (depth + 1) }"
                              [ngTemplateOutlet]="m_menu_item_inner">
                </ng-container>
            </a>
            <a *ngIf="item.external" [attr.href]="[item.route]" class="m-menu__link" target="_blank">
                <ng-container [ngTemplateOutletContext]="{ item: item, parentItem: parentItem, depth: (depth + 1) }"
                              [ngTemplateOutlet]="m_menu_item_inner">
                </ng-container>
            </a>
        </ng-container>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <ng-container *ngIf="item.items.length">
            <div class="{{ getSubmenuCssClasses(item, parentItem, depth) }}" style="z-index: 0;">
                <span [ngClass]="{ 'm-menu__arrow--adjust': !parentItem }" class="m-menu__arrow"></span>
                <ul *ngIf="item.items.length" [ngClass]="{'width300px': item.items.length > 20, 'long-submenu': (item.items.length > 13 && !sideBarMenuIsOpen)}"
                    class="m-menu__subnav">
                    <ng-container *ngFor="let child of item.items">

                        <ng-container [ngTemplateOutletContext]="{ item: child, parentItem: item, depth: (depth + 1) }"
                                      [ngTemplateOutlet]="m_menu">
                        </ng-container>

                    </ng-container>
                </ul>
            </div>
        </ng-container>
        <ng-container *ngIf="isTabMenuUsed && !parentItem && !item.items.length">
            <div [ngClass]="{'m-menu__submenu--tabs': isTabMenuUsed}"
                 class="m-menu__submenu m-menu__submenu--classic m-menu__submenu--left">
                <span [ngClass]="{ 'm-menu__arrow--adjust': !parentItem }" class="m-menu__arrow"></span>
                <ul class="m-menu__subnav">
                    <ng-container [ngTemplateOutletContext]="{ item: item, parentItem: item, depth: (depth + 1) }"
                                  [ngTemplateOutlet]="m_menu">
                    </ng-container>
                </ul>
            </div>
        </ng-container>
    </li>
</ng-template>

<!-- item inner -->
<ng-template #m_menu_item_inner let-item="item" let-parentItem="parentItem">
    <!-- if menu item has icon -->
    <i *ngIf="item.icon" [ngClass]="item.icon" class="m-menu__link-icon"></i>

    <ng-container>
        <span class="m-menu__item-here"></span>
        <!-- menu item title text -->
        <span class="m-menu__link-text">
            {{item.name | localize}}
        </span>
    </ng-container>
</ng-template>
