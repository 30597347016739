import {Pipe, PipeTransform} from '@angular/core';
import { toInteger } from 'lodash';

@Pipe({
    name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {
    transform(text: string, length: any): string {
        let textLen: number = 25;
        if (length){
            textLen = toInteger(length);
        } 
        if (!text || textLen==0 || textLen>=text.length) {
            return text;
        }

        return abp.utils.truncateStringWithPostfix(text, textLen);
    }

}
