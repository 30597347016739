import {AfterViewInit, Component, Injectable, Injector, NgZone, OnInit, ViewChild} from '@angular/core';
import {AppConsts} from '@shared/AppConsts';
import {UrlHelper} from '@shared/helpers/UrlHelper';
import moment from 'moment-timezone';
import {AppComponentBase} from 'shared/common/app-component-base';
import {LoginAttemptsModalComponent} from '@app/shared/layout/login-attempts-modal.component';
import {ChangePasswordModalComponent} from '@app/shared/layout/profile/change-password-modal.component';
import {MySettingsModalComponent} from '@app/shared/layout/profile/my-settings-modal.component';
import {
    NotificationSettingsModalComponent
} from '@app/shared/layout/notifications/notification-settings-modal.component';
import {UserNotificationHelper} from '@app/shared/layout/notifications/UserNotificationHelper';

import {VersionInfoComponent} from './shared/layout/VersionInfo/versionInfo.component';
import {DisclaimerComponent} from './shared/layout/Disclaimer/disclaimer.component';
import {ChangeLogComponent} from './shared/layout/changelog/change-log.component';
import {OpenSourceComponentsComponent} from './shared/layout/open-source-components/open-source-components.component';
import {SupportMailComponent} from './shared/layout/support-mail/support-mail.component';
import {PrimeNGConfig, Translation} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {ProfileServiceProxy} from '@shared/service-proxies/service-proxies';
import {firstValueFrom, isObservable, lastValueFrom, Observable} from "rxjs";
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

@Component({
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent extends AppComponentBase implements OnInit, AfterViewInit{

    theme: string;
    installationMode = true;

    // Dialog options
    showDialog = false;
    dialogTitle = "";
    dialogMessage = "";
    dialogOpts: any;
    dialogIcon = "";
    dialogShowIcon = true;

    @ViewChild('loginAttemptsModal') loginAttemptsModal: LoginAttemptsModalComponent;
    @ViewChild('changePasswordModal') changePasswordModal: ChangePasswordModalComponent;
    @ViewChild('mySettingsModal') mySettingsModal: MySettingsModalComponent;
    @ViewChild('notificationSettingsModal') notificationSettingsModal: NotificationSettingsModalComponent;
    @ViewChild('disclaimer') disclaimer: DisclaimerComponent;
    @ViewChild('versionInfo') versionInfo: VersionInfoComponent;
    @ViewChild('changeLog') changelog: ChangeLogComponent;
    @ViewChild('openSourceComponents') opensourceComponents: OpenSourceComponentsComponent;
    @ViewChild('supportMailModal') supportMailModal: SupportMailComponent;

    IsSessionTimeOutEnabled: boolean =
        this.setting.getBoolean(
            "App.UserManagement.SessionTimeOut.IsEnabled"
        ) && this.appSession.userId != null;

    DeclarationTimeOutMinutes: number = undefined;


    public constructor(
        injector: Injector,
        private _userNotificationHelper: UserNotificationHelper,
        private config: PrimeNGConfig,
        private _profileAppService: ProfileServiceProxy,
        private translateService: TranslateService,
        private activatedRoute: ActivatedRoute
    ) {
        super(injector);
    }

    async ngOnInit(): Promise<void> {
        //this._userNotificationHelper.settingsModal = this.notificationSettingsModal;
        this.theme = abp.setting.get('App.UiManagement.Theme').toLocaleLowerCase();
        this.installationMode = UrlHelper.isInstallUrl(location.href);
        this.registerModalOpenEvents();
        this.translateService.setDefaultLang("de");
        this.translateService.use(abp.localization.currentLanguage.name);
        this.translateService.get("primeng").subscribe(res => this.config.setTranslation(res));
    }

    ngAfterViewInit(): void {
        this._userNotificationHelper.settingsModal = this.notificationSettingsModal;
        if (abp.custom.inboxItemMessageAfterLogin) {
            abp.notify.success(abp.custom.inboxItemMessageAfterLogin);
        }
    }

    subscriptionStatusBarVisible(): boolean {
        return this.appSession.tenantId > 0 &&
            (this.appSession.tenant.isInTrialPeriod ||
                this.subscriptionIsExpiringSoon());
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this.appSession.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNootifyDayCount, 'days') >= moment(this.appSession.tenant.subscriptionEndDateUtc);
        }

        return false;
    }

    registerModalOpenEvents(): void {
        abp.event.on('app.show.loginAttemptsModal', () => {
            this.loginAttemptsModal.show();
        });

        abp.event.on('app.show.changePasswordModal', () => {
            this.changePasswordModal.show();
        });

        abp.event.on('app.show.mySettingsModal', () => {
            this.mySettingsModal.show();
        });

        abp.event.on('app.show.OpenSourceComponents', () => {
            this.opensourceComponents.show();
        });
        abp.event.on('app.show.ChangeLog', () => {
            this.changelog.show();
        });
        abp.event.on('app.show.VersionInfo', () => {
            this.versionInfo.show();
        });
        abp.event.on('app.show.Disclaimer', () => {
            this.disclaimer.show();
        });
        abp.event.on('app.show.showSupportMailModal', () => {
            this.supportMailModal.show();
        });
        abp.event.on('app.set.declarationTimeout', () => {
            this._profileAppService.getDeclarationTimeoutSetting()
                .subscribe((s)=>{
                    if (s) this.DeclarationTimeOutMinutes = s;
                    //console.log("--------------- DeclarationTimeoutMinutes: "+this.DeclarationTimeOutMinutes);
                })
        });
        abp.event.on('app.reset.sessionTimeout', () => {
                    this.DeclarationTimeOutMinutes = undefined;
                    //console.log("---------------Reset DeclarationTimeoutMinutes: "+this.DeclarationTimeOutMinutes);
        });

    }

    getRecentlyLinkedUsers(): void {
        abp.event.trigger('app.getRecentlyLinkedUsers');
    }

    onMySettingsModalSaved(): void {
        abp.event.trigger('app.onMySettingsModalSaved');
    }

}
