import {
    Component,
    ContentChildren,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { FilterMetadata, MenuItem } from 'primeng/api';
import { AppComponentBase } from '@shared/common/app-component-base';
import {Table, TableLazyLoadEvent} from "primeng/table";
import {
    _definitions_TableConfigurationType,
    ColumnConfigDto,
    TableConfigServiceProxy,
    TableFieldType
} from '@shared/service-proxies/service-proxies';
import { FieldTypeTemplateDirective } from '@shared/utils/field-type-template.directive';
import { TableUtilitiesService } from '@shared/utils/table-utils-service';
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import {KpmgTableCaptionExtraDirective} from "@shared/utils/kpmg-table-caption-extra.directive";

@Component({
    selector: 'kpmg-lookup-table',
    templateUrl: './kpmg-lookup-table.component.html',
    styleUrls: ['./kpmg-lookup-table.component.css']
})
export class KpmgLookupTableComponent extends AppComponentBase implements OnInit, OnChanges {
    @ViewChild("dt") table: Table;
    @Input() value: any;
    @Input() globalSearch = true;
    @Input() filterShow = true;
    @Input() responsive = true;
    @Input() autoLayout = true;
    @Input() canViewItem = false;
    @Input() canDelete = false;
    @Input() stateStorage = "local";
    @Input() totalRecords: number = 0;
    @Input() paginator = true;
    @Input() rows = 10;
    @Input() rowHover = true;
    @Input() lazy = true;
    @Input() showCurrentPageReport = true;
    @Input() rowsPerPageOptions = [10, 25, 50, 100, 250, { showAll: 'All' }];
    @Input() selectionMode = "multiple";
    @Input() showButtons: boolean = true;
    @Input() menuItems: MenuItem[][] = [];
    @Input() overrideViewMenuSize: number;
    @Input() selectableFilter = true;
    @Input() tableConfigType: _definitions_TableConfigurationType;
    @Input() dataKey = "id";
    @Input() metaKeySelection = false;
    @Input() selectButtonName = this.l('Select');
    @Input() closeButtonName = this.l('Close');
    @Input() paginatorDropdownAppendTo = "";

    public selectedItems: any = [];
    @Output() onLazyLoad: EventEmitter<TableLazyLoadEvent> = new EventEmitter();
    @Output() onGetSelectedItems: EventEmitter<any> = new EventEmitter<any>();
    @Output() onViewItem: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectedRow: EventEmitter<any> = new EventEmitter<any>();
    @Output() unSelectedRow: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectedAllRows: EventEmitter<any> = new EventEmitter<any>();
    actionButtonSize: number[] = [];
    @Input() loading = true;
    public columns: ColumnConfigDto[];
    enumerations: any;
    dateFilters: Date[][] = [];
    currentPageReport: string = this.l('ShowingRecords');
    emptyPageReport: string = this.l('TableNoEntriesFound');
    selectAll: boolean = false;
    showSelectAllLabel: boolean = true;
    @ContentChildren(FieldTypeTemplateDirective)
    private fieldTypeTemplateDirectives: QueryList<FieldTypeTemplateDirective>;
    @ContentChildren(KpmgTableCaptionExtraDirective)
    private kpmgTableCaptionExtraDirectives: QueryList<KpmgTableCaptionExtraDirective>;

    tableConfigTypes = _definitions_TableConfigurationType;

    constructor(
        injector: Injector,
        private tableConfigService: TableConfigServiceProxy,
        protected tableUtilsService: TableUtilitiesService
    ) {
        super(injector);
    }
    filterDebounceTime: number = 500;
    filterTextChanged: Subject<any> = new Subject<any>();
    debounceInput(value: any, field: any) {
        if (!this.filterTextChanged.observed) {
            this.filterTextChanged.pipe(debounceTime(this.filterDebounceTime), distinctUntilChanged()).subscribe(query => {
                if (query.field === '') {
                    this.table.filterGlobal(query.value, '');
                } else {
                    this.table.filter(query.value, query.field, '');
                }
            });
        }
        this.filterTextChanged.next({ value, field });
    }
    ngOnInit() {
        this.selectedItems = [];

        this.tableConfigService
            .getLookupTableConfig(this.tableConfigType)
            .subscribe((s) => {
                this.columns = s.columns;
                this.enumerations = s.enumLabels;
                // init date filters
                this.columns
                    .filter(
                        (f) =>
                            f.isFilterEnabled &&
                            (f.fieldType === TableFieldType.Date ||
                                f.fieldType === TableFieldType.Datetime)
                    )
                    .map((s) => s.field)
                    .forEach((item) => {
                        if (!this.table.filters[item]) {
                            return;
                        }
                        this.dateFilters[item] = [
                            new Date((<FilterMetadata>this.table.filters[item]).value[0]),
                            new Date((<FilterMetadata>this.table.filters[item]).value[1]),
                        ];
                    });
                // now that columns are loaded we can load table data
                this.onLazyLoad.emit(this.table.createLazyLoadMetadata());
            });
    }

    ngOnChanges(simpleChange: SimpleChanges) {
        if (simpleChange.value && !simpleChange.value.firstChange) {
            this.loading = false;
        }

        if (simpleChange.menuItems && !simpleChange.menuItems.firstChange) {
            if (this.menuItems) {
                this.actionButtonSize = [];
                let sizeEachOption: number = 38.5;
                if (this.overrideViewMenuSize) {
                    sizeEachOption = this.overrideViewMenuSize;
                }
                this.menuItems.forEach((item, index) => {
                    this.actionButtonSize[index] =
                        sizeEachOption * (this.menuItems[index].length + 1);
                });
            }
        }
    }

    hasTemplate(field: string): boolean {
        let template = this.fieldTypeTemplateDirectives.find(
            (f) => f.fieldType === field
        );
        return !!template;
    }

    getTemplate(field: string): TemplateRef<any> {
        let template = this.fieldTypeTemplateDirectives.find(
            (f) => f.fieldType === field
        );
        return template.templateRef;
    }

    resetFilters() {
        this.table.filters = {};
        this.table.reset();
        this.table.clearState();
    }

    truncateString(text: any, length: number | undefined): string {
        if (!length) {
            length = 25;
        }

        return abp.utils.truncateStringWithPostfix(text, length);
    }

    close() {
        var ff = this.selectionMode;
        this.onClose.emit(null);
    }

    selectItems() {
        this.onGetSelectedItems.emit(this.selectedItems);
    }

    onRowSelect(event) {
        this.selectedRow.emit(event);
        if (this.selectedItems.length == this.value.length) {
            this.showSelectAllLabel = false;
            this.selectAll = true;
        }
    }

    setWidth(width: any) {
        return `${width}px`;
    }

    onRowUnselect(event) {
        this.unSelectedRow.emit(event);
        this.showSelectAllLabel = true;
        this.selectAll = false;
    }

    viewItem() {
        if (this.selectedItems.length) {
            this.onViewItem.emit(this.selectedItems.find(x => x).id);
        } else {
            this.onViewItem.emit(this.selectedItems.id);
        }
    }

    isViewItemDisabled(): boolean {
        if (this.selectedItems) {
            if (this.selectedItems.length) {
                return !(this.selectedItems.length == 1);
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    selectAllRows(event) {
        if (event.checked) {
            this.selectedItems = [...this.value];
            this.showSelectAllLabel = false;
        } else {
            this.selectedItems = [];
            this.showSelectAllLabel = true;
        }
        this.selectedAllRows.emit(event.checked);
    }

    onPage(event: any) {
        if (this.selectAll) {
            this.showSelectAllLabel = true;
            this.selectAll = false;
            this.selectedItems = [];

        }
        if (event.rows != undefined && event.rows > 100) {
            this.table.scrollHeight = '1000px';
            this.table.scrollable = true;
        } else {
            this.table.scrollHeight = '';
            this.table.scrollable = false;
        }

    }

    getPageReportTemplate(): string {
        if (this.selectedItems == undefined) {
            return this.totalRecords == 0 ? this.emptyPageReport : this.currentPageReport;
        }
        if (this.selectedItems.length > 0) {
            const countSelectedItems = this.selectedItems.length;
            const selectedPageReport = this.l("ShowingRecords") + this.l("ShowingSelectedRecords", countSelectedItems);
            this.currentPageReport = selectedPageReport;
        } else {
            this.currentPageReport = this.l("ShowingRecords");
        }
        return this.totalRecords == 0 ? this.emptyPageReport : this.currentPageReport;
    }

    hasExtraCaption() {
        return this.kpmgTableCaptionExtraDirectives.length > 0;
    }

    getExtraCaption(): TemplateRef<any> {
        return this.kpmgTableCaptionExtraDirectives.first.templateRef;
    }
}
