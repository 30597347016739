import { Component, EventEmitter, Injector, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/api'; import { TableLazyLoadEvent } from 'primeng/table';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    _definitions_TableConfigurationType,
    ArchiveInboxItemViewDto,
    GetCommentToUpdateInput,
    InboxFilterDto,
    InboxItemMetadataDto,
    InboxServiceProxy,
    InboxType,
    NewInboxItemViewDto
} from '@shared/service-proxies/service-proxies';
import { TableUtilitiesService } from '@shared/utils/table-utils-service';
import { KpmgTableComponent } from '@app/shared/common/kpmg-table/kpmg-table.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { forkJoin, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'inbox-component',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    templateUrl: './inboxItems.component.html',
    styleUrls: ['./inboxItems.component.css']
})
export class InboxItemsComponent extends AppComponentBase implements OnInit {
    @ViewChild("newItemsDT") newItemsDT: KpmgTableComponent;
    @ViewChild("archiveItemsDT") archiveItemsDT: KpmgTableComponent;
    @ViewChild('inboxModal') modal: ModalDirective;

    @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

    public active = false;
    userCommentUpdate = new Subject<any>();
    inboxType: any;
    newInboxItems: NewInboxItemViewDto[] = [];
    totalNewInboxItemRecords = 0;
    newInboxMenuItems: MenuItem[][];
    newInboxItemsTableConfigType = _definitions_TableConfigurationType.NewInboxItems;
    archiveInboxItems: ArchiveInboxItemViewDto[] = [];
    totalArchiveInboxItemRecords = 0;
    archiveInboxMenuItems: MenuItem[][];
    archiveInboxItemsTableConfigType = _definitions_TableConfigurationType.ArchiveInboxItems;
    showNewItems = true;
    inboxItemMetadata: InboxItemMetadataDto;
    currentCapacity: any;
    maxCapacity: any;
    capacityPercentage: any;
    customMenuItems: MenuItem[];

    constructor(injector: Injector,
        private _inboxServiceProxy: InboxServiceProxy,
        private _tableUtilitiesService: TableUtilitiesService,
        private _fileDownloadService: FileDownloadService) {
        super(injector);
        this.userCommentUpdate.pipe(
            debounceTime(800),
            distinctUntilChanged())
            .subscribe(value => {
                let comment = new GetCommentToUpdateInput();
                comment.id = value.record;
                comment.comment = value.event;
                this._inboxServiceProxy.updateComment(comment).subscribe();
            });
    }

    ngOnInit(): void {

    }

    show(): void {
        this.active = true;
        this.inboxType = InboxType;
        this.showNewItems = true;
        this.getInboxInfo();
        this.modal.show();
    }

    close(): void {
        if (this.newItemsDT)
            this.newItemsDT.hideSlideMenu();
        if (this.archiveItemsDT)
            this.archiveItemsDT.hideSlideMenu();

        this.active = false;
        this.modal.hide();
        this.modalClose.emit();
    }

    getInboxInfo() {
        this._inboxServiceProxy.getInboxItemMetadata().subscribe((result) => {
            this.inboxItemMetadata = result;
            this.currentCapacity = result.capacity.current;
            this.maxCapacity = result.capacity.max;
            this.capacityPercentage = ((result.capacity.current / result.capacity.max) * 100).toFixed(2);
        });
    }

    getNewInboxItems(event?: TableLazyLoadEvent) {
        if (!event) {
            event = this.newItemsDT.table.createLazyLoadMetadata();
        }
        let input = this._tableUtilitiesService.createFilterObject(
            event,
            this.newItemsDT.columns
        ) as InboxFilterDto;


        this._inboxServiceProxy
            .getAllNew(input)
            .subscribe((result) => {
                this.totalNewInboxItemRecords = result.totalCount;
                this.newInboxItems = result.items;
                this.newInboxMenuItems = this.createMenuItems(result.items, false);
                this.customMenuItems = this.createCustomMenuItems(result.items, false);
            });
    }

    getArchiveInboxItems(event?: TableLazyLoadEvent) {
        if (!event) {
            event = this.archiveItemsDT.table.createLazyLoadMetadata();
        }
        let input = this._tableUtilitiesService.createFilterObject(
            event,
            this.archiveItemsDT.columns
        ) as InboxFilterDto;

        input.isArchiveFilter = true;
        this._inboxServiceProxy
            .getAllArchive(input)
            .subscribe((result) => {
                this.totalArchiveInboxItemRecords = result.totalCount;
                this.archiveInboxItems = result.items;
                this.archiveInboxMenuItems = this.createMenuItems(result.items, true);
                this.customMenuItems = this.createCustomMenuItems(result.items, true);
            });
    }

    delete(id: number) {
        this._inboxServiceProxy.remove(id).subscribe(() => {
            this.getInboxInfo();
            this.reloadPages();
        });
    }

    download(id: number) {
        let downloadObs$ = this._inboxServiceProxy.dowload(id);
        let archiveObs$ = this._inboxServiceProxy.moveToArchive(id);

        forkJoin([downloadObs$, archiveObs$])
            .subscribe(([downloadObs, archiveObs]) => {
                if (downloadObs) {
                    this._fileDownloadService.downloadTempFile(downloadObs);
                    this.reloadPages();
                }

                this.getInboxInfo();
            });
    }

    downloadAllNew(archive) {
        if (archive == 'new') {
            let downloadAllObs$ = this._inboxServiceProxy.dowloadAllNew(archive);
            let archiveAllObs$ = this._inboxServiceProxy.archiveAll();

            forkJoin([downloadAllObs$, archiveAllObs$])
                .subscribe(([downloadAllObs, archiveAllObs]) => {
                    this._fileDownloadService.downloadTempFile(downloadAllObs);
                    this.getInboxInfo();
                    this.reloadPages();
                });
        } else {
            this._inboxServiceProxy.dowloadAllNew(archive).subscribe((res) => {
                this.reloadPages();
                this._fileDownloadService.downloadTempFile(res);
            });
        }
    }

    archive(id: number) {
        this._inboxServiceProxy.moveToArchive(id).subscribe(() => {
            this.getInboxInfo();
            this.reloadPages();
        });
    }

    archiveAll() {
        this._inboxServiceProxy.archiveAll().subscribe(() => {
            this.getInboxInfo();
            this.reloadPages();
        });
    }

    deleteAll(type: boolean) {
        let isArchive = type == true ? "archive" : "";
        this._inboxServiceProxy.removeAll(isArchive).subscribe(() => {
            this.getInboxInfo();
            this.reloadPages();
        });
    }

    reloadPages(): void {
        if (this.showNewItems) {
            this.newItemsDT.onLazyLoad.emit(this.newItemsDT.table.createLazyLoadMetadata());
        } else {
            this.archiveItemsDT.onLazyLoad.emit(this.archiveItemsDT.table.createLazyLoadMetadata());
        }


    }

    shouldShowNewItems(input: boolean) {

        if (input) {
            this.showNewItems = true;
        } else {
            this.showNewItems = false;
        }
    }

    setIcon(fileName) {
        let iconClass = fileName.split(".", 2)[1];
        switch (iconClass) {
            case 'xlsx':
                return 'fas fa-file-excel';
            case 'zip':
                return 'fas fa-file-archive';
            case 'docx':
                return 'fas fa-file-word';
            case 'pptx':
                return 'fas fa-file-powerpoint';
            case 'pdf':
                return 'fas fa-file-pdf';
            default:
                return '';
        }
    }

    updateComment(values) {
        let val = values.data['comment'];
        let id = values.data['id'];
        this.userCommentUpdate.next({ event: val, record: id });
    }

    private createMenuItems(entries: any, isArchive: boolean): MenuItem[][] {
        let newMenuItems: MenuItem[][] = [];
        entries.forEach((item, index) => {
            let id = item.id;

            newMenuItems[id] = [];
            newMenuItems[id].push({
                label: this.l('Download'),
                command: () => {
                    this.download(id);
                },
            });
            if (!isArchive) {
                newMenuItems[id].push({
                    label: this.l('Archiven'),
                    command: () => {
                        this.archive(id);
                    },
                });
            }

            newMenuItems[id].push({
                label: this.l('Delete'),
                command: () => {
                    this.delete(id);
                },
            });

        });
        return newMenuItems;
    }

    private createCustomMenuItems(entries: any, isArchive: boolean): MenuItem[] {
        let newCustomMenuItems: MenuItem[] = [];
        let archive = isArchive ? 'archive' : 'new';

        if (!isArchive) {
            newCustomMenuItems.push({
                label: this.l('ArchiveAll'),
                command: () => {
                    this.archiveAll();
                },
            });
        }

        newCustomMenuItems.push({
            label: this.l('DownloadAll'),
            command: () => {
                this.downloadAllNew(archive);
            },
        });

        newCustomMenuItems.push({
            label: this.l('DeleteAll'),
            command: () => {
                this.deleteAll(isArchive);
            },
        });

        return newCustomMenuItems;
    }


}
