import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {AppComponentBase} from "@shared/common/app-component-base";
import {ColumnConfigDto} from "@shared/service-proxies/service-proxies";

@Component({
    selector: "sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.css"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent extends AppComponentBase implements OnInit {
    @Input() visibleSidebar: boolean;
    @Output() visibleSidebarChange: EventEmitter<boolean> = new EventEmitter();
    @Input() visibleExtra: boolean;
    @Input() data: ColumnConfigDto[];
    @Input() widthBar: string = "28em";
    @Output() reset: EventEmitter<any> = new EventEmitter<any>();
    @Output() saveTable: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteConfig: EventEmitter<any> = new EventEmitter<any>();

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        //this.widthBar = "28em";
    }

    resetValue() {
        this.reset.emit();
    }

    saveTableConfig() {
        this.saveTable.emit();
    }

    deleteTableConfig() {
        this.deleteConfig.emit();
    }
}
