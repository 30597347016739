<div #createOrEditModal="bs-modal" [config]="{backdrop: false }" aria-hidden="true" aria-labelledby="createOrEditModal" bsModal
     class="modal fade" role="dialog" style="background-color:rgba(0, 0, 0, 0.5);"
     tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{"ChangeLogTitle" | localize}}</span>
                </h4>
                <button (click)="close()" aria-label="Close" class="close" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="text-align:left">
                    <div><label for="ChangeLogs">{{"ChangeLogs" | localize}}:</label>
                        {{changeLog?.changeLogs}}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="close()" class="btn btn-primary blue" type="button">{{"Close" | localize}}</button>
            </div>
        </div>
    </div>

</div>


