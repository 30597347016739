import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap/modal";
import { KpmgLookupTableComponent } from "@app/shared/common/kpmg-lookup-table/kpmg-lookup-table.component";
import {
    _definitions_CalculationMethod,
    _definitions_FederalState,
    _definitions_TableConfigurationType,
    EconomicUnitLookupTableDto,
    EconomicUnitType,
    GetAllEconomicUnitsInput,
    LookupServiceProxy
} from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "@shared/common/app-component-base";
import { TableUtilitiesService } from "@shared/utils/table-utils-service";
import { LazyLoadEvent } from "primeng/api/lazyloadevent";
import moment from "moment";
import { MomentHelper } from '@shared/helpers/MomentHelper';
import {TableLazyLoadEvent} from "primeng/table";

@Component({
    templateUrl: './economic-unit-lookup-template.component.html'
})
export class EconomicUnitLookupTemplateComponent extends AppComponentBase implements OnInit {
    @ViewChild('createOrEditModal') modal: ModalDirective;
    @ViewChild("dt") dt: KpmgLookupTableComponent;
    @Output() modalSave: EventEmitter<EconomicUnitLookupTableDto> = new EventEmitter<EconomicUnitLookupTableDto>();
    @Output() onViewItem: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    tableConfigType = _definitions_TableConfigurationType.LookupTable_EconomicUnits;

    id: number;
    displayName: string;
    municipalityDisplayName: string;
    municipalityCode: string;
    federalState: _definitions_FederalState;
    active = false;
    economicUnits: EconomicUnitLookupTableDto[] = [];
    totalRecords = 0;
    canViewItem = false;
    economicUnitType: EconomicUnitType;
    calculationMethod: _definitions_CalculationMethod;
    isEconomicUnitForBuilding: boolean = false;
    isEconomicUnitForDeclaration: boolean = false;
    dueDate: moment.Moment;

    constructor(
        injector: Injector,
        private _lookupsServiceProxy: LookupServiceProxy,
        private _tableUtilitiesService: TableUtilitiesService,
    ) {
        super(injector);
    }


    ngOnInit(): void {
    }

    setAndSave(event) {
        let economicUnit = <EconomicUnitLookupTableDto>event;
        this.active = false;
        this.modalSave.emit(economicUnit);
    }

    getAll(event?: TableLazyLoadEvent) {
        if (!event) {
            event = this.dt.table.createLazyLoadMetadata();
        }

        let input = this._tableUtilitiesService.createFilterObject(
            event,
            this.dt.columns
        ) as GetAllEconomicUnitsInput;

        input.isEconomicUnitForBuilding = this.isEconomicUnitForBuilding;
        input.isEconomicUnitForDeclaration = this.isEconomicUnitForDeclaration;
        input.dueDateFilter = MomentHelper.removeTimeZone(this.dueDate);

        this._lookupsServiceProxy
            .getAllCustomEconomicUnitForLookup(input)
            .subscribe(result => {
                this.totalRecords = result.totalCount;
                this.economicUnits = result.items;
            });
    }
}
