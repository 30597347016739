import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {TreeNode} from 'primeng/api';

@Injectable()
export class TreeDataHelperService {

    partialySelectNodes(treeNodes: Array<TreeNode>, selectedNodes: Array<TreeNode>) {
        _.forEach(treeNodes, node => {
            this.checkNodePartialSelected(node, selectedNodes);
        });
    }

    findNode(data, selector): any {
        let nodes = _.filter(data, selector);
        if (nodes && nodes.length === 1) {
            return nodes[0];
        }

        let foundNode = null;

        _.forEach(data, d => {
            if (!foundNode) {
                foundNode = this.findNode(d.children, selector);
            }
        });

        return foundNode;
    }

    findParent(data, nodeSelector) {
        let node = this.findNode(data, nodeSelector);
        if (!node) {
            return null;
        }

        return node.parent;
    }

    findChildren(data, selector) {
        let traverseChildren = function (node) {
            let names = [];
            if (node.children) {
                _.forEach(node.children, c => {
                    names.push(c.data.name);
                    names = names.concat(traverseChildren(c));
                });
            }
            return names;
        };

        let foundNode = this.findNode(data, selector);
        if (foundNode) {
            return traverseChildren(foundNode);
        } else {
            return [];
        }
    }

    private checkNodePartialSelected(node: TreeNode, selectedNodes: Array<TreeNode>) {
        if (!node.children) { // if node has no children, nothing to check
            return;
        }
        // check childs first, before checking current node,
        _.forEach(node.children, childNode => {
            this.checkNodePartialSelected(childNode, selectedNodes);
        });

        // if node has children, check if any is selected, but not all
        node.partialSelected = this.isPartialySelected(node.children, selectedNodes);
    }

    private isPartialySelected(treeNodes: Array<TreeNode>, selectedNodes: Array<TreeNode>): boolean {
        if (treeNodes.every((node, index) => selectedNodes.includes(node))) { // if all nodes are selected return false
            return false;
        }
        if (treeNodes.some((node, index) => selectedNodes.includes(node) || node.partialSelected)) {
            return true;
        }
        return false;
    }
}
