import {Directive, Host, HostListener, Self} from "@angular/core";
import {Calendar} from "primeng/calendar";

@Directive({
    selector: '[useUtc]'
})
export class UseUtcDirective {

    constructor(@Host() @Self() private calendar: Calendar) {
    }

    @HostListener('onSelect', ['$event']) onSelect() {
        this.toUtc();
    }

    @HostListener('onInput', ['$event']) onInput() {
        this.toUtc();
    }

    private toUtc() {
        if (this.calendar.value) {
            // check if value is array or date
            if (this.calendar.value instanceof Date) {
                this.calendar.value = new Date(Date.UTC(this.calendar.value.getFullYear()
                    , this.calendar.value.getMonth()
                    , this.calendar.value.getDate()
                    , 0, 0, 0));
            } else {

                if (this.calendar.value.length > 0 && !!this.calendar.value[0]) {
                    this.calendar.value[0] = new Date(Date.UTC(this.calendar.value[0].getFullYear()
                        , this.calendar.value[0].getMonth()
                        , this.calendar.value[0].getDate()
                        , 0, 0, 0));
                }
                if (this.calendar.value.length > 1 && !!this.calendar.value[1]) {
                    this.calendar.value[1] = new Date(Date.UTC(this.calendar.value[1].getFullYear()
                        , this.calendar.value[1].getMonth()
                        , this.calendar.value[1].getDate()
                        , 0, 0, 0));
                }
            }

            this.calendar.updateModel(this.calendar.value);
        }

    }
}
