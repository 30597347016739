import {Pipe, PipeTransform} from '@angular/core';
import moment from "moment-timezone";

@Pipe({name: 'm2d'})
export class MomentToDatePipe implements PipeTransform {
    transform(value: moment.Moment) {
        if (!value) {
            return null;
        }
        return value.toDate();
    }
}

@Pipe({name: 's2m'})
export class StringToMomentPipe implements PipeTransform {
    transform(value: string, format?: string) {
        if(!value){
            return null;
        }
        if(!format){
            return moment(value);
        }
        return moment(value, format);
    }
}

@Pipe({name: 'addDays'})
export class AddDaysDatePipe implements PipeTransform {
    transform(value: Date, days: number) {
        if (!value) {
            return null;
        }
        value.setDate(value.getDate() + days);
        return value;
    }
}
