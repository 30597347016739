import {Injectable, Injector} from "@angular/core";
import {AppComponentBase} from "@shared/common/app-component-base";
import * as _ from "lodash";

export interface WizardPage {
    finalLabel?: string;
    isErrorPage?: boolean;
    key?: string;
    questions: { [key: string]: WizardQuestion },

    GetNextQuestionKey(objectWithAllAuxVariables?: {}): string;//Function to get the next Key page and save some variable if is necessary
}

//-Not exist required flag because all questions are always required
export interface WizardQuestion {
    key?: string
    type: string,//can be number/radio/checkbox
    radioOptions?: RadioOption[],
    value?: any,//number->the value will be a number / radio will be a string
    min?: number,
    max?: number,
    allCheckboxChecked?: boolean,//value for checkbox
    checkboxObject?: CheckboxQuestion
}

export interface CheckboxQuestion {
    questionLabel: string;
    completed: boolean;
    subQuestions?: CheckboxQuestion[];
}

export interface RadioOption {
    key: string,
    value: string
}

@Injectable()
export class WizardTooltipHelperService extends AppComponentBase {
    public readonly INITIAL_PAGE = "screen1";
    allPages: { [key: string]: WizardPage } =
        {
            //FINAL PAGES RESULTS - Without questions - Without next button, only has back button
            "errorPage": {
                finalLabel: "InvalidErrorMessage",
                isErrorPage: true,
                questions: null,//If there are no questions, is a final page
                GetNextQuestionKey() {
                    return null;
                }
            },
            "lastPageOtherProperty": {
                finalLabel: "SonstigesBebautesGrundstück",
                questions: null,//If there are no questions, is a final page
                GetNextQuestionKey() {
                    return null;
                }
            },
            "lastPageCommercialProperty": {
                finalLabel: "Geschäftsgrundstücke",
                questions: null,//If there are no questions, is a final page
                GetNextQuestionKey() {
                    return null;
                }
            },
            "lastPageDetachedHouse": {
                finalLabel: "Einfamilienhaus",
                questions: null,//If there are no questions, is a final page
                GetNextQuestionKey() {
                    return null;
                }
            },
            "lastPageTwoFamilyHouse": {
                finalLabel: "Zweifamilienhaus",
                questions: null,//If there are no questions, is a final page
                GetNextQuestionKey() {
                    return null;
                }
            },
            "lastPageRentalProperty": {
                finalLabel: "Mietwohngrundstück",
                questions: null,//If there are no questions, is a final page
                GetNextQuestionKey() {
                    return null;
                }
            },
            "lastPageMixedLand": {
                finalLabel: "GemischtGenutzteGrundstücke",
                questions: null,//If there are no questions, is a final page
                GetNextQuestionKey() {
                    return null;
                }
            },
            "lastPageCondominumOwnership": {
                finalLabel: "Wohnungseigentum",
                questions: null,//If there are no questions, is a final page
                GetNextQuestionKey() {
                    return null;
                }
            },
            "lastPagePartialOwnerShip": {
                finalLabel: "Teileigentum",
                questions: null,//If there are no questions, is a final page
                GetNextQuestionKey() {
                    return null;
                }
            },
            //PAGES WITH QUESTIONS
            "screen1": {
                questions: {
                    "isPropertyOwnerOrPartial": {
                        type: "radio",
                        radioOptions: [
                            {
                                key: "Yes",
                                value: "Yes"
                            },
                            {
                                key: "No",
                                value: "No"
                            },
                        ]
                    },
                },
                GetNextQuestionKey() {
                    let q = this.questions["isPropertyOwnerOrPartial"];
                    if (!q.value) {
                        return "errorPage";
                    }
                    if (q.value == "Yes") {
                        return "screen2a";
                    }
                    return "screen2b";
                }
            },
            //OTHER PAGE
            "screen2a": {
                questions: {
                    "AreRoomsConnected": {
                        type: "radio",
                        radioOptions: [
                            {
                                key: "Yes",
                                value: "Yes"
                            },
                            {
                                key: "No",
                                value: "No"
                            },
                        ]
                    },
                    "AreRoomsSeparated": {
                        type: "radio",
                        radioOptions: [
                            {
                                key: "Yes",
                                value: "Yes"
                            },
                            {
                                key: "No",
                                value: "No"
                            },
                        ]
                    },
                    "CanAccessRooms": {
                        type: "radio",
                        radioOptions: [
                            {
                                key: "Yes",
                                value: "Yes"
                            },
                            {
                                key: "No",
                                value: "No"
                            },
                        ]
                    },
                    "AreFollowingRoomsAvailable": {
                        type: "checkbox",
                        allCheckboxChecked: false,
                        checkboxObject: {
                            completed: false,
                            questionLabel: "All",
                            subQuestions: [{
                                completed: false,
                                questionLabel: "Kitchen",
                            },
                                {
                                    completed: false,
                                    questionLabel: "BathOrShower",
                                },
                                {
                                    completed: false,
                                    questionLabel: "Toilette",
                                }]
                        }
                    },
                    "SizeOfRooms": {
                        type: "number",
                        min: 1
                    }
                },
                GetNextQuestionKey() {
                    if ((!this.questions["AreRoomsConnected"].value)
                        || (!this.questions["AreRoomsSeparated"].value)
                        || (!this.questions["CanAccessRooms"].value)
                        || (!this.questions["SizeOfRooms"].value)) {
                        return "errorPage";//MISSING VALUES
                    }
                    if (this.questions["AreRoomsConnected"].value == "No"
                        || this.questions["AreRoomsSeparated"].value == "No"
                        || this.questions["CanAccessRooms"].value == "No"
                        || !this.questions["AreFollowingRoomsAvailable"].allCheckboxChecked
                        || this.questions["SizeOfRooms"].value < 20) {
                        return "lastPagePartialOwnerShip"
                    }
                    return "lastPageCondominumOwnership";
                }
            },
            //OTHER PAGE
            "screen2b": {
                questions: {
                    "howWillRatioBetween": {
                        type: "radio",
                        radioOptions: [
                            {
                                key: "ratioPercentAvailable",
                                value: "ratioPercentAvailable"
                            },
                            {
                                key: "ratioSizeShouldBeCalculate",
                                value: "ratioSizeShouldBeCalculate"
                            },
                        ]
                    },
                },
                GetNextQuestionKey() {
                    let q = this.questions["howWillRatioBetween"];
                    if (!q) {
                        return "errorPage";
                    }
                    if (q.value == "ratioPercentAvailable") {
                        return "screen3a";
                    }
                    return "screen3b";
                }
            },
            //OTHER PAGE
            "screen3a": {
                questions: {
                    "percentageLivingSpace": {
                        type: "number",
                        min: 0,
                        max: 100
                    }
                },
                GetNextQuestionKey(objectWithAllAuxVariables) {
                    let q = this.questions["percentageLivingSpace"];
                    if (!q) {
                        return "errorPage";
                    }
                    objectWithAllAuxVariables["PW"] = q.value;
                    if (q.value > 50) {
                        return "screen4a";
                    }
                    return "screen4b";
                }
            },
            //OTHER PAGE
            "screen3b": {
                questions: {
                    "buildingSizeQ": {
                        type: "number",
                        min: 1
                    },
                    "livingAreaSizeQ": {
                        type: "number",
                        min: 0
                    }
                },
                GetNextQuestionKey(objectWithAllAuxVariables) {
                    let BS = this.questions["buildingSizeQ"];
                    let LAS = this.questions["livingAreaSizeQ"];
                    if (!BS || !LAS) {
                        return "errorPage";
                    }
                    let PW = (LAS.value / BS.value) * 100;
                    if (PW > 100) {
                        return "errorPage"; //PW should be <= 100
                    }
                    if (PW > 50) {
                        objectWithAllAuxVariables["PW"] = PW;
                        return "screen4a";
                    }
                    objectWithAllAuxVariables["BS"] = BS.value;
                    return "screen4c";
                }
            },
            //OTHER PAGE
            "screen4a": {
                questions: {
                    "nrOfRentees": {
                        type: "number",
                        min: 0
                    }
                },
                GetNextQuestionKey(objectWithAllAuxVariables) {
                    let pw = objectWithAllAuxVariables["PW"];
                    let aw = this.questions["nrOfRentees"];
                    if (!aw || pw == null || pw == undefined) { //the values can be zero.
                        return "errorPage";
                    }
                    if (aw.value === 1 || aw.value === 2) {
                        if (pw === 100) {
                            if (aw.value === 1) {
                                return "lastPageDetachedHouse";
                            } else if (aw.value === 2) {
                                return "lastPageTwoFamilyHouse";
                            }
                        } else {
                            objectWithAllAuxVariables["AW"] = aw.value;
                            return "screen5a";
                        }
                    } else {
                        if (pw > 80) {
                            return "lastPageRentalProperty";
                        } else {
                            return "lastPageMixedLand";
                        }
                    }
                    return "errorPage";
                }
            },
            //OTHER PAGE
            "screen4b": {
                questions: {
                    "percentageCommercialArea": {
                        type: "number",
                        min: 0,
                        max: 100
                    }
                },
                GetNextQuestionKey(objectWithAllAuxVariables) {
                    let pw = objectWithAllAuxVariables["PW"];
                    let ca = this.questions["percentageCommercialArea"];
                    if (!ca || pw == null || pw == undefined) {
                        return "errorPage";
                    }
                    let pb = (pw + ca.value)
                    if (pb > 100) {
                        return "errorPage";//PW + PB has to be <= 100
                    }
                    if (ca.value > 80) {
                        return "lastPageCommercialProperty";
                    }
                    return "screen5b";
                }
            },
            //OTHER PAGE
            "screen4c": {
                questions: {
                    "commercialUsedArea": {
                        type: "number",
                        min: 0
                    }
                },
                GetNextQuestionKey(objectWithAllAuxVariables) {
                    let BS = objectWithAllAuxVariables["BS"];
                    let q = this.questions["commercialUsedArea"];
                    if (!q || BS == null || BS == undefined) {
                        return "errorPage";
                    }
                    let PB = (q.value / BS) * 100;
                    if (PB > 100) {
                        return "errorPage";//PB has to be <= 100
                    }
                    if (PB > 80) {
                        return "lastPageCommercialProperty";
                    }
                    return "screen5b";
                }
            },
            //OTHER PAGE
            "screen5a": {
                questions: {
                    "isTypeInfluenced": {
                        type: "radio",
                        radioOptions: [
                            {
                                key: "Yes",
                                value: "Yes"
                            },
                            {
                                key: "No",
                                value: "No"
                            },
                        ]
                    },
                },
                GetNextQuestionKey(objectWithAllAuxVariables) {
                    let aw = objectWithAllAuxVariables["AW"];
                    let q = this.questions["isTypeInfluenced"];
                    if (!q.value) {
                        return "errorPage";
                    }
                    if (q.value == "Yes") {
                        return "lastPageMixedLand";
                    }
                    if (!aw) {
                        return "errorPage";
                    }

                    if (aw === 1) {
                        return "lastPageDetachedHouse";
                    } else if (aw === 2) {
                        return "lastPageTwoFamilyHouse";
                    }
                }
            },
            //OTHER PAGE
            "screen5b": {
                questions: {
                    "doesIncludeLivingAndCommercial": {
                        type: "radio",
                        radioOptions: [
                            {
                                key: "Yes",
                                value: "Yes"
                            },
                            {
                                key: "No",
                                value: "No"
                            },
                        ]
                    },
                },
                GetNextQuestionKey() {
                    let q = this.questions["doesIncludeLivingAndCommercial"];
                    if (!q.value) {
                        return "errorPage";
                    }
                    if (q.value == "Yes") {
                        return "lastPageMixedLand";
                    }
                    return "lastPageOtherProperty";
                }
            },
        };

    constructor(injector: Injector) {
        super(injector);

    }

    GetInitalPage(): WizardPage {
        return this.GetPage(this.INITIAL_PAGE);
    }

    GetPage(key: string): WizardPage {
        if (this.allPages[key]) {
            //_Clone because we want to return a new object and not the reference
            return _.cloneDeep(this.allPages[key]);
        }
        return null;
    }

}
