import {AppConsts} from '@shared/AppConsts';
import * as _ from 'lodash';
import {FileLikeObject, FileUploader, FileUploaderOptions} from "ng2-file-upload";


export class AttachmentFileUploader extends FileUploader {


    constructor()
    constructor(options?: FileUploaderOptions) {
        super(options);
        if (!options) {
            options = {
                url: undefined
            };
        }
        this.setOptions(options);
        this.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
            let elem = event.target as HTMLInputElement;
            elem.value = ''; // remove the label, the file was not added to the uploader, but it shows the label
            abp.message.error(abp.localization.localize('File_Invalid_Type_Error', AppConsts.localization.defaultLocalizationSourceName)).then(() => {
                this.onAddingFailed(item, filter, options);
            });
        };
    }

    setOptions(options: FileUploaderOptions) {
        _.merge(options, {allowedMimeType: abp.custom.attachmentMimeTypes, autoUpload: false});
        super.setOptions(options);
    }

    onAddingFailed(item: FileLikeObject, filter: any, options: any) {

    }
}
