<div #changePasswordModal="bs-modal" (onShown)="onShown()" [config]="{backdrop: 'static'}" aria-hidden="true" aria-labelledby="myLargeModalLabel" bsModal
     class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #changePasswordModalForm="ngForm" (ngSubmit)="save()" *ngIf="active">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"ChangePassword" | localize}}</span>
                    </h5>
                    <button (click)="close()" [attr.aria-label]="l('Close')" class="close" type="button">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="CurrentPassword">{{"CurrentPassword" | localize}}</label>
                        <input #currentPasswordInput="ngModel" [(ngModel)]="currentPassword" autocomplete="off"
                               class="form-control" id="CurrentPassword" name="CurrentPassword"
                               required type="password">
                        <validation-messages [formCtrl]="currentPasswordInput"></validation-messages>
                    </div>
                    <div class="form-group">
                        <label for="NewPassword">{{"NewPassword" | localize}}</label>
                        <input #NewPassword="ngModel" [(ngModel)]="password" [requireDigit]="passwordComplexitySetting.requireDigit" [requireLowercase]="passwordComplexitySetting.requireLowercase"
                               [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric" [requireUppercase]="passwordComplexitySetting.requireUppercase" [requiredLength]="passwordComplexitySetting.requiredLength"
                               autocomplete="off"
                               class="form-control" id="NewPassword"
                               name="NewPassword"
                               required
                               reverse="true"
                               type="password"
                               validateEqual="NewPasswordRepeat">
                    </div>
                    <div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
                        <ul *ngIf="NewPassword.errors" class="help-block text-danger">
                            <li [hidden]="!NewPassword.errors.requireDigit">{{"PasswordComplexity_RequireDigit_Hint" | localize}}</li>
                            <li [hidden]="!NewPassword.errors.requireLowercase">{{"PasswordComplexity_RequireLowercase_Hint" | localize}}</li>
                            <li [hidden]="!NewPassword.errors.requireUppercase">{{"PasswordComplexity_RequireUppercase_Hint" | localize}}</li>
                            <li [hidden]="!NewPassword.errors.requireNonAlphanumeric">{{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                            <li [hidden]="!NewPassword.errors.requiredLength">{{"PasswordComplexity_RequiredLength_Hint" | localize:passwordComplexitySetting.requiredLength}}</li>
                        </ul>
                    </div>
                    <div class="form-group">
                        <label for="NewPasswordRepeat">{{"NewPasswordRepeat" | localize}}</label>
                        <input #NewPasswordRepeat="ngModel" [ngModel]="passwordConfirm" [requireDigit]="passwordComplexitySetting.requireDigit" [requireLowercase]="passwordComplexitySetting.requireLowercase"
                               [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric" [requireUppercase]="passwordComplexitySetting.requireUppercase" [requiredLength]="passwordComplexitySetting.requiredLength"
                               autocomplete="off"
                               class="form-control"
                               id="NewPasswordRepeat"
                               name="NewPasswordRepeat"
                               required
                               reverse="false"
                               type="password" validateEqual="NewPassword">
                    </div>
                    <div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
                        <ul *ngIf="NewPasswordRepeat.errors" class="help-block text-danger">
                            <li [hidden]="!NewPasswordRepeat.errors.requireDigit">{{"PasswordComplexity_RequireDigit_Hint" | localize}}</li>
                            <li [hidden]="!NewPasswordRepeat.errors.requireLowercase">{{"PasswordComplexity_RequireLowercase_Hint" | localize}}</li>
                            <li [hidden]="!NewPasswordRepeat.errors.requireUppercase">{{"PasswordComplexity_RequireUppercase_Hint" | localize}}</li>
                            <li [hidden]="!NewPasswordRepeat.errors.requireNonAlphanumeric">{{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                            <li [hidden]="!NewPasswordRepeat.errors.requiredLength">{{"PasswordComplexity_RequiredLength_Hint" | localize:passwordComplexitySetting.requiredLength}}</li>
                            <li [hidden]="NewPasswordRepeat.valid">{{"PasswordsDontMatch" | localize}}</li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="close()" [disabled]="saving" class="btn btn-secondary"
                            type="button">{{"Cancel" | localize}}</button>
                    <button [disabled]="!changePasswordModalForm.form.valid || saving" class="btn btn-primary"
                            type="submit"><i class="fa fa-save"></i>
                        <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
