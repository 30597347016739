import { ChangeDetectorRef, Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MomentHelper } from '@shared/helpers/MomentHelper';
import {
    DownloadType,
    MyGeneralSettingsDto,
    ProfileServiceProxy,
    SelectListItem,
    SettingScopes
} from '@shared/service-proxies/service-proxies';
import { ModalDirective } from "ngx-bootstrap/modal";
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'mySettingsModal',
    templateUrl: './my-settings-modal.component.html'
})
export class MySettingsModalComponent extends AppComponentBase {

    @ViewChild('mySettingsModal') modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    public active = false;
    public saving = false;
    public isGoogleAuthenticatorEnabled = false;
    public showTimezoneSelection: boolean = abp.clock.provider.supportsMultipleTimezone;
    public canChangeUserName: boolean;
    public defaultTimezoneScope: SettingScopes = SettingScopes.User;
    public savedPhoneNumber: string;
    public newPhoneNumber: string;
    public downloadType: DownloadType;
    public timezone: string = "UTC";
    dueDate!: moment.Moment | undefined;
    public statusChangeNotification: boolean = true;
    public newDeclarationNotification: boolean = false;
    public deadlineNotification: boolean = false;

    timezones: SelectListItem[];
    downloadTypes = [{
        text: this.l('DirectDownload'),
        value: 1
    }, {
        text: this.l('Inbox'),
        value: 2
    }];

    constructor(
        injector: Injector,
        private _profileService: ProfileServiceProxy,
        private _cd: ChangeDetectorRef
    ) {
        super(injector);
    }

    show(): void {
        this.active = true;
        this._profileService.getAllMyGeneralSettings().subscribe((result) => {
            this.downloadType = result.downloadType;
            this.timezone = result.timeZone;
            this.timezones = result.timeZones;
            this.dueDate = result.dueDate;
            this.statusChangeNotification = result.statusChangeNotification;
            this.newDeclarationNotification = result.newDeclarationNotification;
            this.deadlineNotification = result.deadlineNotification;
            this._cd.detectChanges();
            this.modal.show();
        });
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    save(): void {
        this.saving = true;
        this._profileService.updateMyGeneralSettings(
            new MyGeneralSettingsDto({
                downloadType: this.downloadType,
                timeZone: this.timezone,
                statusChangeNotification: this.statusChangeNotification,
                newDeclarationNotification: this.newDeclarationNotification,
                deadlineNotification: this.deadlineNotification,
                dueDate: MomentHelper.removeTimeZone(this.dueDate)
            }))
            .pipe(finalize(() => {
                this.saving = false;
            }))
            .subscribe(() => {

                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
                window.location.reload();

            });
    }
}
