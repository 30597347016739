<div class="input-group">
    <div *ngIf="file && (file.storageFileId || file.binaryObjectId) && !file.isDeleted" class="input-group-prepend">
        <button (click)="downloadAttachment(file)" [disabled]="viewOnly" class="btn btn-secondary"
                style="color: #343a40 !important"
                type="button">
            <i class="fa fa-download"></i>
        </button>
    </div>
    <div [ngClass]="{ 'custom-file-readonly': viewOnly, 'custom-file': !viewOnly }">
        <input #fileInput [accept]="mimetypes" [disabled]="viewOnly" [multiple]="multiple" [required]="required" [title]="multiple ? l('ChooseFiles') : getFileLabel()"
               [uploader]="uploader" class="custom-file-input" fileSelect
               type="file">
        <label [title]="multiple ? l('ChooseFiles') : getFileLabel()" class="custom-file-label">
            <span>{{ multiple ? l('ChooseFiles') : getFileLabel() }}</span>
        </label>
    </div>
    <div *ngIf="(file && !file.isDeleted) || filesReadyForUpload()" class="input-group-append">
        <button (click)="clear()" [disabled]="viewOnly" class="btn btn-warning" type="button">{{l('Clear')}}</button>
    </div>
</div>
<!--div class="table-responsive" *ngIf="multiple && files && files.length > 0">
    <table class="table table-sm">
        <tbody>
            <tr *ngFor="let file of files; let i = index" [hidden]="file.isDeleted"
                [ngClass]="{'new-file' : file.id === 0}">
                <td>
                    {{ file.name }}
                </td>
                <td>
                    <i class="fa fa-download" (click)="downloadAttachment(files[i])" *ngIf="file.storageFileId"></i>
                    <i class="fa fa-trash" (click)="deleteAttachment(i)" *ngIf="file.storageFileId && !viewOnly"></i>
                </td>
            </tr>
        </tbody>
    </table>
</div-->
<br>
<div *ngIf="!light" class="m-scrollable m-scroller ps ps--active-y" data-height="200" data-scrollable="true"
     data-scrollbar-shown="true">
    <div *ngFor="let file of files; let i = index">
        <div *ngIf="!file.isDeleted" class="form-group m-form__group row">
            <div class="col-lg-5">
                <span style="font-weight: bold">{{ l("File") }}:</span><br/>
                <span data-placement="top" data-toggle="tooltip"  style="word-break: break-word;">
                    {{file.name}}
                </span>
            </div>
            <div class="col-lg-4">
                <span style="font-weight: bold">{{ l("Type") }}:</span><br/>
                <span style="word-break: break-word;">
                    {{file.fileType}}
                </span>
            </div>
            <div class="col-lg-1">
                
                <i (click)="downloadAttachment(files[i])" *ngIf="file.storageFileId" class="fa fa-download"></i>
            </div>
            <div class="col-lg-1">
              
                <i (click)="deleteAttachment(i)" *ngIf="!viewOnly" class="fa fa-trash"></i>
            </div>
        </div>
    </div>
</div>
<ul *ngIf="light" class="m-scrollable m-scroller ps ps--active-y" data-height="200" data-scrollable="true"
    data-scrollbar-shown="true"
    id="fu-selected-file-list">
    <li *ngFor="let file of files; let i = index">
        <div *ngIf="!file.isDeleted" class="form-group m-form__group row">
            <div class="col-lg-8">
                <span data-placement="top" data-toggle="tooltip">
                    {{file.name}}
                </span>
            </div>
            <div class="col-lg-2">
                <i (click)="downloadAttachment(files[i])" *ngIf="file.storageFileId" class="fa fa-download"></i>
            </div>
            <div class="col-lg-2">
                <i (click)="deleteAttachment(i)" *ngIf="!viewOnly" class="fa fa-trash"></i>
            </div>
        </div>
    </li>
</ul>

