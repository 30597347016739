import {Directive, ElementRef} from '@angular/core';
import {FileSelectDirective} from 'ng2-file-upload';

@Directive({
    selector: '[fileSelect]'
})
export class KpmgFileSelectDirective extends FileSelectDirective {

    constructor(element: ElementRef) {
        super(element);
    }

    isEmptyAfterSelection() {
        return false;
    }
}
