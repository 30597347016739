import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment-timezone';

@Pipe({name: 'momentFromNow'})
export class MomentFromNowPipe implements PipeTransform {
    transform(value: moment.MomentInput) {
        if (!value) {
            return '';
        }

        return moment(value).fromNow();
    }
}
