import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment-timezone';

@Pipe({name: 'momentFormat'})
export class MomentFormatPipe implements PipeTransform {
    transform(value: moment.MomentInput, format: string) {
        if (!value) {
            return '';
        }
        if (moment.isMoment(value)) {
            return value.format(format);
        }
        return moment(value).format(format);
    }
}
