import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[kCaptionExtra]'
})
export class KpmgTableCaptionExtraDirective {

    constructor(private container: ViewContainerRef,
                public templateRef: TemplateRef<any>) {

    }

}
