<!-- BEGIN: Topbar -->
<div class="m-topbar m-stack m-stack--ver m-stack--general" id="m_header_topbar">
    <nav [attr.aria-label]="l('TopMenu')" class="m-stack__item m-topbar__nav-wrapper">

        <ul aria-label="TopMenu" class="m-topbar__nav m-nav m-nav--inline" role="menubar">
            <li *ngIf="tenantType === 0"
                class="red ml-auto m-nav__item m-topbar__languages m-dropdown m-dropdown--small m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width ">
                <span class="m-nav__link m-dropdown__toggle kpmg-name">
                    <span class="m-nav__link-text red"><i class="fa fa-exclamation-triangle "></i>{{ l('DemoTenant')}}
                    </span>
                </span>
            </li>
            <li *ngIf="languages.length > 1"
                class="ml-auto m-nav__item m-topbar__languages m-dropdown m-dropdown--small m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width"
                m-dropdown-toggle="click">
                <a class="m-nav__link m-dropdown__toggle kpmg-name" href="javascript:">
                    <span class="m-nav__link-text">
                        <i [class]="currentLanguage.icon"></i>
                        <!-- <span class="m-menu__link-text">
                                {{currentLanguage.displayName}}
                            </span> -->
                        <!-- <i class="fa fa-angle-down"></i> -->
                    </span>
                </a>
                <div class="m-dropdown__wrapper">
                    <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                    <div class="m-dropdown__inner">
                        <div class="m-dropdown__body">
                            <div class="m-dropdown__content">
                                <ul class="m-nav m-nav--skin-light">
                                    <ng-container *ngFor="let language of languages">
                                        <li [ngClass]="{'m-nav__item--active': language.active}" class="m-nav__item">
                                            <a (click)="changeLanguage(language.name)" class="m-nav__link"
                                                href="javascript:">
                                                <span class="m-nav__link-icon">
                                                    <span class="m-nav__link-icon-wrapper">
                                                        <i [class]="language.icon"></i>
                                                    </span>
                                                </span>
                                                <span
                                                    class="m-nav__link-title m-topbar__language-text m-nav__link-text">{{language.displayName}}</span>
                                            </a>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li *ngIf="subscriptionStatusBarVisible()"
                class="m-nav__item m-topbar__languages m-dropdown m-dropdown--small m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width d-none d-md-inline-block">
                <a *ngIf="appSession.tenant.isInTrialPeriod && !subscriptionIsExpiringSoon()"
                    class="m-nav__link m-dropdown__toggle" href="javascript:">
                    <span class="m-nav__link-text glowing">
                        <i class="fa fa-exclamation-triangle"></i>
                        <span [innerHTML]="getTrialSubscriptionNotification()" class="m-menu__link-text ml-1"></span>
                    </span>
                </a>
                <a *ngIf="subscriptionIsExpiringSoon()" class="m-nav__link"
                    routerLink="/app/admin/subscription-management">
                    <span *ngIf="subscriptionIsExpiringSoon() && appSession.tenant.isInTrialPeriod"
                        class="m-nav__link-text glowing">
                        <i class="fa fa-exclamation-triangle"></i>
                        <span class="m-menu__link-text ml-1">{{getExpireNotification("TrialExpireNotification")}}</span>
                    </span>
                    <span *ngIf="subscriptionIsExpiringSoon() && !appSession.tenant.isInTrialPeriod"
                        class="m-nav__link-text glowing">
                        <i class="fa fa-exclamation-triangle"></i>
                        <span
                            class="m-menu__link-text ml-1">{{getExpireNotification("SubscriptionExpireNotification")}}</span>
                    </span>
                </a>
            </li>
            <li *ngIf="isQuickThemeSelectEnabled" class="m-nav__item" id="m_theme_selection_panel_toggle">
                <a aria-expanded="false" aria-haspopup="true" class="m-nav__link" href="javascript:" role="menuitem"
                    tabindex="-1">
                    <span class="m-nav__link-icon">
                        <span class="m-nav__link-icon-wrapper">
                            <i class="flaticon-interface-7"></i>
                        </span>
                    </span>
                </a>
            </li>
            <li *ngIf="knowledgeBoxItems && knowledgeBoxItems.length > 0"
                class="m-dropdown--header-bg-fill ml-auto m-nav__item m-dropdown m-dropdown--small m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width"
                m-dropdown-toggle="click">
                <a class="m-nav__link m-dropdown__toggle" href="javascript:" role="menuitem">
                    <span class="m-nav__link-icon">
                        <span class="m-nav__link-icon-wrapper">
                            <!-- <i class="la la-2x la-graduation-cap"></i> -->
                            <i [style.background-image]='graduationIconBackground' class="graduation-cap"></i>
                            <!-- <i class="flaticon-light kpmg-notify-icn"></i> -->
                        </span>
                    </span>
                </a>

                <div class="m-dropdown__wrapper" style="width:600px;">
                    <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                    <div class="m-dropdown m-dropdown__inner" style="width:770px;">
                        <div class="m-dropdown__header m--align-center">
                            <span class="m-dropdown__header-title m-dropdown--header-bg-fill">
                                {{"KnowledgeBox" | localize}}
                            </span>
                        </div>
                        <div class="m-dropdown__body ">
                            <div class="m-dropdown__content" style="max-height: 300px; overflow-y: auto;">
                                <ul class="m-nav m-nav--skin-light">
                                    <li *ngFor="let item of knowledgeBoxItems" class="m-nav__item">
                                        <span *ngIf="item.data.isEnabled"
                                            class="m-dropdown__header-title m-dropdown--header-bg-fill knowledgeBoxItemHeading">{{ item.data.text }}</span>
                                        <ul *ngIf="item.data.isEnabled">
                                            <li *ngFor="let child of getEnabledChildren(item)" class="children">
                                                <span (click)="downloadFile(child)" *ngIf="child.data.type == 'file'"
                                                    class="m-link m-link--primary topBarChildItem link-topbar-theme"
                                                    style="cursor: pointer;">
                                                    {{child.data.text}}
                                                </span>
                                                <a *ngIf="child.data.type == 'link'" [attr.href]="child.data.name"
                                                    class="m-link m-link--primary topBarChildItem link-topbar-theme"
                                                    target="_blank">{{ child.data.text}}</a>
                                                <a *ngIf="child.data.type == 'mail-to'"
                                                    [attr.href]="'mailto:' + child.data.name"
                                                    class="m-link m-link--primary topBarChildItem link-topbar-theme"
                                                    target="_blank">{{child.data.text}}</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="ml-auto m-nav__item">
                <a class="m-nav__link m-dropdown__toggle" href="javascript:" id="m_topbar_notification_icon_not"
                    (click)="goToMyInbox()">
                    <!--                    <span *ngIf="newInboxItems > 0" [ngClass]="{'m-badge&#45;&#45;danger' : newInboxItems}"-->
                    <!--                    class="m-nav__link-badge m-badge m-badge&#45;&#45;dot m-badge&#45;&#45;dot-small"></span>-->
                    <!-- <span *ngIf="newInboxItems > 0" class="m-nav__link-badge m-badge m-badge--dot m-badge--dot-small">{{newInboxItems}}</span>-->
                    <span class="m-nav__link-icon">
                        <span class="m-nav__link-icon-wrapper filter-none">
                            <i class="flaticon-folder-2 kpmg-notify-icn" *ngIf="newInboxItems > 0" pBadge
                                [value]="newInboxItems.toString()" severity="danger">
                            </i>
                            <i class="flaticon-folder-2 kpmg-notify-icn" *ngIf="newInboxItems == 0">
                            </i>
                        </span>
                    </span>
                </a>
            </li>
            <li class="m-dropdown--header-bg-fill ml-auto m-nav__item m-dropdown m-dropdown--small m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width"
                m-dropdown-toggle="click">
                <a class="m-nav__link m-dropdown__toggle" href="javascript:" role="menuitem">
                    <span class="m-nav__link-icon">
                        <span class="m-nav__link-icon-wrapper">
                            <i class="flaticon-questions-circular-button kpmg-notify-icn"></i>
                        </span>
                    </span>
                </a>

                <div class="m-dropdown__wrapper">
                    <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                    <div class="m-dropdown m-dropdown__inner">
                        <div class="m-dropdown__header m--align-center">
                            <span class="m-dropdown__header-title m-dropdown--header-bg-fill">
                                {{"Help" | localize}}
                            </span>
                        </div>
                        <div class="m-dropdown__body">
                            <div class="m-dropdown__content">
                                <ul class="m-nav m-nav--skin-light">
                                    <!-- <li class="m-nav__item">
                                        <a (click)="downloadManual()" class="m-link m-link--primary"
                                           href="javascript:">{{"Manual" | localize}}</a>
                                    </li> -->
                                    <li class="m-nav__item">
                                        <a (click)="downloadChangelog()" class="m-link m-link--primary"
                                            href="javascript:">{{"ChangeLogTitle" | localize}}</a>
                                    </li>
                                    <li class="m-nav__item">
                                        <a (click)="showVersionInfo()" class="m-link m-link--primary"
                                            href="javascript:">{{"VersionInfo" | localize}}</a>
                                    </li>
                                    <li class="m-nav__separator m-nav__separator--fit"></li>
                                    <li class="m-nav__item">
                                        <a (click)="showOpenSourceComponents()" class="m-link m-link--primary"
                                            href="javascript:">{{"OpenSourceComponents" | localize}}</a>
                                    </li>
                                    <li class="m-nav__item">
                                        <a class="m-link m-link--primary"
                                            href="https://home.kpmg/de/de/home/misc/legal.html"
                                            target="_blank">{{"Legal" | localize}}</a>
                                    </li>
                                    <li class="m-nav__item">
                                        <a class="m-link m-link--primary"
                                            href="https://home.kpmg/de/de/home/misc/privacy.html"
                                            target="_blank">{{"Privacy" | localize}}</a>
                                    </li>
                                    <li class="m-nav__item">
                                        <a (click)="downloadDisclaimer()" class="m-link m-link--primary"
                                            href="javascript:">{{"Disclaimer" | localize}}</a>
                                    </li>

                                    <li class="m-nav__separator m-nav__separator--fit"></li>
                                    <li *ngIf="isNeedHelpEnabled" class="m-nav__item">
                                        <a (click)="showSupportMailModal()" class="m-link m-link--primary"
                                            href="javascript:">{{"SupportMail.NeedHelp" | localize}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width"
                data-dropdown-persistent="true" headerNotifications id="header_notification_bar"
                m-dropdown-toggle="click"></li>

            <!-- <li *ngIf="(isHost || ('App.ChatFeature' | checkFeature)) && !chatConnected"
                class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width">
                <a href="javascript:;" class="m-nav__link" role="menuitem" aria-haspopup="true" aria-expanded="false"
                    tabindex="-1">
                    <span class="m-nav__link-icon">
                        <span class="m-nav__link-icon-wrapper">
                            <img *ngIf="!chatConnected" src="./assets/common/images/loading.gif" style="width: 23px;"
                                tooltip="{{'ChatIsConnecting' | localize}}" placement="left" />
                        </span>
                    </span>
                </a>
            </li> -->
            <li class="pr-0 m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                m-dropdown-toggle="click">
                <a class="m-nav__link m-dropdown__toggle" href="#">
                    <span class="m-topbar__userpic">
                        <!-- <img *ngIf="profilePicture" alt="" class="header-profile-picture m--img-rounded m--marginless m--img-centered" [src]="profilePicture" /> -->
                        <img [src]="appBaseHref() + 'assets/common/images/custom/header_profile_icon_blue.png'"
                            alt="User Profile" class="icon-top-bar" width="24px">
                    </span>
                    <!-- <span class="m-topbar__username">
                        <i *ngIf="isImpersonatedLogin" class="fa fa-reply m--font-danger"></i>
                        <span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{tenancyName}}\</span>{{userName}}
                        <i class="fa fa-angle-down" style="color: #00338d"></i>
                    </span> -->
                </a>
                <div class="m-dropdown__wrapper kpmg-user-dropdown">
                    <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                    <div class="m-dropdown__inner">
                        <div class="m-dropdown__header m--align-center">
                            <div class="m-card-user m-card-user--skin-dark">
                                <div class="m-card-user__pic">
                                    <img [src]="profilePicture" alt=""
                                        class="header-profile-picture m--img-rounded m--marginless m--img-centered" />
                                </div>
                                <div class="m-card-user__details">
                                    <span class="m-card-user__name m--font-weight-500">
                                        {{shownLoginName}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="m-dropdown__body">
                            <nav [attr.aria-label]="l('UserAccount')" class="m-dropdown__content">
                                <ul [attr.aria-label]="l('UserAccount')" class="m-nav m-nav--skin-light" role="menu">
                                    <li *ngIf="isImpersonatedLogin" class="m-nav__item" role="none">
                                        <a (click)="backToMyAccount()" aria-expanded="false" aria-haspopup="true"
                                            class="m-nav__link" href="javascript:" role="menuitem" tabindex="-1">
                                            <i class="m-nav__link-icon fa fa-reply m--font-danger"></i>
                                            <span class="m-nav__link-title">
                                                <span class="m-nav__link-wrap">
                                                    <span class="m-nav__link-text"
                                                        id="UserProfileBackToMyAccountButton">
                                                        {{"BackToMyAccount" | localize}}
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </li>


                                    <li class="m-nav__item" role="none">
                                        <a (click)="changeMySettings()" aria-expanded="false" aria-haspopup="true"
                                            class="m-nav__link" href="javascript:" role="menuitem" tabindex="-1">
                                            <i class="m-nav__link-icon flaticon-cogwheel"></i>
                                            <span class="m-nav__link-title">
                                                <span class="m-nav__link-wrap">
                                                    <span class="m-nav__link-text" id="UserProfileMySettingsLink">
                                                        {{"MySettings" | localize}}
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </li>
                                    <!-- <li class="m-nav__item" role="none">
                                        <a (click)="goToMyInbox()" aria-expanded="false" aria-haspopup="true"
                                            class="m-nav__link" href="javascript:" role="menuitem" tabindex="-1">
                                            <i class="m-nav__link-icon flaticon-folder-2"></i>
                                            <span class="m-nav__link-title">
                                                <span class="m-nav__link-wrap">
                                                    <span class="m-nav__link-text" id="InboxLink">
                                                        {{"MyInbox" | localize}}
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </li> -->
                                    <li class="m-nav__separator m-nav__separator--fit" role="none"></li>
                                    <li class="m-nav__item" role="none">
                                        <a (click)="logout()" aria-expanded="false" aria-haspopup="true"
                                            class="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                            href="javascript:" role="menuitem" tabindex="-1">{{"Logout" | localize}}</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </nav>
</div>
<!-- END: Topbar -->
<inbox-component #inboxModal (modalClose)="getInboxMetadata(false)"></inbox-component>