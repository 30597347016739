<!-- BEGIN: Brand -->
<div class="m-stack m-stack--ver m-stack--general">
    <div class="m-stack__item m-stack__item--middle m-brand__logo">
        <a class="m-brand__logo-wrapper" routerLink="/" target="_blank">
            <img *ngIf="!appSession.tenant || !appSession.tenant.hasLogo"
                 [src]="appRootUrl() + 'assets/common/images/custom/kpmg-logo.png'" alt="logo" height="38" class="max-width-px-110"/>
            <img *ngIf="appSession.tenant && appSession.tenant.hasLogo"
                 [src]="remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin=' + currentTheme.baseSettings.menu.asideSkin + '&tenantId=' + appSession.tenant.id + '&id=' + appSession.tenant.logoId"
                 alt="logo" height="38" class="max-width-px-110"/>
        </a>
    </div>
    <div class="m-stack__item m-stack__item--middle m-brand__tools">
        <!-- BEGIN: Left Aside Minimize Toggle -->
        <a (click)="clickLeftAsideHideToggle()" *ngIf="currentTheme.isLeftMenuUsed && currentTheme.baseSettings.menu.allowAsideHiding"
           class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block" href="javascript:"
           id="m_aside_left_hide_toggle">
            <span></span>
        </a>
        <a *ngIf="currentTheme.isLeftMenuUsed && currentTheme.baseSettings.menu.allowAsideMinimizing && !currentTheme.baseSettings.menu.allowAsideHiding"
           class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block"
           href="javascript:" id="m_aside_left_minimize_toggle"
           mMenuAsideToggle>
            <span></span>
        </a>
        <!-- END -->
        <!-- BEGIN: Responsive Aside Left Menu Toggler-->
        <a *ngIf="currentTheme.isLeftMenuUsed" class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block" href="javascript:"
           id="m_aside_left_offcanvas_toggle">
            <span></span>
        </a>
        <!-- END -->
        <!-- begin::Responsive Header Menu Toggler-->
        <a *ngIf="currentTheme.isTopMenuUsed || currentTheme.isTabMenuUsed" class="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block"
           href="javascript:"
           id="m_aside_header_menu_mobile_toggle">
            <span></span>
        </a>
        <!-- end::Responsive Header Menu Toggler-->
        <!-- BEGIN: Topbar Toggler -->
        <a (click)="clickTopbarToggle()" class="m-brand__icon m--visible-tablet-and-mobile-inline-block" href="javascript:"
           id="m_aside_header_topbar_mobile_toggle">
            <i [attr.aria-label]="l('Expand')" class="flaticon-more"></i>
        </a>
        <!-- BEGIN: Topbar Toggler -->
    </div>
</div>
<!-- END: Brand -->
