import { FeatureCheckerService, PermissionCheckerService, SettingService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService,
        private _featureCheckerService: FeatureCheckerService,
        private _settingService: SettingService
    ) {
    }

    getMenu(): AppMenu {
        let result: AppMenuItem[] = [];
        result.push(new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'));
        result.push(new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'));
        result.push(new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'));
        if (this._appSessionService.tenantId > 0) {
            let masterData: AppMenuItem[] = [];
            masterData.push(new AppMenuItem('Companies', 'Pages.Companies', 'flaticon-map', '/app/main/kpmg/companies'));
            masterData.push(new AppMenuItem('EconomicUnits', 'Pages.EconomicUnits', 'flaticon-share', '/app/main/kpmg/economicUnits'));
            masterData.push(new AppMenuItem('Lands', 'Pages.Lands', 'flaticon-placeholder-1', '/app/main/kpmg/lands'));
            masterData.push(new AppMenuItem('Buildings', 'Pages.Buildings', 'flaticon-home-2', '/app/main/kpmg/buildings'));
            masterData.push(new AppMenuItem('Rentees', 'Pages.Rentees', 'flaticon-users', '/app/main/kpmg/rentees'));
            masterData.push(new AppMenuItem('OperatingEquipments', 'Pages.OperatingEquipments', 'flaticon-settings-1', '/app/main/kpmg/operatingEquipments'),);
            masterData.push(new AppMenuItem('SubmissionFields', 'Pages.SubmissionFields', 'flaticon-notes', '/app/main/kpmg/submissionFields'));
            masterData.push(new AppMenuItem('MunicipalityExtraDataMenu', 'Pages.MunicipalityExtraData', 'flaticon-add', '/app/main/kpmg/municipalityExtraData'));

            result.push(new AppMenuItem('MasterData', '', 'flaticon-interface-8', '', masterData));
        }
        result.push(new AppMenuItem('Assessments', '', 'flaticon-open-box', '', [
            new AppMenuItem('GrundsteuerBewertungen', 'Pages.Calculations', 'flaticon-browser', '/app/main/kpmg/calculations'),
        ]));
        result.push(new AppMenuItem('DeclarationsAndNotes', '', 'flaticon-file-2', '', [
            new AppMenuItem('PropertyTaxValueDeclaration', 'Pages.PropertyTaxValueDeclaration', 'flaticon-notes', '#'),
            new AppMenuItem('RealEstateValueDeclaration', 'Pages.RealEstateValueDeclaration', 'flaticon-list', '#'),
            new AppMenuItem('Declarations', 'Pages.TaxDeclaration', 'flaticon-list', '/app/main/kpmg/declarations'),
            new AppMenuItem('ChangeDeclarationDuties', 'Pages.ChangeDeclarationDuty', 'flaticon-alert-1', '/app/main/kpmg/change-declaration-duty'),
            new AppMenuItem('ReportingDuties', 'Pages.ReportingDuty', 'flaticon-alert-2', '/app/main/kpmg/reporting-duty'),
            new AppMenuItem('OtherMessages', 'Pages.OtherMessage', 'flaticon-multimedia', '/app/main/kpmg/other-message'),
        ]));

        let notes = new AppMenuItem('Notes', 'Pages.Notes', '	flaticon-folder-1', '', [
            new AppMenuItem('NoteRateableValues', 'Pages.NoteRateableValues', '	flaticon-interface-10', '/app/main/kpmg/noteRateableValues')
        ]);
        if (abp.setting.getBoolean('App.HostManagement.IsOldNoteSectionVisible')) {
            notes.items.push(new AppMenuItem('NotePropertyTaxAssessments', 'Pages.NoteRateableValues', 'flaticon-file-2', '/app/main/kpmg/notePropertyTaxAssessments'));
        }
        notes.items.push(
            new AppMenuItem('NotePropertyTaxs', 'Pages.NotePropertyTaxs', 'flaticon-interface-4', '/app/main/kpmg/notePropertyTaxs'),
            new AppMenuItem('NoteBreakdowns', 'Pages.NoteBreakdowns', 'flaticon-interface-11', '/app/main/kpmg/comingSoon'),
            new AppMenuItem('PaymentManagement', 'Pages.PaymentManagements', 'flaticon-coins', '/app/main/kpmg/payment-management'),
            new AppMenuItem('Pages.PaymentReceipts', 'Pages.PaymentReceipts', 'flaticon-piggy-bank', '/app/main/kpmg/payment-receipt'),
            new AppMenuItem('LegalRemedy', 'Pages.LegalRemedy', 'flaticon-interface-3', '/app/main/kpmg/legal-remedy'));

        result.push(notes);

        let annexes: AppMenuItem[] = [];
        annexes.push(new AppMenuItem('Municipalities', 'Pages.Requests.Municipalities', 'flaticon-placeholder-1', '/app/main/kpmg/requestMunicipalities'));
        annexes.push(new AppMenuItem('Districts', 'Pages.Requests.Districts', 'flaticon-coins', '/app/main/kpmg/requestDistricts'));
        annexes.push(new AppMenuItem('CollectionRates', 'Pages.Requests.CollectionRates', 'flaticon-coins', '/app/main/kpmg/requestCollectionRates'));
        if (this._appSessionService.tenantId <= 0) {
            //annexes.push(new AppMenuItem('Banks', 'Pages.Banks', 'flaticon-piggy-bank', '/app/main/kpmg/banks'));
            annexes.push(new AppMenuItem('CompareMappingReference', 'Pages.CompareMappingReference', 'flaticon-map', '/app/main/kpmg/compareMappingReference'));
            annexes.push(new AppMenuItem('TaxOffices', 'Pages.TaxOffices', 'flaticon-placeholder-1', '/app/main/kpmg/taxOffices'));
            annexes.push(new AppMenuItem('Holidays', 'Pages.Holiday', 'flaticon-calendar', '/app/main/kpmg/holidays'));
            annexes.push(new AppMenuItem('Municipalities', 'Pages.Municipalities', '	flaticon-placeholder-1', '/app/main/kpmg/municipalities'));
            annexes.push(new AppMenuItem('Districts', 'Pages.District', 'flaticon-coins', '/app/main/kpmg/districts'));
            annexes.push(new AppMenuItem('CollectionRates', 'Pages.CollectionRates', 'flaticon-coins', '/app/main/kpmg/collectionRates'));
            annexes.push(new AppMenuItem('BuildingTypes', 'Pages.BuildingTypes', 'flaticon-coins', '/app/main/kpmg/buildingTypes'));
            annexes.push(new AppMenuItem('LandStateDevelopments', 'Pages.LandStateDevelopments', 'flaticon-coins', '/app/main/kpmg/landStateDevelopment'));
            annexes.push(new AppMenuItem('TaxExemptionDefinition', 'Pages.TaxExemptionDefinition', 'flaticon-coins', '/app/main/kpmg/taxExemptionDefinition'));
            annexes.push(new AppMenuItem('FullBenefitDefinition', 'Pages.FullBenefitDefinition', 'flaticon-coins', '/app/main/kpmg/fullBenefitDefinition'));
        }
        if (this._appSessionService.tenantId > 0) {
            annexes.push(new AppMenuItem('BuildingTypes', 'Pages.BuildingTypes', 'flaticon-coins', '/app/main/kpmg/buildingTypes'));
            annexes.push(new AppMenuItem('LandStateDevelopments', 'Pages.LandStateDevelopments', 'flaticon-coins', '/app/main/kpmg/landStateDevelopment'));
            annexes.push(new AppMenuItem('TaxExemptionDefinition', 'Pages.TaxExemptionDefinition', 'flaticon-coins', '/app/main/kpmg/taxExemptionDefinition'));
            annexes.push(new AppMenuItem('FullBenefitDefinition', 'Pages.FullBenefitDefinition', 'flaticon-coins', '/app/main/kpmg/fullBenefitDefinition'));
        }
        annexes.push(new AppMenuItem('Annex27s', 'Pages.Annexes.Annex27s', 'flaticon-interface-11', '/app/main/kpmg/annex27s'),
            new AppMenuItem('Annex28s', 'Pages.Annexes.Annex28s', 'flaticon-interface-11', '/app/main/kpmg/annex28s'),
            new AppMenuItem('Annex29s', 'Pages.Annexes.Annex29s', 'flaticon-interface-11', '/app/main/kpmg/annex29s'),
            new AppMenuItem('Annex30s', 'Pages.Annexes.Annex30s', 'flaticon-interface-11', '/app/main/kpmg/annex30s'),
            new AppMenuItem('Annex31s', 'Pages.Annexes.Annex31s', 'flaticon-interface-11', '/app/main/kpmg/annex31s'),
            new AppMenuItem('Annex32s', 'Pages.Annexes.Annex32s', 'flaticon-interface-11', '/app/main/kpmg/annex32s'),
            new AppMenuItem('Annex33s', 'Pages.Annexes.Annex33s', 'flaticon-interface-11', '/app/main/kpmg/annex33s'),
            new AppMenuItem('Annex36s', 'Pages.Annexes.Annex36s', 'flaticon-interface-11', '/app/main/kpmg/annex36s'),
            new AppMenuItem('Annex37s', 'Pages.Annexes.Annex37s', 'flaticon-interface-11', '/app/main/kpmg/annex37s'),
            new AppMenuItem('Annex38s', 'Pages.Annexes.Annex38s', 'flaticon-interface-11', '/app/main/kpmg/annex38s'),
            new AppMenuItem('Annex39I', 'Pages.Annexes.Annex39I', 'flaticon-interface-11', '/app/main/kpmg/annex39I'),
            new AppMenuItem('Annex39IIs', 'Pages.Annexes.Annex39IIs', 'flaticon-interface-11', '/app/main/kpmg/annex39IIs'),
            new AppMenuItem('Annex40s', 'Pages.Annexes.Annex40s', 'flaticon-interface-11', '/app/main/kpmg/annex40s'),
            new AppMenuItem('Annex41s', 'Pages.Annexes.Annex41s', 'flaticon-interface-11', '/app/main/kpmg/annex41s'),
            new AppMenuItem('Annex42s', 'Pages.Annexes.Annex42s', 'flaticon-interface-11', '/app/main/kpmg/annex42s'),
            new AppMenuItem('Annex43s', 'Pages.Annexes.Annex43s', 'flaticon-interface-11', '/app/main/kpmg/annex43s'),
            new AppMenuItem('AnnexPara256s', 'Pages.Annexes.AnnexPara256s', 'flaticon-interface-11', '/app/main/kpmg/annexPara256s'),
            new AppMenuItem('EquivalenceValues', 'Pages.Annexes.AnnexEquivalenceValues', 'flaticon-interface-11', '/app/main/kpmg/equivalenceValues'),
            new AppMenuItem('AssessmentRates', 'Pages.Annexes.AnnexAssessmentRates', 'flaticon-interface-11', '/app/main/kpmg/annexAssessmentRates'),
            new AppMenuItem('AnnexBuildingCosts', 'Pages.Annexes.AnnexBuildingCosts', 'flaticon-interface-11', '/app/main/kpmg/annexBuildingCosts'),
            new AppMenuItem('ResidentialAreaHamburg', 'Pages.Annexes.AnnexResidentialAreaHamburg', 'flaticon-interface-11', '/app/main/kpmg/annexResidentialAreaHamburg'),
            new AppMenuItem('PlausibilityCheckRenovatedArea', 'Pages.Annexes.AnnexPlausibilityCheckRenovatedArea', 'flaticon-interface-11', '/app/main/kpmg/annexPlausibilityCheckRenovatedAreas'),
            new AppMenuItem('PlausibilityCheckGrossFloorArea', 'Pages.Annexes.AnnexPlausibilityCheckGrossFloorArea', 'flaticon-interface-11', '/app/main/kpmg/annexPlausibilityCheckGrossFloorAreas'));
        result.push(new AppMenuItem('Administration', 'Pages.Administration', 'flaticon-settings', '', [
            new AppMenuItem('AppMenuTables', '', 'flaticon-tabs', '', annexes),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
            new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
            new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
            new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
            // new AppMenuItem('CountUnits', 'Pages.Administration.Host.InvoiceReports', 'flaticon-coins', '/app/admin/hostReports'),
            new AppMenuItem('CountUnits', 'Pages.Administration.Tenant.InvoiceReports', 'flaticon-coins', '/app/main/kpmg/tenantReports'),
            new AppMenuItem(
                'Archives',
                'Pages.Administration.Archives',
                'flaticon-interface-3',
                '/app/admin/archives',
                undefined,
                undefined,
                undefined,
                () => {
                    return (
                        this._appSessionService.tenantId === null || (this._appSessionService.tenantId != null &&
                            this._featureCheckerService.isEnabled(
                                'App.Auditing'
                            ))
                    );
                }
            ),
            new AppMenuItem('AuditLogs',
                'Pages.Administration.AuditLogs',
                'flaticon-folder-1',
                '/app/admin/auditLogs',
                undefined,
                undefined,
                undefined,
                () => {
                    return (
                        this._appSessionService.tenantId === null || (this._appSessionService.tenantId != null &&
                            this._featureCheckerService.isEnabled(
                                'App.Auditing'
                            ))
                    );
                }
            ),
            new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
            //  new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
            new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
            new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
            new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings'),
            new AppMenuItem(
                'Database Management',
                'Pages.DatabaseManagement',
                'flaticon-add',
                '/app/admin/database-management'
            ),
            new AppMenuItem(
                'EFMigration',
                'Pages.Pages_EFMigrationOverview', //Pages.Pages_EFMigrationOverview
                'flaticon-refresh',
                '/app/admin/ef-migration'
            ),
            new AppMenuItem(
                'BackgroundTasksManagement',
                'Pages.HostBackgroundJobInfos',
                'flaticon-background',
                '/app/admin/hostBackgroundJobInfos'
            ),
            new AppMenuItem(
                'BackgroundTasksManagement',
                'Pages.TenantBackgroundJobInfos',
                'flaticon-background',
                '/app/admin/tenantBackgroundJobInfos'
            ),
            new AppMenuItem(
                'GlobalMessages',
                'Pages.GlobalMessages', //Pages.GlobalMessages
                'flaticon-alarm',
                '/app/admin/messages'
            ),
            new AppMenuItem(
                'IndividualDatas',
                'Pages.IndividualData', //Pages.IndividualDatas
                'flaticon-refresh',
                '/app/admin/individualData'
            ),
            new AppMenuItem(
                'KnowledgeArticles',
                'Pages.KnowledgeArticles',
                'flaticon-list',
                '/app/admin/articles'
            ),
            new AppMenuItem(
                'KnowledgeBox',
                'Pages.Knowledgebox',
                'fa fa-graduation-cap',
                '/app/admin/knowledgebox'
            ),
            new AppMenuItem(
                'Filling_Guide',
                'Pages.Filling_Guide',
                'fa fa-graduation-cap',
                '/app/admin/filling-guide'
            ),
            new AppMenuItem('ImportTemplate', 'Pages.ImportTemplate', 'flaticon-interface-11', '/app/main/kpmg/ImportTemplate'),
            new AppMenuItem('PDFTemplate', 'Pages.PDFTemplate', 'flaticon-interface-11', '/app/main/kpmg/PdfTemplate'),
            new AppMenuItem('Duedate', 'Pages.DueDate', 'flaticon-event-calendar-symbol', '/app/main/kpmg/dueDate'),
            new AppMenuItem('Duedate', 'Pages.Requests.DueDates', 'flaticon-event-calendar-symbol', '/app/main/kpmg/requestDueDate'),
            new AppMenuItem('DueDateCalculation', 'Pages.DueDate', 'la la-calculator', '/app/admin/dueDateCalculation'),
            new AppMenuItem('CalculationReports', 'Pages.CalculationReports', 'la la-calculator', '/app/admin/calculationReports'),
            new AppMenuItem('MassXmlCreation', 'Pages.MassXmlCreation', 'flaticon-background', '/app/admin/massXmlCreation',
                undefined,
                undefined,
                undefined,
                () => { return (abp.custom.runningEnvironment <= 2); }),
            new AppMenuItem('Synchronization', 'Pages.Synchronization', 'flaticon-background', '/app/admin/syncjobs'),
            new AppMenuItem('ObjectionTemplates', 'Pages.ObjectionTemplates', 'flaticon-event-calendar-symbol', '/app/main/kpmg/objectionTemplates'),
        ]),);

        return new AppMenu('MainMenu', 'MainMenu', result);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0;i < menuItem.items.length;i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null || subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {

        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
