import {IThemeAssetContributor} from '../ThemeAssetContributor';

export class Theme5ThemeAssetContributor implements IThemeAssetContributor {
    getAssetUrls(): string[] {
        return [
            '/assets/fonts/fonts-asap-condensed.css',
            '/assets/metronic/assets/demo/default/base/style.bundle.css',
            '/assets/common/styles/themes/theme5/kpmg-style.css',
            '/assets/common/styles/themes/theme5/kpmg-style.custom.css'
        ];
    }
}
