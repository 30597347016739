import {Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AttachmentFileUploader} from '@app/shared/common/file-upload/attachmentFileUploader';
import {AppComponentBase} from '@shared/common/app-component-base';
import {AttachmentDto, StorageServiceServiceProxy} from '@shared/service-proxies/service-proxies';
import {FileDownloadService} from '@shared/utils/file-download.service';
import {FileItem} from 'ng2-file-upload';
import {UploadFileAttachment} from '@shared/utils/classes/UploadFileAttachment';

@Component({
    selector: 'file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent extends AppComponentBase implements OnInit {

    // single file
    @Input()
    file: UploadFileAttachment;
    @Output()
    fileChange = new EventEmitter<UploadFileAttachment>();
    @Output()
    onClearFile = new EventEmitter<any>();
    @Input()
    viewOnly: Boolean;
    // multiple files
    @Input()
    multiple: Boolean = false;
    @Input()
    files: UploadFileAttachment[] = [];
    @Output()
    filesChange = new EventEmitter<UploadFileAttachment[]>();
    @Input()
    mimetypes: string[] = abp.custom.attachmentMimeTypes;
    @Input()
    required = false;
    @Input()
    light = false;

    @ViewChild('fileInput') fileInput: ElementRef;

    uploader: AttachmentFileUploader;

    constructor(
        injector: Injector, 
        private fileDownloadService: FileDownloadService
    ) {
        super(injector);
    }

    ngOnInit() {
        this.uploader = new AttachmentFileUploader();

        this.uploader.onAfterAddingFile = (file: FileItem) => {
            if (this.multiple) {
                return file;
            }
            // file is replaced
            if (this.file) {
                this.file.isDeleted = true;
                this.file.storageFileId = undefined;
            } else {
                this.file = new UploadFileAttachment();
            }
            if (file) {
                this.file.name = file.file.name;
                this.file.uploadedFile = file._file;
                this.fileChange.emit(this.file);
            }

        };

        this.uploader.onAfterAddingAll = (files: FileItem[]) => {
            if (!this.multiple) {
                return files;
            }
            if (files) {
                //this.files = this.files.filter(f => f.id !== 0);
                files.every((f, index, array) => {
                    let attachment = new UploadFileAttachment();
                    attachment.name = f.file.name;
                    attachment.fileType = f.file.type;
                    attachment.id = 0;
                    attachment.isDeleted = false;
                    attachment.uploadedFile = f._file;
                    this.files.push(attachment);
                    return true;
                });
                this.filesChange.emit(this.files);
            }

        };
    }

    getFileLabel() {

        // if no file is set and no file uploaded return chosse file label
        if (this.file && this.file.isDeleted && this.uploader.queue.length === 0) {
            return this.l('ChooseFile');
        }
        // if file was added to form, return filename from inputfile
        if (this.uploader.queue.length > 0) {
            return this.uploader.queue[0].file.name;
        }
        // if file is a persistent attachement show file name from persistend attachement
        if (this.file && (this.file.storageFileId || this.file.binaryObjectId) && !this.file.isDeleted) {
            return this.file.name;
        }

        return this.l('ChooseFile');
    }

    filesReadyForUpload() {
        return this.uploader.queue.length > 0;
    }

    clear() {
        if (this.file) {
            this.file.isDeleted = true;
            this.fileChange.emit(this.file);
        }

        this.files = this.files.filter(f => f.id !== 0);
        this.filesChange.emit(this.files);
        this.uploader.clearQueue();
        // hack to re-enable native input element (otherwise after clear no new file/s could be selected)
        this.fileInput.nativeElement.value = '';
        this.onClearFile.emit();
    }

    deleteAttachment(index: number) {
        if (this.viewOnly) return;
        let file = this.files[index];
        file.isDeleted = true;
        // remove file from array if it has id === 0 (new file), existing files only set deleted flag
        if(file.id === 0){
            const index = this.files.indexOf(file, 0);
            if (index > -1) {
                this.files.splice(index, 1);
            }
            this.filesChange.emit(this.files);
        }

        // if the current file array is empty, clear the file input also
        if(this.files.length === 0){
            this.clear();
        }
    }

    downloadAttachment(attachment: AttachmentDto) {


        if (attachment.storageFileId) {
            if (attachment.folderName) {
                this.fileDownloadService.downloadStorageFileBasedOnFolderName(attachment.storageFileId, attachment.folderName, !attachment.tenantId, attachment.name);
            } else {
                this.fileDownloadService.downloadStorageFile(attachment.storageFileId, 0, !attachment.tenantId);
            }

        }
        if (attachment.binaryObjectId) {
            this.fileDownloadService.downloadBinaryObject(attachment.binaryObjectId, attachment.name);
        }

    }
}
