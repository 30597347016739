import {Component, EventEmitter, Injector, Output, ViewEncapsulation} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {EconomicUnitLookupTableDto, LookupServiceProxy} from '@shared/service-proxies/service-proxies';
import {AppComponentBase} from '@shared/common/app-component-base';
import {TableUtilitiesService} from '@shared/utils/table-utils-service';
import {
    EconomicUnitLookupTemplateComponent
} from "@app/main/kpmg/lookups/economicUnit-lookup-table-modal/economic-unit-lookup-template/economic-unit-lookup-template.component";
import moment from "moment";

@Component({
    selector: 'economicUnitLookupTableModal',
    styleUrls: ['./economicUnit-lookup-table-modal.component.less'],
    encapsulation: ViewEncapsulation.None,
    templateUrl: './economicUnit-lookup-table-modal.component.html'
})
export class EconomicUnitLookupTableModalComponent extends AppComponentBase {
    @Output() modalSave: EventEmitter<EconomicUnitLookupTableDto> = new EventEmitter<EconomicUnitLookupTableDto>();
    @Output() onViewItem: EventEmitter<any> = new EventEmitter<any>();

    modalRef: BsModalRef<EconomicUnitLookupTemplateComponent>;

    //hasDiscounts: boolean = false;

    constructor(
        injector: Injector,
        private _lookupsServiceProxy: LookupServiceProxy,
        private _tableUtilitiesService: TableUtilitiesService,
        private _bsModalService: BsModalService
    ) {
        super(injector);
    }

    show(canViewItem: boolean, id: number, displayName: string, dueDate: moment.Moment, isEconomicUnitForBuilding: boolean = false, isEconomicUnitForDeclaration: boolean = false): void {
        this.modalRef = this._bsModalService.show(EconomicUnitLookupTemplateComponent, {
            class: 'modal-lg',
            backdrop: 'static'
        })
        this.modalRef.content.modalSave.subscribe(s => {
            this.modalSave.emit(s);
            this.close();
        });
        this.modalRef.content.onClose.subscribe(s => this.close());
        this.modalRef.content.onViewItem.subscribe(this.onViewItem);
        this.modalRef.content.active = true;
        this.modalRef.content.dueDate = dueDate;
        this.modalRef.content.isEconomicUnitForBuilding = isEconomicUnitForBuilding;
        this.modalRef.content.isEconomicUnitForDeclaration = isEconomicUnitForDeclaration;
        this.modalRef.content.canViewItem = canViewItem;
        this.modalRef.content.id = id;
        this.modalRef.content.displayName = displayName;
    }

    close(): void {
        this.modalRef.content.active = false;
        this.modalRef.hide();
        this.modalRef = null;
    }
}
