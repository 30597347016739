import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {ChangeLogServiceProxy} from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'ChangeLog',
    templateUrl: './change-log.component.html',
    styleUrls: ['./change-log.component.css']
})
export class ChangeLogComponent implements OnInit {
    @ViewChild('createOrEditModal') modal: ModalDirective;

    // changeLogView: GetChangeLogForViewDto = new GetChangeLogForViewDto();
    // changeLog: ChangeLogDto = new ChangeLogDto();
    changeLog: any;

    constructor(private _changeLogServiceProxy: ChangeLogServiceProxy) {
    }

    ngOnInit() {
    }

    show() {
        this._changeLogServiceProxy.getChangeLogForView().subscribe(result => {
            this.changeLog = result.changeLog;
            if (result !== undefined) {
                this.modal.show();
            }
        });
    }

    close(): void {

        this.modal.hide();
    }
}
