import moment from 'moment-timezone';

export class MomentHelper {

    static removeTimeZone(date: moment.Moment): moment.Moment {
        if (!date) {
            return;
        }
        return moment.utc([date.year(), date.month(), date.date()]);
    }
}
