<div #mySettingsModal="bs-modal" [config]="{backdrop: 'static'}" aria-hidden="true" aria-labelledby="myLargeModalLabel" bsModal
     class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #mySettingsModalForm="ngForm" (ngSubmit)="save()" *ngIf="active">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"MySettings" | localize}}</span>
                    </h5>
                    <button (click)="close()" [attr.aria-label]="l('Close')" class="close" type="button">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!-- *ngIf="user">-->
                    <tabset>
                        <tab heading="{{'Profile' | localize}}">
                            <div class="form-group form-md-line-input row">
                                <label class="col-3 col-form-label" for="DownloadType">{{"downloadType" | localize}}
                                    *</label>
                                <div class="col" style="padding-top:10px">
                                    <p-dropdown [(ngModel)]="downloadType" [options]="downloadTypes"
                                                [style]="{'minWidth':'100%'}" name="downloadtype" optionLabel="text"
                                                optionValue="value"></p-dropdown>
                                </div>
                            </div>
                            <div class="form-group form-md-line-input row">
                                <label class="col-3 col-form-label" for="Timezone">{{"Timezone" | localize}}
                                    *</label>
                                <div class="col" style="padding-top:10px">
                                    <p-dropdown [(ngModel)]="timezone" [options]="timezones"
                                                [style]="{'minWidth':'100%'}" name="timezone" optionLabel="text"
                                                optionValue="value"></p-dropdown>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-3 col-form-label"
                                    for="OnDueDate">{{l("OnTheDueDate")}}&nbsp;*
                                </label>
                                <div class="col-lg-3">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon">
                                            <small>01.01.</small>
                                        </span>
                                        <p-calendar
                                            (ngModelChange)="this.dueDate = getMoment($event);"
                                            [ngModel]="this.dueDate | m2d" id="OnDueDate"
                                            view="year" dateFormat="yy" inputStyleClass="form-control w-full"
                                            name="OnDueDate" required useUtc>
                                        </p-calendar>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab heading="{{'MailNotifications' | localize}}">
                            <div class="m-checkbox-list" style="margin-left: 50px;">
                                <div class="form-group form-md-line-input row">
                                    <h5 style="font-weight: bold; color: dimgrey;">{{"DeclarationsNotifications" | localize}}</h5>
                                </div>
                                <div class="form-group form-md-line-input row">
                                    <label class="m-checkbox" for="Status_Change_Notification">
                                        <input [(ngModel)]="statusChangeNotification" class="m-check"
                                            id="Status_Change_Notification"
                                            name="StatusChangeNotification"
                                            type="checkbox">
                                        {{"StatusChangeNotification" | localize}}
                                        <span></span>
                                    </label>
                                </div>
                                <div class="form-group form-md-line-input row">
                                    <label class="m-checkbox" for="New_Declaration_Notification">
                                        <input [(ngModel)]="newDeclarationNotification" class="m-check"
                                            id="New_Declaration_Notification"
                                            name="NewDeclarationNotification"
                                            type="checkbox">
                                        {{"NewDeclarationNotification" | localize}}
                                        <span></span>
                                    </label>
                                </div>    
                                <div class="form-group form-md-line-input row">
                                    <h5 style="font-weight: bold; color: dimgrey;">{{"DeadlinesNotifications" | localize}}</h5>
                                </div>
                                <div class="form-group form-md-line-input row">
                                    <label class="m-checkbox" for="Deadline_Notification">
                                        <input [(ngModel)]="deadlineNotification" class="m-check"
                                            id="Deadline_Notification"
                                            name="DeadlineNotification"
                                            type="checkbox">
                                        {{"DeadlineNotification" | localize}}
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        </tab> 
                    </tabset>
                </div>
                <div class="modal-footer">
                    <button (click)="close()" [disabled]="saving" class="btn btn-secondary" type="button">{{"Cancel" |
                        localize}}</button>
                    <button [disabled]="!mySettingsModalForm.form.valid || saving" class="btn btn-primary"
                            type="submit"><i class="fa fa-save"></i>
                        <span>{{"Save"
                            | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
