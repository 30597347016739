<div [ngClass]="{'subscription-bar-visible': subscriptionStatusBarVisible()}"
     class="m-grid m-grid--hor m-grid--root m-page">
    <default-layout *ngIf="theme=='default'"></default-layout>
    <theme5-layout *ngIf="theme=='theme5'"></theme5-layout>
</div>

<loginAttemptsModal #loginAttemptsModal></loginAttemptsModal>
<changePasswordModal #changePasswordModal></changePasswordModal>

<mySettingsModal #mySettingsModal (modalSave)="onMySettingsModalSaved()"></mySettingsModal>
<notificationSettingsModal #notificationSettingsModal></notificationSettingsModal>

<ChangeLog #changeLog></ChangeLog>
<Disclaimer #disclaimer></Disclaimer>
<VersionInfo #versionInfo></VersionInfo>
<OpenSourceComponents #openSourceComponents></OpenSourceComponents>
<supportMailModal #supportMailModal></supportMailModal>
<session-timeout #sessionTimeout [declarationTimeOut]="DeclarationTimeOutMinutes" *ngIf="IsSessionTimeOutEnabled"></session-timeout>

