<div class="modal-header">
    <h4 class="modal-title">
        <span>{{ l("PickEconomicUnit") }}</span>
    </h4>
    <button (click)="onClose.emit()" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<kpmg-lookup-table #dt (onClose)="onClose.emit()" (onGetSelectedItems)="setAndSave($event)" (onLazyLoad)="getAll($event)"
                   (onViewItem)="onViewItem.emit($event)"
                   *ngIf="active"
                   [canViewItem]="canViewItem"
                   [tableConfigType]="tableConfigType"
                   [totalRecords]="totalRecords"
                   [value]="economicUnits"
                   selectionMode="single">
</kpmg-lookup-table>
