import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable, SecurityContext} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Base64Helper} from './Base64Helper';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable()
export class ModelUploadHelper {

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
    }

    public sendModel(url: string, model: object, fd: FormData = null): Observable<any> {
        const formData: FormData = fd || new FormData();
        this.sanitize(model);
        const file = new Blob([Base64Helper.b64EncodeUnicode(JSON.stringify(model))]);
        formData.append('model', file, 'model.txt');
        //let httpClient = InjectorInstance.get<HttpClient>(HttpClient);
        return this.http.post<any>(url, formData).pipe(catchError(err => {
            if(err instanceof HttpErrorResponse && err.status === 500){
                abp.message.error(err.error.error.details, err.error.error.message, {html: true});
            }
            return throwError(() => err);
        }));
    }

    public sanitize(model: object): void {
        if(!!model){
            return;
        }
        if(!!model['_visited'] && model['_visited'] === true){
            return;
        }
        model['_visited'] = true;
        Object.keys(model).forEach(key => {
            let val = model[key];
            try {
                if (_.isString(val)) {
                    model[key] = val.replace(/\bon\w+=\S+(?=.*>)/g, '');
                } else if (this.isObject(val) && !this.isValidDate(val)) {
                    this.sanitize(val);
                } else if (Array.isArray(val)) {
                    for (let valElement of val) {
                        this.sanitize(valElement);
                    }
                }
            } catch
                (e) {
                console.warn(e);
            }
        });
    }

    private isObject(model: any): boolean {
        return typeof model === 'object' &&
            !Array.isArray(model) &&
            model !== null;
    }

    private isValidDate(date: any) {
        return !!date && ((Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date)) || moment.isMoment(date));
    }
}
