import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

const LAYOUT_5_MENU_TOGGLER_KEY = 'layout5menuTogglerValue';

@Injectable()
export class Layout5Service {
    private menuTogglerSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {
        this.loadFromLocalStorage();
    }

    public loadFromLocalStorage() {
        const valueFromLocalStorage = localStorage.getItem(LAYOUT_5_MENU_TOGGLER_KEY);
        if (valueFromLocalStorage && valueFromLocalStorage === 'true') {
            this.menuTogglerSubj.next(true);
            return;
        }

        this.menuTogglerSubj.next(false);
    }

    setToggleMenuValue(_value: boolean) {
        this.menuTogglerSubj.next(_value);
        localStorage.setItem(LAYOUT_5_MENU_TOGGLER_KEY, _value.toString());
    }

    getToggleMenuValue(): boolean {
        return this.menuTogglerSubj.value;
    }
}
