<a [href]="legalPrivacyInfo.legalLink" class="m-link m-link--primary label_text_primary"
   target="_blank">{{"LegalInformation" | localize}}</a>
<span class="pipe-divider"> | </span>
<a [href]="legalPrivacyInfo.privacyLink" class="m-link m-link--primary label_text_primary"
   target="_blank">{{"Privacy" | localize}}</a>
<span class="pipe-divider"> | </span>
<a (click)="showDisclaimer()" class="m-link m-link--primary label_text_primary"
   href="javascript:">{{"LegalDisclaimer" | localize}}</a>

<span *ngIf="showLoginGuideline">
    <span class="pipe-divider"> | </span>
    <a (click)="downloadLoginFile()" class="m-link m-link--primary label_text_primary"
       href="javascript:">{{"LoginGuideLineLable" | localize}}</a>
</span>
<span class="pipe-divider"> | </span>
<a (click)="showVersionInfo()" class="m-link m-link--primary label_text_primary"
   href="javascript:">v{{ versionInfo.applicationVersion }}</a>
