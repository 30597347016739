import {AbpModule} from 'abp-ng2-module';
import * as ngCommon from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, Injector, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {LoginAttemptsModalComponent} from '@app/shared/layout/login-attempts-modal.component';
import {ChangePasswordModalComponent} from '@app/shared/layout/profile/change-password-modal.component';
import {MySettingsModalComponent} from '@app/shared/layout/profile/my-settings-modal.component';
import {CoreModule} from '@metronic/app/core/core.module';
import {LayoutConfigService} from '@metronic/app/core/services/layout-config.service';
import {LayoutRefService} from '@metronic/app/core/services/layout/layout-ref.service';
import {UtilsService} from '@metronic/app/core/services/utils.service';
import {
    ERiCEnumServiceProxy,
    FillingGuideServiceProxy,
    SettingsServiceProxy
} from '@shared/service-proxies/service-proxies';
import {ServiceProxyModule} from '@shared/service-proxies/service-proxy.module';
import {UtilsModule} from '@shared/utils/utils.module';
import {FileUploadModule} from 'ng2-file-upload';
import {FileUploadModule as PrimeNgFileUploadModule} from 'primeng/fileupload';
import {TableModule} from 'primeng/table';
import {ImpersonationService} from './admin/users/impersonation.service';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppCommonModule} from './shared/common/app-common.module';
import {ChangeLogComponent} from './shared/layout/changelog/change-log.component';
import {DisclaimerComponent} from './shared/layout/Disclaimer/disclaimer.component';
import {FooterComponent} from './shared/layout/footer.component';
import {SideBarMenuComponent} from './shared/layout/nav/side-bar-menu.component';
import {TopBarMenuComponent} from './shared/layout/nav/top-bar-menu.component';
import {HeaderNotificationsComponent} from './shared/layout/notifications/header-notifications.component';
import {NotificationSettingsModalComponent} from './shared/layout/notifications/notification-settings-modal.component';
import {NotificationsComponent} from './shared/layout/notifications/notifications.component';
import {UserNotificationHelper} from './shared/layout/notifications/UserNotificationHelper';
import {SupportMailComponent} from './shared/layout/support-mail/support-mail.component';
import {DefaultBrandComponent} from './shared/layout/themes/default/default-brand.component';
import {DefaultLayoutComponent} from './shared/layout/themes/default/default-layout.component';
import {Theme5BrandComponent} from './shared/layout/themes/theme5/theme5-brand.component';
import {Theme5LayoutComponent} from './shared/layout/themes/theme5/theme5-layout.component';
import {Layout5Service} from './shared/layout/themes/theme5/theme5.service';
import {TopBarComponent} from './shared/layout/topbar.component';
import {VersionInfoComponent} from './shared/layout/VersionInfo/versionInfo.component';
import {ModalModule} from "ngx-bootstrap/modal";
import {TabsModule} from "ngx-bootstrap/tabs";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {PopoverModule} from "ngx-bootstrap/popover";
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PaginatorModule} from "primeng/paginator";
import {ProgressBarModule} from "primeng/progressbar";
import {ChipsModule} from "primeng/chips";
import {TabViewModule} from "primeng/tabview";
import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ModelUploadHelper} from '@shared/helpers/ModelUploadHelper';
import {GlobalErrorHandler} from '@shared/common/GlobalErrorHandler';
import {ConfirmationService, MessageService} from 'primeng/api';
import {BadgeModule} from 'primeng/badge';
import {CalendarModule} from "primeng/calendar";
import {DividerModule} from 'primeng/divider';
import {StepsModule} from 'primeng/steps';
import {SessionTimeoutComponent} from "@app/shared/common/session-timeout/session-timeout.component";
import {SessionTimeoutModalComponent} from "@app/shared/common/session-timeout/session-timeout-modal-component";
import {HttpErrorInterceptor} from "@shared/common/HttpErrorInterceptor";

export let InjectorInstance: Injector;

@NgModule({
    declarations: [
        AppComponent,
        DefaultLayoutComponent,
        HeaderNotificationsComponent,
        SideBarMenuComponent,
        TopBarMenuComponent,
        FooterComponent,
        LoginAttemptsModalComponent,
        ChangePasswordModalComponent,
        MySettingsModalComponent,
        NotificationsComponent,
        NotificationSettingsModalComponent,
        TopBarComponent,
        DefaultBrandComponent,
        Theme5BrandComponent,
        Theme5LayoutComponent,
        ChangeLogComponent,
        DisclaimerComponent,
        VersionInfoComponent,
        SupportMailComponent,
        SessionTimeoutComponent,
        SessionTimeoutModalComponent
    ],
    imports: [
        DropdownModule,
        ngCommon.CommonModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        FileUploadModule,
        AbpModule,
        AppRoutingModule,
        UtilsModule,
        AppCommonModule.forRoot(),
        ServiceProxyModule,
        TableModule,
        PaginatorModule,
        PrimeNgFileUploadModule,
        ProgressBarModule,
        CoreModule,
        ChipsModule,
        TabViewModule,
        InputSwitchModule,
        BadgeModule,
        CalendarModule,
        DividerModule,
        StepsModule
    ],
    providers: [
        ERiCEnumServiceProxy,
        Layout5Service,
        ImpersonationService,
        UserNotificationHelper,
        MessageService,
        ConfirmationService,
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        LayoutConfigService,
        UtilsService,
        LayoutRefService,
        ModelUploadHelper,
        SettingsServiceProxy,
        FillingGuideServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
    ], exports: [
        ChangeLogComponent,
        DisclaimerComponent,
        VersionInfoComponent
    ]
})
export class AppModule {
    constructor(private injector: Injector) {
        InjectorInstance = this.injector;
    }
}
