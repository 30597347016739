<a class="m-nav__link m-dropdown__toggle" href="javascript:" id="m_topbar_notification_icon_not">
    <span [ngClass]="{'m-badge--danger':unreadNotificationCount}"
          class="m-nav__link-badge m-badge m-badge--dot m-badge--dot-small"></span>
    <span class="m-nav__link-icon">
        <span class="m-nav__link-icon-wrapper ">
            <!-- <i class="flaticon-alarm" [attr.aria-label]="l('Notifications')"></i> -->
            <img [src]=" appBaseHref() + 'assets/common/images/custom/notification_icon_blue.png'" alt="Notification"
                 class="icon-top-bar"
                 width="17px">
        </span>
    </span>
</a>
<div class="m-dropdown__wrapper" style="width: 360px !important; right: -8px !important; left: auto !important;">
    <span class="m-dropdown__arrow m-dropdown__arrow--right"></span>
    <div class="m-dropdown__inner">
        <div [ngStyle]="{ 'background': 'url(' + appRootUrl() + 'assets/metronic/assets/demo/' + currentTheme.baseSettings.layout.themeColor + '/media/img/misc/notification_bg.jpg)', 'background-size': 'cover'}"
             class="m-dropdown__header m--align-center">
            <span class="m-dropdown__header-title">
                {{unreadNotificationCount}} {{"NewNotifications" | localize}}
            </span>
        </div>
        <div class="m-dropdown__body">
            <div class="m-dropdown__content">
                <div class="row">
                    <div class="text-left col-md-8">
                        <a (click)="setAllNotificationsAsRead(); $event.preventDefault(); $event.stopPropagation();"
                           *ngIf="unreadNotificationCount"
                           href="javascript:">{{"SetAllAsRead" | localize}}</a>
                    </div>
                    <div class="text-right col-md-4">
                        <a (click)="openNotificationSettingsModal()" class="text-right"
                           href="javascript:">{{"Settings" | localize}}</a>
                    </div>
                </div>
                <hr/>
                <div>
                    <div class="m-list-timeline m-list-timeline--skin-light m-nav">
                        <div [ngClass]="{'empty':!notifications.length}" class="m-list-timeline__items">
                            <div (click)="gotoUrl(notification.url)"
                                 *ngFor="let notification of notifications"
                                 [ngClass]="{'user-notification-item-clickable': notification.url,'user-notification-item-unread': notification.isUnread}"
                                 class="m-list-timeline__item user-notification-item">
                                <span class="m-list-timeline__badge m-list-timeline__badge--success"></span>
                                <span class="m-list-timeline__icon {{notification.icon}}"></span>
                                <span class="m-list-timeline__text">
                                    {{notification.text}}
                                </span>
                                <span class="m-list-timeline__time">
                                    {{notification.time | momentFromNow}}
                                    <span *ngIf="notification.isUnread">
                                        <a (click)="setNotificationAsRead(notification); $event.preventDefault(); $event.stopPropagation();" *ngIf="notification.state=='UNREAD'"
                                           class="m-link set-notification-as-read"
                                           href="javascript:">{{"SetAsRead" | localize}}</a>
                                    </span>
                                </span>
                            </div>
                            <span *ngIf="!notifications.length" class="notification-empty-text">
                                {{"ThereIsNoNotification" | localize}}
                            </span>
                        </div>
                        <div *ngIf="notifications.length" class="m-nav__separator m-nav__separator--fit"></div>
                        <div *ngIf="notifications.length" class="external notifications-footer">
                            <div class="text-center"><a class="m-dropdown__toggle"
                                                        routerLink="notifications">{{"SeeAllNotifications" | localize}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
