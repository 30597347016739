<!-- BEGIN: messagebar -->
<div *ngIf="alerts && alerts.length" class="message-container">
    <ng-container *ngIf="!styleMessages && !showGridValue">
        <div *ngFor="let alert of alerts" class="global-messages gmessages">
            <alert #alertPanel (onClosed)="onClosed(alert)" [dismissible]="alert.dismissible" [type]="alert.type"
                   class="alert-panel">
                <h4 class="alert-heading"><strong>{{alert.title}}</strong></h4>
                <!-- <span class="alert-content" [innerHTML]="truncateHTML(alert.msg, 780)" ></span> -->
                <!-- <span [innerHTML]="alert.msg | replace : 'href=' : 'link' | safe: 'html'"></span> -->
                <span [innerHTML]="alert.msg | safe: 'html'"></span>
            </alert>
        </div>
    </ng-container>
    <ng-container *ngIf="showGridValue">
        <div *ngFor="let alert of alerts" class="global-messages">
            <alert #alertPanel (onClosed)="onClosed(alert)" [dismissible]="alert.dismissible" [type]="alert.type"
                   class="alert-panel">
                <h4 class="alert-heading"><strong>{{alert.title}}</strong></h4>
                <span [innerHTML]="truncateHTML(alert.msg, 190) | safe: 'html'"
                      class="alert-content"></span>
                <span (click)="this.classOverlay = alert.type; messageBarPannel.toggle($event)" class="see-more">See More</span>

            </alert>
            <div #attachHere class="attach"></div>
            <p-overlayPanel #messageBarPannel
                            [appendTo]="attachHere"
                            hideTransitionOptions="0s linear"
                            showCloseIcon="true"
                            showTransitionOptions="0s linear"
                            styleClass="alert-{{this.classOverlay}}">
                <ng-template pTemplate>
                    <!-- <div [innerHTML]="alert.msg | replace : 'href=' : 'link'"></div> -->
                    <div [innerHTML]="alert.msg | safe: 'html'"></div>
                </ng-template>
            </p-overlayPanel>

        </div>
    </ng-container>
    <ng-container *ngIf="styleMessages && !showGridValue">
        <p-carousel #carousel *ngIf="carouselFlag" [autoplayInterval]="10000" [circular]="true"
                    [numScroll]="1" [numVisible]="numVisible" [responsiveOptions]="responsiveOptions" [value]="alerts"
                    styleClass="custom-carousel">
            <ng-template #parentElem class="" let-product pTemplate="item">
                <div class="remove-container"><i (click)="closeCarousel()" class="fa fa-times"></i></div>
                <div (mouseenter)="stopCarousel()" (mouseleave)="startCarousel()" [ngClass]="setBackground(product)"
                     class="product-item">
                    <div class="product-item-content">
                        <div>
                            <h4 class="p-mb-1 title">{{product.title}}</h4>
                            <div [innerHTML]="product.msg | safe: 'html'"></div>
                            <!-- <h6 class="p-mt-0 p-mb-3 content" [innerHTML]="product.msg | replace : 'href=' : 'link' | safe: 'html'"></h6>-->
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-carousel>
    </ng-container>
</div>
<!-- END: messagebar -->
