import {Component, Injector, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {AppComponentBase} from '@shared/common/app-component-base';
import moment from 'moment-timezone';
import {
    _definitions_KPMGFileType,
    Backend3PartyLicenseDto,
    Backend3PartyLicenseServiceProxy,
    DownloadType,
    HostSettingsServiceProxy,
    KPMGAppAttachmentsAppServiceProxy,
    SubmitType
} from '@shared/service-proxies/service-proxies';

import {FileDownloadService} from '@shared/utils/file-download.service';
import {HttpClient} from "@angular/common/http";
import {Router} from '@angular/router';

@Component({
    selector: 'OpenSourceComponents',
    templateUrl: './open-source-components.component.html',
    styleUrls: ['./open-source-components.component.css']
})
export class OpenSourceComponentsComponent extends AppComponentBase {
    @ViewChild('createOrEditModal') modal: ModalDirective;
    backend3rdParties: Backend3PartyLicenseDto[] = []
    //Install: npm install generate-license-file -g
    //Run (in pipline): generate-license-file --input package.json --output src/assets/frontend3rdPartyLicenses.txt
    //Source: https://www.npmjs.com/package/generate-license-file
    frontend3rdParties: string;
    hasAcceptedLicense: boolean = false;
    ericAcceptedLicenseDateTime: moment.Moment;
    public downloadType: DownloadType;
    public timezone: string = "UTC";

    ericLicenseType1: _definitions_KPMGFileType;
    ericLicenseType2: _definitions_KPMGFileType;
    acceptedOn: string;
    eRiCDisclaimerText = '';
    declarationId: number;
    companyId: number;
    userId: number;
    testo: boolean;
    legalRemedyId: number = undefined;

    constructor(injector: Injector,
                private _backend3PartyLicenseServiceProxy: Backend3PartyLicenseServiceProxy,
                private _guidelineService: KPMGAppAttachmentsAppServiceProxy,
                private _downloadService: FileDownloadService,
                private _hostSettingService: HostSettingsServiceProxy,
                private _httpClient: HttpClient,
                private _router: Router
    ) {
        super(injector);
    }

    show(declarationId: number = undefined, companyId: number = undefined, userId: number = undefined, legalRemedyId: number = undefined) {
        this.declarationId = declarationId;
        this.companyId = companyId;
        this.userId = userId;
        this.legalRemedyId = legalRemedyId;
        this._httpClient.get(abp.custom.appPath + 'assets/frontend3rdPartyLicenses.txt', {
            observe: 'response',
            responseType: 'blob'
        }).subscribe(s => {
            let reader = new FileReader();
            reader.addEventListener('loadend', (e) => {
                this.frontend3rdParties = <string>e.target.result;

                this.ericLicenseType1 = _definitions_KPMGFileType.EricLicense1;
                this.ericLicenseType2 = _definitions_KPMGFileType.EricLicense2;

                this._backend3PartyLicenseServiceProxy.getBackend3PartyLicenses()
                    .subscribe(result => {
                        this.backend3rdParties = result.backend3PartyLicense;
                        this.ericAcceptedLicenseDateTime = result.ericAcceptanceLicenseDateTime;
                        this.hasAcceptedLicense = this.ericAcceptedLicenseDateTime != undefined ? true : false;
                        const format1 = "DD.MM.YYYY HH:mm:ss"
                        this.acceptedOn = this.ericAcceptedLicenseDateTime != undefined ? this.l('AcceptedOn') + " " + result.ericAcceptanceLicenseDateTime.format(format1) : "";

                        this._hostSettingService.getERiCSettings().subscribe(result => {
                            this.eRiCDisclaimerText = result.eRiCDisclaimerText;
                            this.modal.show();
                        });
                    });
            });

            reader.readAsText(s.body);

        });
        // this.frontend3rdParties = frontend3rdPartyLicenses.default;


    }

    close(): void {
        this.modal.hide();
    }

    saveEricAcceptLicense(): void {
        this._backend3PartyLicenseServiceProxy.updateEricAcceptanceSettings(this.hasAcceptedLicense ? moment() : undefined)
            .subscribe(() => {
                if (this.hasAcceptedLicense) {
                    this.acceptedOn = this.ericAcceptedLicenseDateTime != undefined ? this.l('AcceptedOn') + moment() : "";
                }
                this.notify.info(this.l('SavedSuccessfully'));
            });

        if (this.hasAcceptedLicense == true && (!!this.declarationId || !!this.legalRemedyId)) {
            this.goToSubmissionFields();
        }

        this.modal.hide();
    }

    goToSubmissionFields() {
        setTimeout(() => {
            this._router.navigate(['/app/main/kpmg/submissionFields'], {
                queryParams: {
                    companyId: this.companyId,
                    userId: this.userId,
                    declarationId: this.declarationId,
                    legalRemedyId: this.legalRemedyId,
                    mode: SubmitType.Validation
                },
            });
        });
    }

    download(type: _definitions_KPMGFileType) {
        this._guidelineService.getFile(type).subscribe(result => {
            this._downloadService.downloadTempFile(result);
        });
    }
}
