import {UtilsService} from 'abp-ng2-module';
import {CompilerOptions, NgModuleRef, Type} from '@angular/core';
import {AppAuthService} from '@app/shared/common/auth/app-auth.service';
import {AppConsts} from '@shared/AppConsts';
import {DynamicResourcesHelper} from '@shared/helpers/DynamicResourcesHelper';
import {SubdomainTenancyNameFinder} from '@shared/helpers/SubdomainTenancyNameFinder';
import {XmlHttpRequestHelper} from '@shared/helpers/XmlHttpRequestHelper';
import {LocaleMappingService} from '@shared/locale-mapping.service';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import {environment} from './environments/environment';
import {UrlHelper} from '@shared/helpers/UrlHelper';
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import 'zone.js';

export class AppPreBootstrap {

    static run(appRootUrl: string, callback: () => void, resolve: any, reject: any): void {

        abp.auth.tokenCookieName = 'KPMG.RET.Abp.AuthToken';
        abp.multiTenancy.tenantIdCookieName = 'KPMG-RET-Abp-TenantId';

        AppPreBootstrap.getApplicationConfig(appRootUrl, () => {
            if (UrlHelper.isInstallUrl(location.href)) {
                AppPreBootstrap.loadAssetsForInstallPage(callback);
                return;
            }

            const queryStringObj = UrlHelper.getQueryParameters();

            if (queryStringObj.redirect && queryStringObj.redirect === 'TenantRegistration') {
                if (queryStringObj.forceNewRegistration) {
                    new AppAuthService().logout();
                }

                location.href = AppConsts.appBaseUrl + '/account/select-edition';
            } else if (queryStringObj.impersonationToken) {
                AppPreBootstrap.impersonatedAuthenticate(queryStringObj.impersonationToken, queryStringObj.tenantId, () => {
                    AppPreBootstrap.getUserConfiguration(callback);
                });
            } else if (queryStringObj.switchAccountToken) {
                AppPreBootstrap.linkedAccountAuthenticate(queryStringObj.switchAccountToken, queryStringObj.tenantId, () => {
                    AppPreBootstrap.getUserConfiguration(callback);
                });
            } else {
                AppPreBootstrap.getUserConfiguration(callback);
            }
        });
    }

    static bootstrap<TM>(moduleType: Type<TM>, compilerOptions?: CompilerOptions | CompilerOptions[]): Promise<NgModuleRef<TM>> {
        return platformBrowserDynamic().bootstrapModule(moduleType, compilerOptions);
    }

    private static getApplicationConfig(appRootUrl: string, callback: () => void) {
        let type = 'GET';
        let url = appRootUrl + 'assets/' + environment.appConfig;
        let customHeaders = [
            {
                name: 'KPMG-RET-Abp-TenantId',
                value: abp.multiTenancy.getTenantIdCookie() + ''
            }];

        XmlHttpRequestHelper.ajax(type, url, customHeaders, null, (result) => {
            const subdomainTenancyNameFinder = new SubdomainTenancyNameFinder();
            const tenancyName = subdomainTenancyNameFinder.getCurrentTenancyNameOrNull(result.appBaseUrl);

            AppConsts.appBaseUrlFormat = result.appBaseUrl;
            AppConsts.remoteServiceBaseUrlFormat = result.remoteServiceBaseUrl;
            AppConsts.localeMappings = result.localeMappings;
            AppConsts.instrumentationKey = result.appInsightKey;

            if (result.appPath.length > 1 && result.appPath.endsWith("/"))  // "/ret/" => "/ret"
                abp.appPath = result.appPath.substring(0, result.appPath.length - 1);
            else
                abp.appPath = result.appPath; // => "/"

            if (tenancyName == null) {
                AppConsts.appBaseUrl = result.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl + '.', '');
                AppConsts.remoteServiceBaseUrl = result.remoteServiceBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl + '.', '');
            } else {
                AppConsts.appBaseUrl = result.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl, tenancyName);
                AppConsts.remoteServiceBaseUrl = result.remoteServiceBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl, tenancyName);
            }

            callback();
        });
    }

    private static getCurrentClockProvider(currentProviderName: string): abp.timing.IClockProvider {
        if (currentProviderName === 'unspecifiedClockProvider') {
            return abp.timing.unspecifiedClockProvider;
        }

        if (currentProviderName === 'utcClockProvider') {
            return abp.timing.utcClockProvider;
        }

        return abp.timing.localClockProvider;
    }

    private static impersonatedAuthenticate(impersonationToken: string, tenantId: number, callback: () => void): void {
        abp.multiTenancy.setTenantIdCookie(tenantId);
        const cookieLangValue = abp.utils.getCookieValue('Abp.Localization.CultureName');

        let requestHeaders = {
            '.AspNetCore.Culture': ('c=' + cookieLangValue + '|uic=' + cookieLangValue),
            'KPMG-RET-Abp-TenantId': abp.multiTenancy.getTenantIdCookie()
        };

        XmlHttpRequestHelper.ajax(
            'POST',
            AppConsts.remoteServiceBaseUrl + '/api/TokenAuth/ImpersonatedAuthenticate?impersonationToken=' + impersonationToken,
            requestHeaders,
            null,
            (response) => {
                let result = response.result;
                abp.auth.setToken(result.accessToken);
                AppPreBootstrap.setEncryptedTokenCookie(result.encryptedAccessToken);
                location.search = '';
                callback();
            }
        );
    }

    private static linkedAccountAuthenticate(switchAccountToken: string, tenantId: number, callback: () => void): void {
        abp.multiTenancy.setTenantIdCookie(tenantId);
        const cookieLangValue = abp.utils.getCookieValue('Abp.Localization.CultureName');

        let requestHeaders = {
            '.AspNetCore.Culture': ('c=' + cookieLangValue + '|uic=' + cookieLangValue),
            'KPMG-RET-Abp-TenantId': abp.multiTenancy.getTenantIdCookie()
        };

        XmlHttpRequestHelper.ajax(
            'POST',
            AppConsts.remoteServiceBaseUrl + '/api/TokenAuth/LinkedAccountAuthenticate?switchAccountToken=' + switchAccountToken,
            requestHeaders,
            null,
            (response) => {
                let result = response.result;
                abp.auth.setToken(result.accessToken);
                AppPreBootstrap.setEncryptedTokenCookie(result.encryptedAccessToken);
                location.search = '';
                callback();
            }
        );
    }

    private static getUserConfiguration(callback: () => void): any {
        const cookieLangValue = abp.utils.getCookieValue('Abp.Localization.CultureName');
        const token = abp.auth.getToken();

        let requestHeaders = {
            '.AspNetCore.Culture': ('c=' + cookieLangValue + '|uic=' + cookieLangValue),
            'KPMG-RET-Abp-TenantId': abp.multiTenancy.getTenantIdCookie()
        };

        if (token) {
            requestHeaders['Authorization'] = 'Bearer ' + token;
        }

        return XmlHttpRequestHelper.ajax('GET', AppConsts.remoteServiceBaseUrl + '/AbpUserConfiguration/GetAll', requestHeaders, null, (response) => {
            let result = response.result;

            _.merge(abp, result);

            abp.clock.provider = this.getCurrentClockProvider(result.clock.provider);

            AppPreBootstrap.configureMoment();

            // moment.locale(new LocaleMappingService().map('moment', abp.localization.currentLanguage.name).toLowerCase());
            // (window as any).moment.locale(new LocaleMappingService().map('moment', abp.localization.currentLanguage.name).toLowerCase());

            // if (abp.clock.provider.supportsMultipleTimezone) {
            //     moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
            //     (window as any).moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
            // }

            abp.event.trigger('abp.dynamicScriptsInitialized');

            AppConsts.recaptchaSiteKey = abp.setting.get('Recaptcha.SiteKey');
            AppConsts.subscriptionExpireNootifyDayCount = parseInt(abp.setting.get('App.TenantManagement.SubscriptionExpireNotifyDayCount'));

            DynamicResourcesHelper.loadResources(callback);

            // load primeng translations

        });
    }

    private static configureMoment() {

        moment.locale(new LocaleMappingService().map('moment', abp.localization.currentLanguage.name).toLowerCase());
        (window as any).moment.locale(new LocaleMappingService().map('moment', abp.localization.currentLanguage.name).toLowerCase());

        if (abp.clock.provider.supportsMultipleTimezone) {
            moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
            (window as any).moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
            moment.fn.toJSON = function () {
                return this.format();
            };

            moment.fn.toISOString = function () {
                return this.format();
            };
        } else {
            moment.fn.toJSON = function () {
                let timeZoneDifference = (this._d.getTimezoneOffset() / 60) * -1; //convert to positive value.
                this._d.setTime(this._d.getTime() + (timeZoneDifference * 60) * 60 * 1000);
                return this._d.toISOString();
            };
            moment.fn.toISOString = function () {
                let timeZoneDifference = (this._d.getTimezoneOffset() / 60) * -1; //convert to positive value.
                this._d.setTime(this._d.getTime() + (timeZoneDifference * 60) * 60 * 1000);
                return this._d.toISOString();
            };
        }
    }

    private static setEncryptedTokenCookie(encryptedToken: string) {
        new UtilsService().setCookieValue(AppConsts.authorization.encrptedAuthTokenName,
            encryptedToken,
            new Date(new Date().getTime() + 365 * 86400000), //1 year
            abp.appPath,
            '',
            {secure: location.protocol === 'https:'}
        );
    }

    private static loadAssetsForInstallPage(callback) {
        abp.setting.values['App.UiManagement.Theme'] = 'default';
        abp.setting.values['default.App.UiManagement.ThemeColor'] = 'default';

        DynamicResourcesHelper.loadResources(callback);
    }
}
