import {Injectable, Injector} from '@angular/core';
import {AppConsts} from '@shared/AppConsts';
import {FileDto} from '@shared/service-proxies/service-proxies';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ModelUploadHelper} from "@shared/helpers/ModelUploadHelper";
import {Base64Helper} from "@shared/helpers/Base64Helper";

@Injectable()
export class FileDownloadService {

    constructor(
        private client: HttpClient,
        private modelUploadHelper: ModelUploadHelper
    ){
    }

    downloadTempFile(file: FileDto) {
        const formData: FormData = new FormData();
        this.modelUploadHelper.sanitize(file);
        const uploadFile = new Blob([Base64Helper.b64EncodeUnicode(JSON.stringify(file))]);
        formData.append('model', uploadFile, 'model.txt');

        this.client.post(AppConsts.remoteServiceBaseUrl + '/File/DownloadTempFile', formData, {
            observe: "response",
            responseType: "blob"
        }).subscribe(res => {
            this.downloadBlob(res.body, file.fileName);
        });
        // const url = AppConsts.remoteServiceBaseUrl + '/File/DownloadTempFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + this.getEscapedString(file.fileName);
        // location.href = url; //TODO: This causes reloading of same page in Firefox
    }

    downloadBinaryObject(id: any, displayName: string) {
        let tenantID = abp.session.tenantId;
        let enDisplayName = encodeURIComponent(displayName);
        let url = AppConsts.remoteServiceBaseUrl + '/File/DownloadBinaryObject?id=' + id + '&displayName=' + enDisplayName;
        if (tenantID) {
            url = AppConsts.remoteServiceBaseUrl + '/File/DownloadBinaryObject?id=' + id + '&displayName=' + enDisplayName + '&tenantID=' + tenantID;
        }

        location.href = url; //TODO: This causes reloading of same page in Firefox
    }

    downloadStorageFile(id: any, companyId: number, isHost: boolean = false) {
        let tenantID = abp.session.tenantId;
        let url = AppConsts.remoteServiceBaseUrl + '/File/DownloadStorageFile?id=' + id + '&companyId=' + companyId;
        if (tenantID && !isHost) {
            url = AppConsts.remoteServiceBaseUrl + '/File/DownloadStorageFile?id=' + id + '&companyId=' + companyId + '&tenantID=' + tenantID;
        }
        location.href = url; //TODO: This causes reloading of same page in Firefox
    }

    downloadStorageFileBlob(id: any, companyId: any, filename: string) {
        let tenantID = abp.session.tenantId;
        let url = AppConsts.remoteServiceBaseUrl + '/File/DownloadStorageFile?id=' + id + '&companyId=' + companyId;

        if (tenantID) {
            url = AppConsts.remoteServiceBaseUrl + '/File/DownloadStorageFile?id=' + id + '&companyId=' + companyId + '&tenantID=' + tenantID;
        } //location.href = url;

        this.client.get(url, {observe: 'response', responseType: 'blob'}).subscribe(res => {
            this.downloadBlob(res.body, filename);
        });
    }

    downloadStorageFileBasedOnFolderName(id: any, folderName: string, isHost: boolean = false, fileName: string = "") {
        let tenantID = abp.session.tenantId;
        let url = AppConsts.remoteServiceBaseUrl + '/File/DownloadStorageFileBasedOnFolderName?id=' + id + '&folderName=' + folderName;

        if (tenantID && !isHost) {
            url = AppConsts.remoteServiceBaseUrl + '/File/DownloadStorageFileBasedOnFolderName?id=' + id + '&folderName=' + folderName + '&tenantID=' + tenantID;
        }

        //location.href = url; //TODO: This causes reloading of same page in Firefox
        this.client.get(url, {observe: 'response', responseType: 'blob'}).subscribe(res => {
            this.downloadBlob(res.body, fileName);
        });
    }

    downloadBlob(blob: Blob, fileName: string) {
        //other browsers
        let url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                window.removeEventListener(
                    'click',
                    clickHandler
                );
                abp.ui.clearBusy();
            }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();


        return;
    }

    getEscapedString(input: string): string {
        return input.replace('&', '%26');
    }

    downloadProtocolAttachment(id: any, companyId: any, filename: string) {
        let tenantID = abp.session.tenantId;
        const url = AppConsts.remoteServiceBaseUrl + '/File/DownloadProtocolAttachment?id=' + id + '&companyId=' + companyId + '&tenantId=' + tenantID;
        //location.href = url;
        this.client.get(url, {observe: 'response', responseType: 'blob'}).subscribe(res => {
            this.downloadBlob(res.body, filename);
        });
    }

}
