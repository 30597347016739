import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {LocalizationService} from "abp-ng2-module";
import {AppConsts} from "@shared/AppConsts";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private localization: LocalizationService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(e => this.handleError(e)))
    }

    private handleError(e: HttpErrorResponse): Observable<any> {
        if(e.status === 403 && !!e.error){
            if(e.error.indexOf('403 Forbidden') != -1 && e.error.indexOf('Microsoft-Azure-Application-Gateway') != -1 ){
                abp.ui.clearBusy();
                abp.message.error(this.localization.localize('AFW_Blocked_Request_Message', AppConsts.localization.defaultLocalizationSourceName)
                    , this.localization.localize('Warning', AppConsts.localization.defaultLocalizationSourceName), {html: true})
            }
        }
        return throwError(() => e);
    }
}
