import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
    selector: 'Disclaimer',
    templateUrl: './disclaimer.component.html',
    styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent implements OnInit {
    @ViewChild('createOrEditModal') modal: ModalDirective;
    disclaimer: string;

    constructor() {
    }

    ngOnInit() {
        this.disclaimer = abp.custom.disclaimer;
    }

    show() {
        this.modal.show();
    }

    close(): void {

        this.modal.hide();
    }
}
