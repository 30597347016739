import {Component, Inject, Injector, OnInit, ViewEncapsulation} from '@angular/core';

import {AppConsts} from '@shared/AppConsts';
import {AppComponentBase} from '@shared/common/app-component-base';

import {DOCUMENT} from '@angular/common';
import {Layout5Service} from './theme5.service';
import {HttpClient} from "@angular/common/http";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Component({
    templateUrl: './theme5-brand.component.html',
    selector: 'theme5-brand',
    encapsulation: ViewEncapsulation.None
})
export class Theme5BrandComponent extends AppComponentBase implements OnInit {
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    // tslint:disable-next-line:no-inferrable-types
    menuTogglerValue: boolean = false;
    logo: SafeUrl;
    constructor(
        injector: Injector,
        @Inject(DOCUMENT) private document: Document,
        private layout5Service: Layout5Service
    ) {
        super(injector);
    }

    ngOnInit() {
        this.menuTogglerValue = this.layout5Service.getToggleMenuValue();
        this.getTenantLogo();
    }

    clickTopbarToggle(): void {
        this.document.body.classList.toggle('m-topbar--on');
    }

    clickLeftAsideShowMobile(): void {
        this.document.body.classList.toggle('m-aside-left--on');
    }

    clickLeftAsideHideToggle(): void {
        this.document.body.classList.toggle('m-aside-left--hide');
    }

    getTenantLogo() {
        // remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?' + 'tenantId=' + appSession.tenant.id
        if (this.appSession.tenant)
        {
            this.httpClient.get(this.remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?' + 'tenantId=' + this.appSession.tenant.id, {observe: 'response', responseType: 'blob'}).subscribe(r => {
                let objectURL = URL.createObjectURL(r.body);
                this.logo = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            })
        }
    }
}
