export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;
    static emailRegex: RegExp = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]+$', 'i');
    static instrumentationKey: string;
    static masterDataDefaultValidFromDate = Date.UTC(2021,11, 31, 0, 0, 0);
    static declarationDeadlineDate: string = '2023-01-31';
    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'KPMG'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'KPMG.RET.enc_auth_token'
    };

    static readonly grid = {
        defaultPageSize: 10
    };
}
