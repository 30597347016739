<!-- [stateKey]="stateKey" -->
<p-table #dt (onColReorder)="updateOrderConfig($event)" (onColResize)="updateTableConfiguration($event)"
         (onLazyLoad)="loading = true; onLazyLoad.emit($event); this.selectedItems = [];" [columns]="columns"
         [currentPageReportTemplate]="tableConfigTypes[tableConfigType] | localize | pageReport" [dataKey]="dataKey" [lazyLoadOnInit]="false"
         [lazy]="lazy"
         [loading]="loading" [paginator]="paginator" [reorderableColumns]="reorderableColumns" [resizableColumns]="true"
         [rowHover]="rowHover" [rowsPerPageOptions]="rowsPerPageOptions" [rows]="rows"
         [showCurrentPageReport]="showCurrentPageReport" [stateStorage]="stateStorage" [styleClass]="styleClass"
         [totalRecords]="totalRecords" [(selection)]="selectedItems" [value]="value" columnResizeMode="expand"
         paginatorDropdownAppendTo="body" withUnsort>

    <ng-template *ngIf="globalSearch" pTemplate="caption">
        <div class="row align-items-center">
            <div class="col-3 global-filter">
                <div class="p-inputgroup">
                    <div class="input-group-append">
                        <button type="button" (click)="globalButtonSearch()" pButton
                                class="btn btn-outline-primary blue search-button">
                            <div class="centered-icon">
                                <i class="fa fa-search"></i>
                            </div>
                        </button>
                    </div>
                    <input (keyup.enter)="debounceInput($event.target.value, '')" [(ngModel)]="globalSearchInput"
                           [placeholder]="l('Search')" [value]="dt.filters['global']?.value" pInputText size="50"
                           type="text" style="margin: 0;">
                    <div class="input-group-prepend">
                        <button (click)="resetFilters()" class="btn btn-outline-danger" type="button">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="hasExtraCaption()" [ngTemplateOutlet]="getExtraCaption()">
            </ng-container>
        </div>
    </ng-template>
    <ng-template let-columns pTemplate="header">
        <tr>
            <th *ngIf="(menuItems && menuItems.length > 0) || (customMenuItems && customMenuItems.length > 0 && value.length > 0)"
                class="text-center w-10px"></th>
            <th *ngIf="selectableFilter" class="text-center w-10px">
            </th>
            <th *ngFor="let col of columns; let last = last;" [hidden]="col.isHidden"
                [pSortableColumnDisabled]="!col.sortable" [pSortableColumn]="col.field"
                [style.width]="setWidth(col.width)" id="{{col.field}}" pReorderableColumn pResizableColumn>
                <span class="pi pi-user-edit" *ngIf="col.isEditable"></span>
                {{ l(col.headerLocalization) }}
                <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
            </th>
        </tr>
        <tr>
            <th *ngIf="customMenuItems && customMenuItems.length > 0 && value.length > 0" class="text-center w-10px">
                <div>
                    <p-slideMenu #customMenu [menuWidth]="155" [model]="customMenuItems" [popup]="true"
                                 [style]="{ width: '155px' }"
                                 [viewportHeight]="customActionButtonSize[customMenuItems.length - 1]" appendTo="body">
                    </p-slideMenu>
                    <button (click)="customMenu.toggle($event)" class="p-button-secondary" icon="fa fa-cog" pButton
                            type="button">
                    </button>
                </div>
            </th>
            <th *ngIf="menuItems && menuItems.length > 0 && customMenuItems.length == 0" class="text-center w-10px">
                <button (click)="visibleSidebar = true" *ngIf="viewTableConfigButton; else disableSetting"
                        class="p-button-secondary" icon="fa fa-cog" pButton type="button"></button>
                <ng-template #disableSetting>
                    <div [pTooltip]="l('TableConfigurationDisabled')" style="display:inline-block;">
                        <button class="p-button-secondary" disabled icon="fa fa-cog" pButton type="button">
                        </button>
                    </div>
                </ng-template>
            </th>
            <th *ngIf="selectableFilter" class="text-center w-10px">
                <p-tableHeaderCheckbox #thCheckbox></p-tableHeaderCheckbox>
            </th>
            <th *ngFor="let col of columns" [hidden]="col.isHidden" [ngSwitch]="col.fieldType">
                <!-- Text -->
                <input *ngSwitchCase="0" [hidden]="!col.isFilterEnabled"
                       (input)="debounceInput($event.target.value, col.field)" [ngModel]="dt.filters[col.field]?.value"
                       pInputText>
                <!-- Boolean -->
                <p-multiSelect (onChange)="debounceInput($event.value, col.field)" (onClear)="clearFilter(col.field)"
                               *ngSwitchCase="1" [hidden]="!col.isFilterEnabled"
                               [ngModel]="dt.filters[col.field]?.value"
                               [options]="booleanOptions" [placeholder]="l('Select')" appendTo="body" showClear="true">
                </p-multiSelect>
                <!-- Tristate Boolean -->
                <p-multiSelect (onChange)="debounceInput($event.value, col.field)" (onClear)="clearFilter(col.field)"
                               *ngSwitchCase="22" [hidden]="!col.isFilterEnabled"
                               [ngModel]="dt.filters[col.field]?.value"
                               [options]="booleanTristateOptions" [placeholder]="l('Select')" appendTo="body" showClear="true">
                </p-multiSelect>
                <!-- Date -->
                <p-calendar *ngSwitchCase="2" [(ngModel)]="dateFilters[col.field]" [hidden]="!col.isFilterEnabled"
                            (ngModelChange)="debounceInput($event, col.field)" selectionMode="range" inputId="range"
                            appendTo="body" [baseZIndex]="10000" useUtc></p-calendar>
                <!-- Datetime -->
                <p-calendar *ngSwitchCase="3" [(ngModel)]="dateFilters[col.field]" [hidden]="!col.isFilterEnabled"
                            (ngModelChange)="debounceInput($event, col.field)" selectionMode="range" inputId="range"
                            appendTo="body" [baseZIndex]="10000" useUtc></p-calendar>
                <!-- Enum -->
                <p-multiSelect *ngSwitchCase="4" [options]="enumerations[col.field].value"
                               [ngModel]="dt.filters[col.field]?.value" [placeholder]="l('Select')" appendTo="body"
                               [hidden]="!col.isFilterEnabled" (onChange)="debounceInput($event.value, col.field)"
                               showClear="true"
                               (onClear)="clearFilter(col.field)">
                </p-multiSelect>
                <!-- EnumFlag -->
                <p-multiSelect (onChange)="debounceInput($event.value, col.field)" (onClear)="clearFilter(col.field)"
                               *ngSwitchCase="17" [hidden]="!col.isFilterEnabled"
                               [ngModel]="dt.filters[col.field]?.value"
                               [options]="enumerations[col.field].value" [placeholder]="l('Select')" appendTo="body"
                               showClear="true">
                </p-multiSelect>
                <!-- NumberRange -->
                <div *ngSwitchCase="6" [hidden]="!col.isFilterEnabled" class="p-inputgroup range-filter">
                    <input #min (input)="debounceInput([$event.target.value, max.value], col.field)"
                           [ngModel]="dt.filters[col.field]?.value[0]" [placeholder]="l('Min')" pInputText
                           type="number">
                    <span class="p-inputgroup-addon">-</span>
                    <input #max (input)="debounceInput([min.value, $event.target.value], col.field)"
                           [ngModel]="dt.filters[col.field]?.value[1]" [placeholder]="l('Max')" pInputText
                           type="number">
                </div>
                <!-- Number -->
                <p-inputNumber (onInput)="debounceInput($event.value, col.field)" *ngSwitchCase="7"
                               [hidden]="!col.isFilterEnabled" [maxFractionDigits]="0"
                               [ngModel]="dt.filters[col.field]?.value"
                               [useGrouping]="false">
                </p-inputNumber>
                <!-- FileSize -->
                <input (input)="debounceInput($event.target.value, col.field)" *ngSwitchCase="8"
                       [hidden]="!col.isFilterEnabled" [ngModel]="dt.filters[col.field]?.value" pInputText>
                <!-- TruncatedText -->
                <input (input)="debounceInput($event.target.value, col.field)" *ngSwitchCase="9"
                       [hidden]="!col.isFilterEnabled" [ngModel]="dt.filters[col.field]?.value" pInputText>
                <!-- Decimal -->
                <input (input)="debounceInput($event.target.value, col.field)" *ngSwitchCase="11"
                    [hidden]="!col.isFilterEnabled" [ngModel]="dt.filters[col.field]?.value" pInputText type="number">
                <!-- MoneyRange -->
                <div *ngSwitchCase="13" [hidden]="!col.isFilterEnabled" class="p-inputgroup range-filter">
                    <input #min (input)="debounceInput([$event.target.value, max.value], col.field)"
                           [ngModel]="dt.filters[col.field]?.value[0]" [placeholder]="l('Min')" pInputText>
                    <span class="p-inputgroup-addon">-</span>
                    <input #max (input)="debounceInput([min.value, $event.target.value], col.field)"
                           [ngModel]="dt.filters[col.field]?.value[1]" [placeholder]="l('Max')" pInputText>
                </div>
                <!-- DatePicker -->
                <p-calendar (ngModelChange)="debounceInput($event, col.field)" *ngSwitchCase="15"
                            [hidden]="!col.isFilterEnabled" [ngModel]="dateFilter[col.field] | m2d" appendTo="body"
                            useUtc>
                </p-calendar>
                <!-- DateUTC -->
                <p-calendar (ngModelChange)="debounceInput($event, col.field)" *ngSwitchCase="16"
                            [hidden]="!col.isFilterEnabled" [ngModel]="dateFilter[col.field] | m2d" appendTo="body"
                            useUtc>
                </p-calendar>
                <!-- Year -->
                <p-calendar *ngSwitchCase="19" [ngModel]="dateFilters[col.field]  | m2d" [hidden]="!col.isFilterEnabled"
                            (ngModelChange)="debounceInput($event, col.field)" selectionMode="range" view="year"
                            inputId="range"
                            appendTo="body" [baseZIndex]="10000" useUtc></p-calendar>
                <!-- NumberLeadingZero -->
                <input (input)="debounceInput($event.target.value, col.field)" *ngSwitchCase="20" type="number" step="1"
                       [hidden]="!col.isFilterEnabled" [ngModel]="dt.filters[col.field]?.value" pInputText>

                <!-- DateUTCRange -->
                <p-calendar *ngSwitchCase="21" [(ngModel)]="dateFilters[col.field]" [hidden]="!col.isFilterEnabled"
                            (ngModelChange)="debounceInput($event, col.field)" selectionMode="range" inputId="range"
                            appendTo="body" [baseZIndex]="10000" useUtc></p-calendar>

            </th>
        </tr>
    </ng-template>
    <ng-template let-columns="columns" let-record="$implicit" let-rowData pTemplate="body">

        <ng-container [ngTemplateOutletContext]="{ id: tableUtilsService.getValue(rowData, dataKey)}"
                      [ngTemplateOutlet]="kpmgTableBody">
        </ng-container>

        <ng-template #kpmgTableBody let-id="id">
            <tr [pSelectableRow]="record">
                <td *ngIf="menuItems && menuItems.length > 0" class="text-center w-10px">
                    <div>
                        <p-slideMenu #menu [menuWidth]="menuWidth" [model]="menuItems[id]" [popup]="true"
                                     backLabel="{{l('Back')}}" [viewportHeight]="actionButtonSize[id]" appendTo="body">
                        </p-slideMenu>
                        <button (click)="menu.toggle($event); setMenuWidth(menu);" *ngIf="viewActionButton"
                                class="p-button-secondary" icon="fa fa-cog" pButton type="button">
                        </button>
                    </div>
                </td>
                <td *ngIf="selectableFilter" class="text-center w-10px">
                    <p-tableCheckbox [value]="record">
                    </p-tableCheckbox>
                </td>
                <td *ngFor="let col of columns" [hidden]="col.isHidden" [pEditableColumn]="tableUtilsService.getValue(rowData, col.field)" [pEditableColumnField]="col.field">
                    <!-- responsive -->
                    <span class="p-column-title">{{ l(col.headerLocalization) }} </span>
                    <ng-container *ngIf="hasTemplate(col.field)"
                                  [ngTemplateOutletContext]="{col: col, rowData: rowData, record: record}"
                                  [ngTemplateOutlet]="getTemplate(col.field)">
                    </ng-container>

                    <ng-container *ngIf="!hasTemplate(col.field)" [ngTemplateOutlet]="defaultTableCells">
                    </ng-container>

                    <ng-template #defaultTableCells>
                        <div [ngSwitch]="col.fieldType">
                            <!-- Boolean -->
                            <ng-container *ngSwitchCase="1">
                                <span *ngIf="!col.isEditable"
                                      [class]="tableUtilsService.getValue(rowData,col.field) ? 'm-badge m-badge--success m-badge--wide' : 'm-badge m-badge--metal m-badge--wide'">
                                    {{ (tableUtilsService.getValue(rowData, col.field) ? "Yes" : "No") | localize }}
                                </span>
                                <p-cellEditor *ngIf="col.isEditable">
                                    <ng-template pTemplate="input">
                                        <p-checkbox [(ngModel)]="rowData[col.field]" [binary]="true"
                                                    [inputId]="col.field"></p-checkbox>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                    <span
                                        [class]="tableUtilsService.getValue(rowData,col.field) ? 'm-badge m-badge--success m-badge--wide' : 'm-badge m-badge--metal m-badge--wide'">
                                        {{ (tableUtilsService.getValue(rowData, col.field) ? "Yes" : "No") | localize }}
                                    </span>
                                    </ng-template>
                                </p-cellEditor>
                            </ng-container>
                            <!-- Boolean Tristate -->
                            <ng-container *ngSwitchCase="22">
                                <span *ngIf="!col.isEditable"
                                      [class]="tableUtilsService.getValue(rowData,col.field) ? 'm-badge m-badge--success m-badge--wide' : 'm-badge m-badge--metal m-badge--wide'">
                                    {{ (tableUtilsService.getValue(rowData, col.field) === null ? "NotSet" : (tableUtilsService.getValue(rowData, col.field) ? "Yes" : "No")) | localize }}
                                </span>
                                <p-cellEditor *ngIf="col.isEditable">
                                    <ng-template pTemplate="input">
                                        <p-triStateCheckbox [(ngModel)]="rowData[col.field]" [inputId]="col.field"></p-triStateCheckbox>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <p-tag [value]="(tableUtilsService.getValue(rowData, col.field) === null ? 'NotSet' :
                                        (tableUtilsService.getValue(rowData, col.field) ? 'Yes' : 'No')) | localize"
                                        [severity]="tableUtilsService.getValue(rowData, col.field) === null ? 'primary' :
                                        (tableUtilsService.getValue(rowData, col.field) ? 'success' : 'danger')"></p-tag>
<!--                                        <span-->
<!--                                           [class]="tableUtilsService.getValue(rowData,col.field) ? 'm-badge m-badge&#45;&#45;success m-badge&#45;&#45;wide' : 'm-badge m-badge&#45;&#45;metal m-badge&#45;&#45;wide'">-->
<!--                                            -->
<!--                                        </span>-->
                                    </ng-template>
                                </p-cellEditor>
                            </ng-container>
                            <!-- Date -->
                            <span *ngSwitchCase="2">
                                {{ tableUtilsService.getValue(rowData, col.field) | momentFormat : 'L' }}
                            </span>
                            <!-- Datetime -->
                            <span *ngSwitchCase="3">
                                {{ tableUtilsService.getValue(rowData, col.field) | momentFormat : 'L' }}&nbsp;
                                {{ tableUtilsService.getValue(rowData, col.field) | momentFormat : 'LT' }}
                            </span>
                            <!-- Enum -->
                            <span *ngSwitchCase="4">
                                {{
                                    tableUtilsService.localizeEnumList(enumerations[col.field].value,
                                        tableUtilsService.getValue(rowData, col.field))
                                }}
                            </span>
                            <!-- EnumFlag -->
                            <span *ngSwitchCase="17">
                                {{
                                    tableUtilsService.localizeEnumFlagList(enumerations[col.field].value,
                                        tableUtilsService.getValue(rowData, col.field))
                                }}
                            </span>
                            <!-- FileSize -->
                            <span *ngSwitchCase="8">
                                {{ tableUtilsService.getValue(rowData, col.field) | fileSize }}
                            </span>
                            <!-- TruncatedText -->
                            <span *ngSwitchCase="9" [escape]="false"
                                  [pTooltip]="tableUtilsService.getValue(rowData,col.field)"
                                  tooltipStyleClass="p-tooltip-xl" tooltipZIndex="10000">
                                <!-- {{ truncateString(tableUtilsService.getValue(rowData, col.field), col.truncate)}} -->
                                {{ tableUtilsService.getValue(rowData, col.field) | truncateText : col.truncate }}
                            </span>
                            <!-- Time -->
                            <span *ngSwitchCase="10">
                                {{ tableUtilsService.getValue(rowData, col.field) | momentFormat : 'LTS' }}
                            </span>
                            <!-- Decimal -->
                            <span *ngSwitchCase="11">
                                {{ tableUtilsService.getValue(rowData, col.field) }}
                            </span>
                            <!-- Enum list -->
                            <span *ngSwitchCase="12">
                                {{ tableUtilsService.getValue(rowData, col.field) }}
                            </span>
                            <!-- Money -->
                            <span *ngSwitchCase="13">
                                {{ tableUtilsService.getValue(rowData, col.field) | currency: 'EUR' }}
                            </span>
                            <!-- Editable (Obsolete will be removed in a future release)-->
                            <span *ngSwitchCase="14">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input (input)="updateField(col.field, rowData)"
                                               [(ngModel)]="rowData[col.field]" class="input-editable" type="text">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ tableUtilsService.getValue(rowData, col.field) }}
                                    </ng-template>
                                </p-cellEditor>
                            </span>
                            <!-- Date only one pop up selector-->
                            <span *ngSwitchCase="15">
                                {{ tableUtilsService.getValue(rowData, col.field) | momentFormat : 'L' }}
                            </span>
                            <!-- DateUTC -->
                            <span *ngSwitchCase="16">
                                {{ toUTC(tableUtilsService.getValue(rowData, col.field)) | date }}
                            </span>
                            <!-- DateUTCRange -->
                            <span *ngSwitchCase="21">
                                {{ toUTC(tableUtilsService.getValue(rowData, col.field)) | date }}
                            </span>
                            <!-- Text -->
                            <span *ngSwitchDefault>
                                <ng-container *ngSwitchDefault>
                                    <p-cellEditor *ngIf="col.isEditable">
                                        <ng-template pTemplate="input">
                                           <input pInputText (input)="updateField(col.field, rowData)"
                                                   [(ngModel)]="rowData[col.field]" class="input-editable" type="text">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ tableUtilsService.getValue(rowData, col.field) }}
                                        </ng-template>
                                    </p-cellEditor>
                                    <span *ngIf="!col.isEditable">
                                        {{ tableUtilsService.getValue(rowData, col.field) }}
                                    </span>
                                </ng-container>
                            </span>
                        </div>
                    </ng-template>
                </td>
            </tr>
        </ng-template>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr *ngIf="columns">
            <td></td>
            <td [attr.colspan]="columns.length" style="text-align:left">{{ l('TableNoEntriesFound') }}</td>
        </tr>
    </ng-template>
</p-table>

<sidebar (deleteConfig)="deleteTableConfig()" (reset)="visibleSidebar = false" (saveTable)="saveTableConfig()"
         [(visibleSidebar)]="visibleSidebar" [data]="columns" [visibleExtra]="true"></sidebar>
