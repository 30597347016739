import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[fieldType]'
})
export class FieldTypeTemplateDirective {

    @Input()
    fieldType: string;

    constructor(private container: ViewContainerRef,
                public templateRef: TemplateRef<any>) {
    }


}
