<div [@routerTransition]>
    <div class="m-subheader">
        <div class="row align-items-center">
            <div class="mr-auto col-auto">
                <h3 class="m-subheader__title">
                    <span>{{"Notifications" | localize}}</span>
                </h3>
            </div>
            <div class="col text-right mt-3 mt-md-0">
                <button (click)="openNotificationSettingsModal()" class="btn btn-success"><i
                    class="fa fa-cog"></i> {{"NotificationSettings" | localize}}</button>
                <button (click)="setAllNotificationsAsRead()" class="btn btn-primary"><i
                    class="fa fa-check"></i> {{"SetAllAsRead" | localize}}</button>
            </div>
        </div>
    </div>
    <div class="m-content">
        <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__body">
                <div class="m-form m-form--label-align-right">
                    <div class="row m--margin-bottom-10">
                        <div class="col-xl-6">
                            <div class="form-group">
                                <select #TargetValueFilterSelectionCombobox
                                        [(ngModel)]="readStateFilter"
                                        class="form-control"
                                        name="readStateFilter">
                                    <option value="ALL">{{"All" | localize}}</option>
                                    <option value="UNREAD">{{"Unread" | localize}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-6 text-right">
                            <div class="form-group">
                                <button (click)="getNotifications()" class="btn btn-metal"><i
                                    class="fa fa-sync"></i> {{"Refresh" | localize}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <!--<Primeng-TurboTable-Start>-->
                    <div [busyIf]="primengTableHelper.isLoading" class="primeng-datatable-container">
                        <p-table #dataTable
                                 (onLazyLoad)="getNotifications($event)"
                                 [lazy]="true"
                                 [paginator]="false"
                                 [responsive]="primengTableHelper.isResponsive"
                                 [value]="primengTableHelper.records"
                                 rows="{{primengTableHelper.defaultRecordsCountPerPage}}">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width:130px">
                                        {{'Actions' | localize}}
                                    </th>
                                    <th>
                                        {{'Notification' | localize}}
                                    </th>
                                    <th>
                                        {{'CreationTime' | localize}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template let-record="$implicit" pTemplate="body">
                                <tr>
                                    <td style="width:130px">
                                        <button (click)="setAsRead(record)"
                                                [attr.title]="!isRead(record) ? l('SetAsRead') : ''"
                                                [disabled]="isRead(record)" class="btn btn-sm btn-primary">
                                            <i *ngIf="isRead(record)" [attr.aria-label]="l('Read')"
                                               class="fa fa-check"></i>
                                            <i *ngIf="!isRead(record)" [attr.aria-label]="l('Unread')"
                                               class="fa fa-circle-notch"></i>
                                        </button>
                                        <button (click)="deleteNotification(record)" [attr.title]="l('Delete')"
                                                class="btn btn-sm btn-danger">
                                            <i [attr.aria-label]="l('Delete')" class="fa fa-times"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <span class="p-column-title">{{'Notification' | localize}}</span>
                                        <a *ngIf="record.formattedNotification.url"
                                           class="{{getRowClass(record)}}"
                                           href="{{record.formattedNotification.url}}">{{truncateString(record.formattedNotification.text, 120)}}</a>
                                        <span *ngIf="!record.formattedNotification.url"
                                              class="{{getRowClass(record)}}"
                                              title="{{record.formattedNotification.text}}">{{truncateString(record.formattedNotification.text, 120)}}</span>
                                    </td>
                                    <td>
                                        <span class="{{getRowClass(record)}}"
                                              title="{{record.notification.creationTime | momentFormat:'llll'}}">
                                            {{fromNow(record.notification.creationTime)}}
                                        </span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div *ngIf="primengTableHelper.totalRecordsCount == 0" class="primeng-no-data">
                            {{'NoData' | localize}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator #paginator
                                         (onPageChange)="getNotifications($event)"
                                         [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                                         [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                         [totalRecords]="primengTableHelper.totalRecordsCount">
                            </p-paginator>
                            <span class="total-records-count">
                                {{'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount}}
                            </span>
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </div>
            </div>
        </div>
    </div>
</div>
