import {Injectable} from '@angular/core';
import {AppAuthService} from '@app/shared/common/auth/app-auth.service';
import {AppUrlService} from '@shared/common/nav/app-url.service';
import {
    AccountServiceProxy,
    ImpersonateInput,
    ImpersonateOutput,
    InboxServiceProxy
} from '@shared/service-proxies/service-proxies';

@Injectable()
export class ImpersonationService {

    constructor(
        private _accountService: AccountServiceProxy,
        private _appUrlService: AppUrlService,
        private _authService: AppAuthService,
        private _inboxServiceProxy: InboxServiceProxy,
    ) {

    }

    impersonate(userId: number, tenantId?: number): void {

        const input = new ImpersonateInput();
        input.userId = userId;
        input.tenantId = tenantId;

        this._accountService.impersonate(input)
            .subscribe((result: ImpersonateOutput) => {
                this._authService.logout(false);

                let targetUrl = this._appUrlService.getAppRootUrlOfTenant(result.tenancyName) + '?impersonationToken=' + result.impersonationToken;
                if (input.tenantId) {
                    targetUrl = targetUrl + '&tenantId=' + input.tenantId;
                } else {
                    input.tenantId = undefined;
                }

                this._inboxServiceProxy.triggerInboxItemMessageAfterLogin(input.tenantId, input.userId)
                    .subscribe(() => {
                        location.href = targetUrl;
                    });
            });
    }

    backToImpersonator(): void {
        this._accountService.backToImpersonator()
            .subscribe((result: ImpersonateOutput) => {
                this._authService.logout(false);

                let targetUrl = this._appUrlService.getAppRootUrlOfTenant(result.tenancyName) + '?impersonationToken=' + result.impersonationToken;
                if (abp.session.impersonatorTenantId) {
                    targetUrl = targetUrl + '&tenantId=' + abp.session.impersonatorTenantId;
                }

                location.href = targetUrl;
            });
    }
}
