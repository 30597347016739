<router-outlet></router-outlet>
<p-toast [showTransformOptions]="'translateX(100%)'" position="bottom-right"></p-toast>
<p-dialog [(visible)]="showDialog" [baseZIndex]="10000" [blockScroll]="true" [closable]="true"
          [closeOnEscape]="true" [draggable]="true" [keepInViewport]="false" [modal]="true" [resizable]="true"
          [style]="{width: '50vw'}" appendTo="body" position="center">
    <ng-template pTemplate="header">
        <div class="dialog-header-kpmg">
            <div [class]="dialogIcon"></div>
            <div [innerHtml]="dialogTitle" class="dialog-title-kpmg"></div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div [innerHtml]="dialogMessage"></div>
    </ng-template>
    <ng-template pTemplate="footer">
        <p-button (click)="showDialog=false" label="Ok" pRipple styleClass="p-button-outlined"></p-button>
    </ng-template>
</p-dialog>

<p-confirmDialog #cd [baseZIndex]="10000" [style]="{width: '50vw'}" rejectButtonStyleClass="p-button-outlined">
    <p-footer>
        <button (click)="cd.reject()" class="p-button-outlined p-confirm-dialog-reject p-ripple p-button p-component"
                icon="pi pi-times" label="{{noLabel}}" pButton type="button"></button>
        <button (click)="cd.accept()" clas="p-confirm-dialog-accept p-ripple p-button p-component" icon="pi pi-check" label="{{yesLabel}}"
                pButton type="button"></button>
    </p-footer>
</p-confirmDialog>
