import {DOCUMENT} from '@angular/common';
import {AfterViewInit, Component, ElementRef, Inject, Injector, OnInit, ViewChild} from '@angular/core';
import {ThemesLayoutBaseComponent} from '@app/shared/layout/themes/themes-layout-base.component';
import {CommunicatonService} from '@app/shared/services/communication.service';
import {LayoutRefService} from '@metronic/app/core/services/layout/layout-ref.service';
import {appModuleAnimation} from '@shared/animations/routerTransition';
import {UrlHelper} from '@shared/helpers/UrlHelper';
import {Layout5Service} from './theme5.service';

@Component({
    templateUrl: './theme5-layout.component.html',
    selector: 'theme5-layout',
    animations: [appModuleAnimation()]
})
export class Theme5LayoutComponent extends ThemesLayoutBaseComponent implements OnInit, AfterViewInit {

    @ViewChild('mHeader') mHeader: ElementRef;
    // tslint:disable-next-line:no-inferrable-types
    menuTogglerValue: boolean = false;
    showMessages: boolean;
    isTenant = this.appSession.tenantId ? true : false;

    constructor(
        injector: Injector,
        private layoutRefService: LayoutRefService,
        private layout5Service: Layout5Service,
        @Inject(DOCUMENT) private document: Document,
        private communicationService: CommunicatonService
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.isTenant) {
            this.communicationService.messagesSubject.subscribe(
                res => {
                    if (res && res.globalMessagesValue !== undefined && res.globalMessagesValue == "false") {
                        this.showMessages = false;
                    } else {
                        this.showMessages = true;
                    }
                }
            )
        } else {
            this.communicationService.hostMessagesSubject.subscribe(
                res => {
                    if (res && res.globalMessagesValue !== undefined && res.globalMessagesValue == "false") {
                        this.showMessages = false;
                    } else {
                        this.showMessages = true;
                    }
                }
            )
        }

        this.installationMode = UrlHelper.isInstallUrl(location.href);
        this.menuTogglerValue = this.layout5Service.getToggleMenuValue();
    }

    ngAfterViewInit(): void {
        this.layoutRefService.addElement('header', this.mHeader.nativeElement);
        this.initStickyHeader();
    }

    clickLeftAsideHideToggle(): void {
        this.document.body.classList.toggle('m-aside-left--on');
    }

    initStickyHeader(): any {
        let headerEl = mUtil.get('m_header');
        let options = {
            offset: {
                desktop: null,
                mobile: null
            },
            minimize: {
                mobile: null,
                desktop: null
            },
            classic: {
                mobile: true,
                desktop: true
            }
        };

        if (mUtil.attr(headerEl, 'm-minimize-mobile') === 'minimize') {
            options.minimize.mobile = {};
            options.minimize.mobile.on = 'm-header--minimize-on';
            options.minimize.mobile.off = 'm-header--minimize-off';
        } else {
            options.minimize.mobile = false;
        }

        if (mUtil.attr(headerEl, 'm-minimize') === 'minimize') {
            options.minimize.desktop = {};
            options.minimize.desktop.on = 'header--minimize-on';
            options.minimize.desktop.off = 'header--minimize-off';
        } else {
            options.minimize.desktop = false;
        }

        if (mUtil.attr(headerEl, 'm-minimize-offset')) {
            options.offset.desktop = mUtil.attr(headerEl, 'm-minimize-offset');
        }

        if (mUtil.attr(headerEl, 'm-minimize-mobile-offset')) {
            options.offset.mobile = mUtil.attr(headerEl, 'm-minimize-mobile-offset');
        }

        return new mHeader('m_header', options);
    }
}
