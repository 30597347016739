<div #createOrEditModal="bs-modal" [config]="{backdrop: false }" aria-hidden="true" aria-labelledby="createOrEditModal" bsModal
     class="modal fade" role="dialog" style="background-color:rgba(0, 0, 0, 0.5);"
     tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{"VersionInfo" | localize}}</span>
                </h4>
                <button (click)="close()" aria-label="Close" class="close" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="text-align:left">
                    <div class="row">
                        <div class="col-4">{{l("ApplicationVersion")}}</div>
                        <div class="col-8">{{versionInfo.applicationVersion}}</div>
                    </div>
                    <div class="row">
                        <div class="col-4">{{l("BuildDate")}}</div>
                        <div class="col-8">{{versionInfo.buildDate}}</div>
                    </div>
                    <div class="row">
                        <div class="col-4">{{l("BuildNumber")}}</div>
                        <div class="col-8">{{versionInfo.buildNumber}}</div>
                    </div>
                    <div class="row">
                        <div class="col-4">{{l("ReleaseDate")}}</div>
                        <div class="col-8">{{versionInfo.releaseDate}}</div>
                    </div>
                    <div class="row">
                        <div class="col-4">{{l("ReleaseNumber")}}</div>
                        <div class="col-8">{{versionInfo.releaseNumber}}</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="close()" class="btn btn-primary blue" type="button">{{"Close" | localize}}</button>
            </div>
        </div>
    </div>

</div>
