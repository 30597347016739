<p-table #dt (onLazyLoad)="loading = true; onLazyLoad.emit($event);" (onPage)="onPage($event)"
    (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" [(selection)]="selectedItems"
    [autoLayout]="autoLayout" [columns]="columns" [currentPageReportTemplate]="tableConfigTypes[tableConfigType].replace('LookupTable_', '') | localize | pageReport"
    [dataKey]="dataKey" [lazyLoadOnInit]="false" [lazy]="lazy" [loading]="loading" [paginator]="paginator"
    [rowHover]="rowHover" [metaKeySelection]="metaKeySelection"
    [rowsPerPageOptions]="rowsPerPageOptions" [rows]="rows" [selectionMode]="selectionMode"
    [paginatorDropdownAppendTo]="paginatorDropdownAppendTo" [showCurrentPageReport]="showCurrentPageReport"
    [stateStorage]="stateStorage" [totalRecords]="totalRecords" [value]="value" withUnsort>

    <ng-template *ngIf="globalSearch" pTemplate="caption">
        <div class="row header-filters">
            <div class="col-3 global-filter">
                <div class="p-inputgroup">
                    <input (input)="debounceInput($event.target.value, '')" [placeholder]="l('Search')"
                        [value]="dt.filters['global']?.value" pInputText size="50" type="text">
                    <div class="input-group-prepend">
                        <button (click)="resetFilters()" class="btn btn-outline-danger" type="button">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="hasExtraCaption()" [ngTemplateOutlet]="getExtraCaption()">
            </ng-container>
        </div>
    </ng-template>
    <ng-template let-columns pTemplate="header">
        <tr>
            <th *ngIf="menuItems && menuItems.length > 0"></th>
            <th *ngIf="selectableFilter && selectionMode === 'multiCheck'" style="width: 0.25em">
            </th>
            <th *ngFor="let col of columns" [hidden]="col.isHidden" [pSortableColumnDisabled]="!col.sortable"
                [pSortableColumn]="col.field" [style.width]="setWidth(col.width)">
                {{ l(col.headerLocalization) }}
                <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
            </th>
        </tr>
        <tr *ngIf="filterShow">
            <th *ngIf="menuItems && menuItems.length > 0"></th>
            <th *ngIf="selectableFilter && selectionMode === 'multiCheck'">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th *ngFor="let col of columns" [hidden]="col.isHidden" [ngSwitch]="col.fieldType">
                <input (input)="debounceInput($event.target.value, col.field)" *ngSwitchCase="0"
                    [hidden]="!col.isFilterEnabled" [ngModel]="dt.filters[col.field]?.value" pInputText>
                <p-multiSelect (onChange)="dt.filter($event.value, col.field)" *ngSwitchCase="1" [filter]="false"
                    [hidden]="!col.isFilterEnabled" [ngModel]="dt.filters[col.field]?.value"
                    [options]="[{label: l('Yes'), value: true},{label: l('No'), value:false}]"
                    [placeholder]="l('Select')" [showClear]="true">
                </p-multiSelect>
                <p-calendar (ngModelChange)="debounceInput($event, col.field)" *ngSwitchCase="2"
                    [(ngModel)]="dateFilters[col.field]" [baseZIndex]="10000" [hidden]="!col.isFilterEnabled"
                    appendTo="body" inputId="range" inputStyleClass="form-control" selectionMode="range"></p-calendar>
                <p-calendar (ngModelChange)="debounceInput($event, col.field)" *ngSwitchCase="3"
                    [(ngModel)]="dateFilters[col.field]" [baseZIndex]="10000" [hidden]="!col.isFilterEnabled"
                    appendTo="body" inputId="range" inputStyleClass="form-control" selectionMode="range"></p-calendar>
                <p-multiSelect (onChange)="debounceInput($event.value, col.field)" *ngSwitchCase="4"
                    [baseZIndex]="10000" [hidden]="!col.isFilterEnabled" [ngModel]="dt.filters[col.field]?.value"
                    [options]="enumerations[col.field].value" [placeholder]="l('Select')" appendTo="body">
                </p-multiSelect>
                <p-dropdown (onChange)="debounceInput($event.value, col.field)" *ngSwitchCase="5" [filter]="true"
                    [hidden]="!col.isFilterEnabled" [ngModel]="dt.filters[col.field]?.value" [options]="countries"
                    [placeholder]="l('Select')" [showClear]="true" styleClass="country-filter">
                </p-dropdown>
                <div *ngSwitchCase="6" [hidden]="!col.isFilterEnabled" class="p-inputgroup range-filter">
                    <input #min (input)="debounceInput([$event.target.value, max.value], col.field)"
                        [ngModel]="dt.filters[col.field]?.value[0]" [placeholder]="l('Min')" pInputText>
                    <span class="p-inputgroup-addon">-</span>
                    <input #max (input)="debounceInput([min.value, $event.target.value], col.field)"
                        [ngModel]="dt.filters[col.field]?.value[1]" [placeholder]="l('Max')" pInputText>
                </div>
                <p-inputNumber (onInput)="debounceInput($event.value, col.field)" *ngSwitchCase="7"
                    [hidden]="!col.isFilterEnabled" [maxFractionDigits]="0" [ngModel]="dt.filters[col.field]?.value"
                    [useGrouping]="false">
                </p-inputNumber>
                <input (input)="debounceInput($event.target.value, col.field)" *ngSwitchCase="9"
                    [hidden]="!col.isFilterEnabled" [ngModel]="dt.filters[col.field]?.value" pInputText>
                <p-calendar (ngModelChange)="debounceInput($event, col.field)" *ngSwitchCase="15"
                [(ngModel)]="dateFilters[col.field]" [baseZIndex]="10000" [hidden]="!col.isFilterEnabled"
                appendTo="body" inputId="picker" inputStyleClass="form-control" useUtc></p-calendar>
                <p-multiSelect (onChange)="dt.filter($event.value, col.field)" *ngSwitchCase="18" [baseZIndex]="10000"
                    [hidden]="!col.isFilterEnabled" [ngModel]="dt.filters[col.field]?.value"
                    [options]="enumerations[col.field].value" [placeholder]="l('Select')" appendTo="body">
                </p-multiSelect>
                <p-calendar *ngSwitchCase="19" [ngModel]="dateFilters[col.field]  | m2d" [hidden]="!col.isFilterEnabled"
                    (ngModelChange)="debounceInput($event, col.field)" selectionMode="range" view="year" inputId="range"
                    appendTo="body" [baseZIndex]="10000" useUtc></p-calendar>
                <input (input)="debounceInput($event.target.value, col.field)" *ngSwitchCase="20" type="number" step="1"
                    [hidden]="!col.isFilterEnabled" [ngModel]="dt.filters[col.field]?.value" pInputText>
            </th>
        </tr>
    </ng-template>
    <ng-template let-columns="columns" let-record="$implicit" let-rowData pTemplate="body">

        <ng-container [ngTemplateOutletContext]="{ id: tableUtilsService.getValue(rowData, dataKey)}"
            [ngTemplateOutlet]="kpmgTableBody">
        </ng-container>

        <ng-template #kpmgTableBody let-id="id">
            <tr [pSelectableRow]="rowData" *ngIf="!!selectionMode">
                <ng-container [ngTemplateOutletContext]="{ id: tableUtilsService.getValue(rowData, dataKey)}"
                              [ngTemplateOutlet]="kpmgTableContext">
                </ng-container>
            </tr>
            <tr *ngIf="!selectionMode">
                <ng-container [ngTemplateOutletContext]="{ id: tableUtilsService.getValue(rowData, dataKey)}"
                              [ngTemplateOutlet]="kpmgTableContext">
                </ng-container>
            </tr>
        </ng-template>

        <ng-template #kpmgTableContext let-id="id">
            <td *ngIf="menuItems && menuItems.length > 0">
                <div>
                    <p-slideMenu #menu [menuWidth]="155" [model]="menuItems[id]" [popup]="true"
                                 [style]="{ width: '155px' }" [viewportHeight]="actionButtonSize[id]" appendTo="body">
                    </p-slideMenu>
                    <button (click)="menu.toggle($event)" class="p-button-secondary" icon="fa fa-cog" pButton
                            type="button">
                    </button>
                </div>
            </td>
            <td *ngIf="selectableFilter && selectionMode === 'multiCheck'">
                <p-tableCheckbox [value]="record"></p-tableCheckbox>
            </td>
            <td *ngFor="let col of columns" [hidden]="col.isHidden">
                <!-- responsive -->
                <span class="p-column-title">{{ l(col.headerLocalization) }} </span>
                <ng-container *ngIf="hasTemplate(col.field)"
                              [ngTemplateOutletContext]="{col: col, rowData: rowData, record: record}"
                              [ngTemplateOutlet]="getTemplate(col.field)">
                </ng-container>

                <ng-container *ngIf="!hasTemplate(col.field)" [ngTemplateOutlet]="defaultTableCells">
                </ng-container>

                <ng-template #defaultTableCells>
                    <div [ngSwitch]="col.fieldType">
                            <span *ngSwitchCase="1"
                                  [class]="tableUtilsService.getValue(rowData,col.field) ? 'm-badge m-badge--success m-badge--wide' : 'm-badge m-badge--metal m-badge--wide'">
                                {{ (tableUtilsService.getValue(rowData, col.field) ? "Yes" : "No") | localize }}
                            </span> <!-- Boolean -->
                        <span *ngSwitchCase="2">
                                {{ tableUtilsService.getValue(rowData, col.field) | momentFormat : 'L' }}
                            </span>
                        <span *ngSwitchCase="3">
                                {{ tableUtilsService.getValue(rowData, col.field) | momentFormat : 'L' }}&nbsp;
                            {{ tableUtilsService.getValue(rowData, col.field) | momentFormat : 'LT' }}
                            </span>
                        <span *ngSwitchCase="4">
                                {{ tableUtilsService.localizeEnumList(enumerations[col.field].value,
                            tableUtilsService.getValue(rowData, col.field)) }}
                            </span>
                        <span *ngSwitchCase="5">
                                {{ tableUtilsService.getValue(rowData, col.field) }}
                            </span>
                        <span *ngSwitchCase="11">
                                {{ tableUtilsService.getValue(rowData, col.field) | number:'1.2-2' }}
                            </span>
                        <!-- TruncatedText -->
                        <span *ngSwitchCase="9" [escape]="false"
                              [pTooltip]="tableUtilsService.getValue(rowData,col.field)"
                              tooltipStyleClass="p-tooltip-xl" tooltipZIndex="10000">
                                <!-- {{ truncateString(tableUtilsService.getValue(rowData, col.field), col.truncate)}} -->
                            {{ tableUtilsService.getValue(rowData, col.field) | truncateText : col.truncate }}
                            </span>
                        <span *ngSwitchCase="15">
                                {{ tableUtilsService.getValue(rowData, col.field) | momentFormat : 'L' }}
                            </span>
                        <span *ngSwitchCase="18">
                                {{ tableUtilsService.getEnumArrayValue(enumerations[col.field].value,
                            tableUtilsService.getValue(rowData, col.field))}}
                            </span>
                        <span *ngSwitchDefault>
                                {{ tableUtilsService.getValue(rowData, col.field) }}
                            </span> <!-- Text -->
                    </div>
                </ng-template>
            </td>
        </ng-template>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr *ngIf="columns">
            <td *ngIf="menuItems && menuItems.length > 0"></td>
            <td *ngIf="selectableFilter"></td>
            <td [attr.colspan]="columns.length" style="text-align:left">{{ l('TableNoEntriesFound') }}</td>
        </tr>
    </ng-template>
</p-table>
<div *ngIf="showButtons" class="modal-footer">
    <button (click)="close()" class="btn btn-primary blue" type="button">
        {{ closeButtonName }}
    </button>
    <button (click)="viewItem()" *ngIf="canViewItem" [disabled]="isViewItemDisabled()" class="btn btn-primary blue"
        type="button">
        {{ l("View") }}
    </button>
    <button (click)="selectItems()" *ngIf="!canDelete"
        [disabled]="(selectedItems==undefined || selectedItems.length==0) ? true: false" class="btn btn-primary blue"
        type="button">
        {{ selectButtonName }}
    </button>
    <button (click)="selectItems()" *ngIf="canDelete"
        [disabled]="(selectedItems==undefined || selectedItems.length==0) ? true: false" class="btn btn-danger white"
        type="button">
        {{ l("Delete") }}
    </button>
</div>
