import { Component, Injector, ViewChild, OnDestroy } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DeclarationsServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { timer, Subscription } from 'rxjs';
import { AppAuthService } from '../auth/app-auth.service';
import { AbpSessionService } from 'abp-ng2-module';

@Component({
    selector: 'session-timeout-modal',
    templateUrl: './session-timeout-modal.component.html'
})
export class SessionTimeoutModalComponent extends AppComponentBase implements OnDestroy {

    @ViewChild('modal', { static: true }) modal: ModalDirective;

    timeOutInMinutes = parseInt(this.s('App.UserManagement.SessionTimeOut.ShowTimeOutNotification')); // show inactivity modal when TimeOut passed
    currentTimeout: number;
    currentTimeoutInMinutes: number; 
    progresbarWidth = '100%';
    private subscription: Subscription;

    constructor(
        injector: Injector,
        private _sessionService: AbpSessionService,
        private _declarationsServiceProxy: DeclarationsServiceProxy,
        private _appAuthService: AppAuthService
    ) {
        super(injector);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    start(): void {
        this.currentTimeout = this.timeOutInMinutes * 60;
        this.subscription = timer(0, 1000).subscribe(() => {
            this.changeNotifyContent();
        });
        this.modal.show();
    }

    stop(): void {
        this.currentTimeout = this.timeOutInMinutes * 60;
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.modal.hide();
    }

    private done(): void {
        this.stop();
        this._declarationsServiceProxy
            .unlockAllDeclarationsByUser(undefined, this._sessionService.userId)
            .subscribe(() => {
                this._appAuthService.logout(true);
            });
    }

    private changeNotifyContent(): void {
        this.currentTimeout--;
        this.currentTimeoutInMinutes = Math.round(this.currentTimeout / 60); 
        if (this.currentTimeout <= 0) {
            this.progresbarWidth = '0%';
            this.done();
        } else {
            this.progresbarWidth = (this.currentTimeout / this.timeOutInMinutes / 60 * 100) + '%';
        }
    }
}
