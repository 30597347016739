import { AbpModule } from 'abp-ng2-module';
import * as ngCommon from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { CommonModule } from '@shared/common/common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { FileUploadModule } from 'ng2-file-upload';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FileUploadModule as NgFileUpload } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { EntityChangeDetailModalComponent } from './entityHistory/entity-change-detail-modal.component';
import { EntityTypeHistoryModalComponent } from './entityHistory/entity-type-history-modal.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ImportFromExcelModalComponent } from './import/import-from-excel-modal.component';
import { CommonLookupModalComponent } from './lookup/common-lookup-modal.component';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { MapModalComponent } from './map-modal/map-modal.component';
import { DatePickerInitialValueSetterDirective } from './timing/date-picker-initial-value.directive';
import { DateRangePickerInitialValueSetterDirective } from './timing/date-range-picker-initial-value.directive';
import { DateTimeService } from './timing/date-time.service';
import { TimeZoneComboComponent } from './timing/timezone-combo.component';
import { MergeWithExcelModalComponent } from './merge/merge-with-excel-modal.component';
import { SaveVersionComponent } from './saveVersion/save-version.component';
import { ChangeVersionDateComponent } from './saveVersion/change-version-date.component';
import { SaveVersionService } from './saveVersion/save-version.service';
import { PaginatorModule } from "primeng/paginator";
import { KpmgTableComponent } from './kpmg-table/kpmg-table.component';
import { KpmgLookupTableComponent } from './kpmg-lookup-table/kpmg-lookup-table.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule as PrimeNgTooltipModule } from 'primeng/tooltip';
import { FieldsetModule } from 'primeng/fieldset';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputMaskModule } from 'primeng/inputmask';
import { PanelModule } from 'primeng/panel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EditorModule } from 'primeng/editor';
import { SpinnerModule } from 'primeng/spinner';
import { TreeModule } from 'primeng/tree';
import { ChipsModule } from 'primeng/chips';
import { TreeTableModule } from 'primeng/treetable';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { SlideMenuModule } from 'primeng/slidemenu';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InboxItemsComponent } from '@app/main/kpmg/inboxItems/inboxItems.component';
import { SplitterModule } from 'primeng/splitter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SaveChangedVersionPromptComponent } from './saveVersion/save-changed-version-prompt.component';
import { CalendarModule } from "primeng/calendar";
import { FilePreview } from './filePreview/filePreview.component';
import { SaveChangedVersionPromptTemplateComponent } from './saveVersion/save-changed-version-prompt-template/save-changed-version-prompt-template.component';
import { ChangeVersionDateTemplateComponent } from './saveVersion/change-version-date-template/change-version-date-template.component';
import { SaveVersionTemplateComponent } from './saveVersion/save-version-template/save-version-template.component';
import { RippleModule } from "primeng/ripple";
import {
    EconomicUnitLookupTableModalComponent
} from "@app/main/kpmg/lookups/economicUnit-lookup-table-modal/economicUnit-lookup-table-modal.component";
import { MultiUserLookupModalComponent } from '@app/main/kpmg/lookups/multi-user-lookup-modal/multi-user-lookup-modal.component';
import { MultiUserLookupTemplateComponent } from '@app/main/kpmg/lookups/multi-user-lookup-modal/multi-user-lookup-template/multi-user-lookup-template.component';
import { LeaveSiteDialogComponent } from './leaveSite/leave-site-dialog.component';
import { DialogModule } from 'primeng/dialog';
import {TriStateCheckboxModule} from "primeng/tristatecheckbox";
import {AvatarModule} from "primeng/avatar";
import {TagModule} from "primeng/tag";
import {InputTextareaModule} from "primeng/inputtextarea";

@NgModule({
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        AbpModule,
        CommonModule,
        TableModule,
        PaginatorModule,
        NgFileUpload,
        FileUploadModule,
        SidebarModule,
        PrimeNgTooltipModule,
        FieldsetModule,
        TabViewModule,
        MultiSelectModule,
        InputMaskModule,
        PanelModule,
        AutoCompleteModule,
        EditorModule,
        SpinnerModule,
        SplitterModule,
        TreeModule,
        ChipsModule,
        TreeTableModule,
        AccordionModule,
        CheckboxModule,
        SlideMenuModule,
        InputNumberModule,
        RadioButtonModule,
        MatCheckboxModule,
        CalendarModule,
        RippleModule,
        DialogModule,
        TriStateCheckboxModule,
        AvatarModule,
        TagModule,
        InputTextareaModule,
    ],
    declarations: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        ImportFromExcelModalComponent,
        MergeWithExcelModalComponent,
        SearchbarComponent,
        MapModalComponent,
        FileUploadComponent,
        SaveVersionComponent,
        ChangeVersionDateComponent,
        KpmgTableComponent,
        KpmgLookupTableComponent,
        SidebarComponent,
        InboxItemsComponent,
        SaveChangedVersionPromptComponent,
        FilePreview,
        SaveChangedVersionPromptTemplateComponent,
        ChangeVersionDateTemplateComponent,
        SaveVersionTemplateComponent,
        EconomicUnitLookupTableModalComponent,
        MultiUserLookupModalComponent,
        MultiUserLookupTemplateComponent,
        LeaveSiteDialogComponent,
    ],
    exports: [
        FileUploadComponent,
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        ImportFromExcelModalComponent,
        MergeWithExcelModalComponent,
        SearchbarComponent,
        MapModalComponent,
        SaveVersionComponent,
        ChangeVersionDateComponent,
        KpmgTableComponent,
        KpmgLookupTableComponent,
        SidebarComponent,
        InboxItemsComponent,
        SaveChangedVersionPromptComponent,
        FilePreview,
        EconomicUnitLookupTableModalComponent,
        MultiUserLookupModalComponent,
        LeaveSiteDialogComponent,
    ],
    providers: [
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,
        SaveVersionService
    ]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
            providers: [
                AppAuthService,
                AppRouteGuard,
            ]
        };
    }
}
