import { Component, Injector, ViewChild, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SessionTimeoutModalComponent } from './session-timeout-modal-component';
import { timer, fromEvent, Subject, Subscription } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { ProfileServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'session-timeout',
  template: '<session-timeout-modal></session-timeout-modal>'
})
export class SessionTimeoutComponent extends AppComponentBase implements AfterViewInit, OnDestroy {
  @ViewChild(SessionTimeoutModalComponent, { static: false }) private sessionTimeOutModal: SessionTimeoutModalComponent;
  @Input() declarationTimeOut: number;

  destroy$ = new Subject();

  private timeOutInMinutes = parseInt(this.s('App.UserManagement.SessionTimeOut.TimeOut')); // show inactivity modal when TimeOut passed
  private lastActivityTimeStorageKey = 'Abp.SessionTimeOut.UserLastActivityTime';
  private sessionTimeOut = parseInt(this.s('App.UserManagement.SessionTimeOut.TimeOut'));

  private IsUserActive = true;
  private notifierIsOpened = false;
  private subscriptions: Subscription[] = [];

  constructor(
    injector: Injector,
    private _profileAppService: ProfileServiceProxy
    ) {
    super(injector);
  }

  ngAfterViewInit() {
    this.bindActions();
    this.writeToStorage(); // initialize store
    this.subscriptions.push(timer(1000, 1000).subscribe(() => {
      this.control();
    }));
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private bindActions(): void {
    this.subscriptions.push(fromEvent(window, 'mousemove')
      .pipe(takeUntil(this.destroy$), debounceTime(350))
      .subscribe(() => {
        this.setUserActivity();
      }));

    this.subscriptions.push(fromEvent(window, 'mousedown')
      .pipe(takeUntil(this.destroy$), debounceTime(350))
      .subscribe(() => {
        this.setUserActivity();
      }));

    this.subscriptions.push(fromEvent(window, 'click')
      .pipe(takeUntil(this.destroy$), debounceTime(350))
      .subscribe(() => {
        this.setUserActivity();
      }));

    this.subscriptions.push(fromEvent(window, 'scroll')
      .pipe(takeUntil(this.destroy$), debounceTime(350))
      .subscribe(() => {
        this.setUserActivity();
      }));

    this.subscriptions.push(fromEvent(window, 'keypress')
      .pipe(takeUntil(this.destroy$), debounceTime(350))
      .subscribe(() => {
        this.setUserActivity();
      }));
  }

  private setUserActivity(): void {
    this.IsUserActive = true;
  }

  private control(): void {
    this.writeToStorage();
    this.controlStorage();
  }

  private writeToStorage(): void {
    if (this.IsUserActive) {
        abp.utils.setCookieValue(this.lastActivityTimeStorageKey, Date.now().toString(), null, abp.appPath,
            '',
            {secure: location.protocol === 'https:'});
    }
    this.IsUserActive = false;
  }

  private controlStorage(): void {
      let lastActivityTime = parseInt(abp.utils.getCookieValue(this.lastActivityTimeStorageKey));
      this.controlValue(lastActivityTime);

  }
  private controlValue(lastActivityTime) {
    if (this.declarationTimeOut && this.declarationTimeOut > 0){
        const declarationTimeOutInMinutes = this.declarationTimeOut; // * 60;
        if (declarationTimeOutInMinutes < this.timeOutInMinutes){
          this.timeOutInMinutes = declarationTimeOutInMinutes;
        }
    }
    else{
      this.timeOutInMinutes = this.sessionTimeOut;
    }
    //console.log("sessionTimeOut: " + this.sessionTimeOut);
    
    if (Date.now() - lastActivityTime <= this.timeOutInMinutes * 60 * 1000) {
      if (this.notifierIsOpened) {
        this.sessionTimeOutModal.stop();
        this.notifierIsOpened = false;
      }
      return;
    }

    if (!this.notifierIsOpened) {
      this.sessionTimeOutModal.start();
      this.notifierIsOpened = true;
    }
  }
}
