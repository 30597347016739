<p-sidebar (onHide)="resetValue()" [(visible)]="visibleSidebar" [baseZIndex]="10000" [style]="{width: widthBar}"
           position="right">
    <h3 style="font-weight:normal">{{ "ColumnConfig" | localize }}</h3>
    <hr/>
    <table>
        <th>
            <span>{{ "ShowTableColumn" | localize }}</span><br/><br/>
        </th>
        <tbody>
        <tr>
            <td class="align-top">
                <table>
                    <th>
                        <span *ngIf="visibleExtra" style="font-weight:normal">{{ "MainFields" | localize }}</span>
                        <hr *ngIf="visibleExtra"/>
                    </th>
                    <tbody>
                    <tr *ngFor="let col of data">
                        <td *ngIf="!col.isExtra && !col.isAdditionalField">
                            <p-checkbox [ngModel]="!col.isHidden" (ngModelChange)="col.isHidden = !col.isHidden" [label]="l(col.headerLocalization)"
                                        binary="true" checkboxIcon="fa fa-check">
                            </p-checkbox>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <br/>
                    </tfoot>
                </table>
            </td>
            <td *ngIf="visibleExtra" class="align-top" style="padding-left: 40px">
                <table>
                    <th>
                        <span style="font-weight:normal">{{ "AdditionalFields" | localize }}</span>
                        <hr/>
                    </th>
                    <tbody>
                    <tr *ngFor="let col of data">
                        <td *ngIf="col.isExtra || col.isAdditionalField">
                            <p-checkbox [ngModel]="!col.isHidden" (ngModelChange)="col.isHidden = !col.isHidden"  [label]="l(col.headerLocalization)"
                                        binary="true" checkboxIcon="fa fa-check">
                            </p-checkbox>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <br/>
                    </tfoot>
                </table>
            </td>
        </tr>
        </tbody>
    </table>


    <button (click)="saveTableConfig()" class="btn btn-primary">
        <i class="fa fa-save"></i> {{ "Save" | localize }}
    </button>
    <button (click)="deleteTableConfig()" class="btn btn-primary">{{ "Reset" | localize }}</button>
</p-sidebar>
