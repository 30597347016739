import {Component, Injector, Input, OnInit} from "@angular/core";
import {AppComponentBase} from "@shared/common/app-component-base";
import {GlobalMessagesEnabledDto, GlobalMessagesServiceProxy} from "@shared/service-proxies/service-proxies";
import {CommunicatonService} from '../services/communication.service';

@Component({
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.css"],
    selector: "footer-bar"
})
export class FooterComponent extends AppComponentBase implements OnInit {
    releaseDate: string;
    themeLight: boolean = localStorage.getItem("themeFlag") == "dark" ? true : false;
    messagesFlag: boolean;
    messagesCounter: number = 0;
    isTenant = this.appSession.tenantId ? true : false;

    @Input() useWrapperDiv = false;

    constructor(injector: Injector, private _communicationService: CommunicatonService, private _customGlobalMessagesService: GlobalMessagesServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {
        this.releaseDate = this.appSession.application.releaseDate.format(
            "YYYYMMDD"
        );
        this._communicationService.loadThemeStyle(this.themeLight);
        this.loadMessages();
        if (this.isTenant) {
            this._communicationService.loadMessages();
            this._communicationService.messagesSubject.subscribe(
                res => {
                    if (res && res.globalMessagesValue !== undefined && res.globalMessagesValue == "false") {
                        this.messagesFlag = false;
                    } else {
                        this.messagesFlag = true;
                    }
                }
            )
        } else {
            this._communicationService.loadHostMessages();
            this._communicationService.hostMessagesSubject.subscribe(
                res => {
                    if (res && res.globalMessagesValue !== undefined && res.globalMessagesValue == "false") {
                        this.messagesFlag = false;
                    } else {
                        this.messagesFlag = true;
                    }
                }
            )
        }
    }

    loadStyle(e) {
        this._communicationService.loadThemeStyle(e.checked);
    }

    setMessages(e) {
        let switchVal = e.checked == true ? "true" : "false";
        let globalMessageVal = new GlobalMessagesEnabledDto();
        globalMessageVal.globalMessagesValue = switchVal;
        this._communicationService.setMessages(globalMessageVal, this.isTenant);
    }

    loadMessages() {
        this._customGlobalMessagesService.getAllValid().subscribe((result) => {
            if (result) {
                this.messagesCounter = result.length;
            }
        });
    }
}
